import React, { useEffect, useRef } from "react";
import clsx from "clsx";

import Slider from "react-slick";
import "../../scss/slick/slick.scss";
import "../../scss/slick/slick-theme.scss";

import styles from "./styles.module.scss";

import { ArrowLeft } from "assets";

import { DayItem } from "components";

const UserBoostcampSlider = ({
  boostcamp,
  currentDay,
  setCurrentDay,
  initialSlide = 0,
  isOpen,
}) => {
  const ref = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    className: "center",
    centerMode: true,
    centerPadding: "25%",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => {
      setCurrentDay(next);
    },

    // afterChange: current => this.setState({ activeSlide2: current })
  };

  useEffect(() => {
    if (isOpen && ref?.current && ref?.current?.slickGoTo)
      ref.current?.slickGoTo(currentDay === 0 ? initialSlide : currentDay);
  }, [isOpen, initialSlide, currentDay]);

  const checkDayStatus = (item, index, currentDay) => {
    if (
      index.toString() === currentDay?.toString() &&
      (item?.isCompleted || item?.isClose)
    ) {
      return "opened-completed";
    }
    if (
      index.toString() === currentDay?.toString() &&
      !(item?.isCompleted || item?.isClose)
    ) {
      return "opened";
    } else if (
      index.toString() !== currentDay?.toString() &&
      (item?.isCompleted || item?.isClose)
    ) {
      return "complete";
    } else if (
      index.toString() !== currentDay?.toString() &&
      index === boostcamp?.passedDays
    ) {
      return "current";
    } else if (
      index.toString() !== currentDay?.toString() &&
      !(item?.isCompleted || item?.isClose)
    ) {
      return "closed";
    } else {
      return "closed";
    }
  };

  return (
    <div className={styles.wrapper}>
      <Slider {...settings} ref={ref}>
        {boostcamp?.days?.map((item, index) => (
          <div key={`USerBoostcampSlide-${item}`}>
            <div className={clsx(styles.slide)}>
              <DayItem
                index={index}
                idBoostcamp={boostcamp?.id || ""}
                status={checkDayStatus(item, index, currentDay)}
                title={item?.title}
                description={item?.shortDescription}
                image={item?.picture}
                // onClick={() => setCurrentDay(index)}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={clsx(className, styles.arrow)}
      style={{ ...style, display: "flex", right: 32 }}
      onClick={onClick}
    >
      <ArrowLeft style={{ rotate: "180deg" }} />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={clsx(className, styles.arrow)}
      style={{ ...style, display: "flex", left: 32 }}
      onClick={onClick}
    >
      <ArrowLeft />
    </div>
  );
}

export default UserBoostcampSlider;
