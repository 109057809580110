import React, { useState } from "react";
import Input from "../Input";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { ArrowUp, Delete, ImgPlaceholder } from "../../assets";
import TextAreaEditor from "../TextAreaEditor";
import { Spinner } from "react-activity";
import { deleteFile, uploadFile } from "../../services/file.service";

const ReviewItem = ({ item, index, setReviews }) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const [pictureError, setIsPictureError] = useState(false);
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [isLoadingDeleteFile, setIsLoadingDeleteFile] = useState(false);

  const ratingOptions = [
    { label: "1 star", value: 1 },
    { label: "2 stars", value: 2 },
    { label: "3 stars", value: 3 },
    { label: "4 stars", value: 4 },
    { label: "5 stars", value: 5 },
  ];

  const onChangeName = (e) => {
    setReviews((prev) => {
      const copy = [...prev];
      copy[index].name = e.target.value;
      return copy;
    });
  };

  const onChangeCity = (e) => {
    setReviews((prev) => {
      const copy = [...prev];
      copy[index].city = e.target.value;
      return copy;
    });
  };

  const onChangeText = (e) => {
    setReviews((prev) => {
      const copy = [...prev];
      copy[index].text = e.target.value;
      return copy;
    });
  };

  const onSetRating = (item) => {
    setReviews((prev) => {
      const copy = [...prev];
      copy[index].rating = item;
      return copy;
    });
  };

  const onDeleteImg = async () => {
    try {
      setIsLoadingDeleteFile(true);
      if (!item?.picture) {
        return;
      }
      await deleteFile(item?.picture);
      setReviews((prev) => {
        const copy = [...prev];
        copy[index].picture = undefined;
        return copy;
      });
      setIsLoadingDeleteFile(false);
    } catch (error) {
      setIsLoadingDeleteFile(false);
      console.log(error);
    }
  };

  const onDeleteReview = () => {
    setReviews((prev) => {
      const copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
  };

  const onSelectFile = async (e) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      setIsLoadingUploadFile(true);

      const res = await uploadFile(e.target.files[0]);
      setReviews((prev) => {
        const copy = [...prev];
        copy[index].avatar = res?.data?.url;
        return copy;
      });
      setIsLoadingUploadFile(false);
    } catch (error) {
      console.log(error);
      setIsLoadingUploadFile(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>Review #{index + 1} person</div>
        <div className={styles.headerContent}>
          <div
            className={clsx(styles.headerInputWrapper, {
              [styles.selectWrapper]: true,
            })}
          >
            <div
              onClick={() => setIsDropDownOpen((prev) => !prev)}
              className={clsx(styles.dropDown, {
                [styles.dropDownActive]: isDropDownOpen,
              })}
            >
              <p className={styles.dropDownTitle}>{item.rating.label}</p>
              <div
                className={clsx(styles.arrowWrapper, {
                  [styles.arrowWrapperActive]: isDropDownOpen,
                })}
              >
                <ArrowUp />
              </div>
              {isDropDownOpen && (
                <div className={styles.dropDownList}>
                  {ratingOptions.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => onSetRating(item)}
                      className={styles.dropDownItem}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className={styles.headerInputWrapper}>
            <Input
              value={item.name}
              onChange={onChangeName}
              placeholder={"Name"}
            />
          </div>
          <div className={styles.headerInputWrapper}>
            <Input
              value={item.city}
              onChange={onChangeCity}
              placeholder={"City"}
            />
          </div>
          <div onClick={onDeleteReview} className={styles.deleteBtn}>
            <Delete />
          </div>
        </div>
      </div>
      <div className={styles.contentTitle}>Review #{index + 1} text</div>
      <div className={styles.contentContainer}>
        <div className={styles.leftContainer}>
          {item?.avatar ? (
            <img
              src={item?.avatar}
              className={clsx(styles.bootcampsSettingsPhoto, {
                [styles.bootcampsSettingsPhotoError]: pictureError,
              })}
              alt={"photo"}
            />
          ) : (
            <div
              className={clsx(styles.bootcampsSettingsPhoto, {
                [styles.bootcampsSettingsPhotoError]: pictureError,
              })}
            >
              <ImgPlaceholder />
            </div>
          )}
          <div className={styles.flex}>
            <label
              htmlFor={`photoInput${index}`}
              className={styles.bootcampsSettingsUploadImgWrapper}
            >
              {isLoadingUploadFile ? (
                <span>
                  <Spinner size={12} />
                </span>
              ) : (
                "Upload New"
              )}
              <input
                id={`photoInput${index}`}
                type={"file"}
                accept="image/*"
                onChange={onSelectFile}
                className={styles.bootcampsSettingsUploadImgBtn}
              />
            </label>
            <div
              onClick={onDeleteImg}
              className={styles.bootcampsSettingsDeleteImgBtn}
            >
              {isLoadingDeleteFile ? (
                <span>
                  <Spinner size={12} />
                </span>
              ) : (
                "Delete photo"
              )}
            </div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <TextAreaEditor
            classes={styles.textArea}
            value={item.text}
            onChange={onChangeText}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewItem;
