import React, { FC, useState, useEffect } from "react";

import styles from "./styles.module.scss";

const Counter = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hour: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = () => {
    const date = new Date();

    date.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7 || 7));
    date.setHours(0, 0, 0, 0);

    let difference = +new Date(date) - +new Date();

    let timeLeft = { days: 0, hour: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hour: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    setTimeLeft(timeLeft);
  };

  useEffect(() => {
    calculateTimeLeft();

    setInterval(() => {
      calculateTimeLeft();
    }, 1000);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.blockItem}>
        <h4 className={styles.time}>{timeLeft.days}</h4>
        <p className={styles.title}>DAYS</p>
      </div>
      <div className={styles.divider} />
      <div className={styles.blockItem}>
        <h4 className={styles.time}>{timeLeft.hour}</h4>
        <p className={styles.title}>HOURS</p>
      </div>
      <div className={styles.divider} />

      <div className={styles.blockItem}>
        <h4 className={styles.time}>{timeLeft.minutes}</h4>
        <p className={styles.title}>MINUTES</p>
      </div>
    </div>
  );
};

export default Counter;
