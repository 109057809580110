//react
import React from 'react';

//navigation
import { NavLink } from 'react-router-dom';

//styles
import styles from './styles.module.scss';
import clsx from 'clsx';

const EditBoostCampsHeader = ({
  id = 21312321,
  totalPoints = 0,
  activetab,
  setActiveTab,
}) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerTitle}>Edit Boost Camp</div>
      <div className={styles.navigationContainer}>
        <div
          onClick={() => {
            setActiveTab(0);
          }}
          // key={`/boostcamps/edit/${id}`}
          // to={`/boostcamps/edit/${id}`}
          className={clsx(styles.navLink, {
            [styles.navLinkActive]: activetab === 0,
          })}
        >
          Main Information
        </div>
        <div
          onClick={() => {
            setActiveTab(1);
          }}
          // key={`/boostcamps/edit/${id}`}
          // to={`/boostcamps/edit/${id}`}
          className={clsx(styles.navLink, {
            [styles.navLinkActive]: activetab === 1,
          })}
        >
          Manage Days
        </div>
        <div className={styles.totalPoints}>
          Total points: <span>{totalPoints}</span>
        </div>
      </div>
    </div>
  );
};

export default EditBoostCampsHeader;
