//react
import moment from "moment";
import React, { useRef, useState } from "react";
import { Spinner } from "react-activity";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

//assets
import {
  Filter,
  Delete,
  DotsIcon,
  PencilSmallIcon,
  CopyIcon,
} from "../../assets";

//modals
import { DeleteModal } from "modals";

//hooks
import { useOutsideClick, useToggleModal } from "hooks";

//components
import { Switch } from "components";

//navigation
import { useNavigate } from "react-router-dom";

//styles
import styles from "./styles.module.scss";

//utils
import { fetchRequest } from "utils";

const BoostCampsItem = ({ item, setRerender, index }) => {
  const navigate = useNavigate();
  const deleteModal = useToggleModal();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(true);
  const [isDraft, setIsDraft] = useState(item?.isDraft);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);
  const [isLoadingDublicate, setIsLoadingDublicate] = useState(false);

  const ref = useRef();
  useOutsideClick(ref, () => setIsOpenMenu(false));

  const onDeleteBoostcamp = async () => {
    try {
      setIsLoadingDelete(true);
      const res = await fetchRequest(`boost-camps/${item?.id}`, "DELETE");
      setIsLoadingDelete(false);
      deleteModal.closeModal();

      setRerender((prev) => !prev);
    } catch (error) {
      console.log(error);
      setIsLoadingDelete(false);
    }
  };

  const onToggleDraft = async () => {
    try {
      if (item?.days && item?.daysNumber !== item?.days?.length) {
        toast.error("All days must be filled", {});
        return;
      }
      setIsLoadingDraft(true);
      const body = {
        isDraft: !isDraft,
      };
      const res = await fetchRequest(`boost-camps/${item?.id}`, "put", body);
      if (res?.success) {
        setIsDraft(res?.data?.isDraft);
      }
      setIsLoadingDraft(false);
    } catch (error) {
      setIsLoadingDraft(false);
    }
  };

  const onDublicate = async (e) => {
    e.stopPropagation();
    try {
      setIsLoadingDublicate(true);
      const res = await fetchRequest(
        `boost-camps/${item.id}/duplicate`,
        "POST"
      );
      if (res?.success) {
        setRerender((prev) => !prev);
      }
      setIsLoadingDublicate(false);
    } catch (error) {
      setIsLoadingDublicate(false);
    }
  };

  const resultPrice = () => {
    if (item?.prices[0]?.discount?.type === "count") {
      return item?.prices[0]?.price - item?.prices[0].discount?.count;
    } else if (item?.prices[0]?.discount?.type === "percentage") {
      return (
        item?.prices[0]?.price -
        (item?.prices[0]?.price * item?.prices[0].discount?.count) / 100
      );
    } else {
      return 0;
    }
  };

  return (
    <>
      <div
        onClick={() => navigate(`/boostcamps/edit/${item?.id}`)}
        ref={ref}
        className={styles.wrapper}
      >
        {isLoadingImg && (
          <div className={styles.photoLoader}>
            <Spinner color={"#8C42E3"} size={14} />
          </div>
        )}
        <img
          alt="picture"
          src={item?.picture}
          onLoad={() => setIsLoadingImg(false)}
          className={styles.photo}
        />
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{item?.title}</p>
          <p className={styles.subtitle}>{item?.daysNumber} days</p>
        </div>

        <div className={styles.priceWrapper}>
          <p className={styles.priceWithDiscount}>${resultPrice()}</p>
          <p className={styles.price}>${item?.prices[0]?.price}</p>
        </div>
        <div className={styles.isDarft}>
          <span className={styles.isDarftTitle}>
            Available
            <br /> on PWA:
          </span>
          <Switch
            isChecked={!isDraft}
            setIsChecked={() => {
              if (!isLoadingDraft) {
                onToggleDraft();
              }
            }}
          />
        </div>
        <div
          className={styles.filterBtn}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpenMenu((prev) => !prev);
          }}
        >
          <DotsIcon className={styles.menuBtn} />
        </div>
        {isOpenMenu && (
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className={styles.menuWrapper}
          >
            <div onClick={onDublicate} className={styles.menuButton}>
              {isLoadingDublicate ? (
                <div className={styles.deleteLoader}>
                  <Spinner color={"#000"} size={11} />
                </div>
              ) : (
                <>
                  <div className={styles.copyIconWrapper}>
                    <CopyIcon />
                  </div>

                  <span style={{ marginLeft: "7px" }}>Duplicate</span>
                </>
              )}
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/boostcamps/edit/${item?.id}`);
              }}
              className={styles.menuButton}
            >
              <PencilSmallIcon width={19} height={19} />
              <span style={{ marginLeft: "7px" }}>Edit</span>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenMenu(false);
                deleteModal.showModal();
              }}
              className={styles.menuButton}
            >
              {isLoadingDelete ? (
                <div className={styles.deleteLoader}>
                  <Spinner color={"#EE3758"} size={11} />
                </div>
              ) : (
                <>
                  <Delete />
                  <span style={{ color: "#EE3758" }}>Delete</span>
                </>
              )}
            </div>
          </motion.div>
        )}
      </div>

      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={"Are you sure you want to delete?"}
          onClick={onDeleteBoostcamp}
          loading={isLoadingDelete}
        />
      )}
    </>
  );
};

export default BoostCampsItem;
