import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";

import styles from "./styles.module.scss";

import {
  ArrowRight,
  ChecklistIcon,
  CrossIcon,
  FastingIcon,
  SleepIcon,
  WaterIcon,
} from "assets";
import { Spinner } from "react-activity";
import {
  Button,
  ChecklistTasks,
  DailyTaskSetItem,
  FastingTracker,
  SleepTracker,
  UserBoostcampSlider,
  WaterTracker,
  WeightTracker,
} from "components";
import { fetchRequest, updateProgressDay } from "utils";
import { useMemo } from "react";
import moment from "moment";

const UserBoostcamp = ({
  user,
  userActiveProgress,
  setUserActiveProgress,
  isLoading,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const reduceCompletedDays = (days) => {
    const sumWithInitial = days
      ? days.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue.isCompleted ? 1 : 0),
          0
        )
      : 0;

    return sumWithInitial;
  };

  const daysProgress = useMemo(
    () =>
      user?.testMode
        ? reduceCompletedDays(userActiveProgress?.days)
        : userActiveProgress?.passedDays
        ? userActiveProgress.passedDays + 1
        : 1,
    [user?.testMode, userActiveProgress]
  );

  return (
    <div className={styles.wrapper}>
      <img
        className={styles.image}
        alt="Boostcamp"
        src={userActiveProgress?.picture}
      />

      <div className={styles.container}>
        <div className={styles.info}>
          <p className={styles.infoCurrent}>Current Boostcamp</p>
          <h5 className={styles.infoTitle}>{userActiveProgress?.title}</h5>
        </div>
        <div className={styles.progress}>
          <div className={styles.progressContainer}>
            <div className={styles.progressLine}>
              <div
                className={styles.progressLineInner}
                style={{
                  width: `${Math.floor(
                    (daysProgress / userActiveProgress?.daysNumber) * 100
                  )}%`,
                }}
              />
            </div>
            <div className={styles.progressDates}>
              <div>
                {moment(userActiveProgress?.startDate).format("MMM D, Y")}
              </div>
              <div>
                {moment(userActiveProgress?.startDate)
                  .add(userActiveProgress?.daysNumber - 1 || 0, "days")
                  .format("MMM D, Y")}
              </div>
            </div>
          </div>
          <div className={styles.progressCount}>
            {daysProgress}/{userActiveProgress?.daysNumber}
          </div>
        </div>
        <div className={styles.details} onClick={() => setIsOpenModal(true)}>
          <p className={styles.detailsText}>Show Details</p>
          <ArrowRight width={24} height={24} className={styles.detailsArrow} />
        </div>
      </div>
      <UserBoostcampModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        user={user}
        userActiveProgress={userActiveProgress}
        setUserActiveProgress={setUserActiveProgress}
      />
    </div>
  );
};

const UserBoostcampModal = ({
  isOpen,
  setIsOpen,
  user,
  userActiveProgress,
  setUserActiveProgress,
  isLoading,
}) => {
  const [currentDayIndex, setCurrentDayIndex] = useState(0);

  const [isOpenSleep, setIsOpenSleep] = useState(false);
  const [isOpenWeight, setIsOpenWeight] = useState(false);
  const [isOpenWater, setIsOpenWater] = useState(false);
  const [isOpenFasting, setIsOpenFasting] = useState(false);

  const [currentDay, setCurrentDay] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  // useEffect(() => {
  //   setCurrentDayIndex((prev) => (prev === 0 ? 1 : prev));
  // }, [userActiveProgress?.days]);

  const onUpdateDay = async () => {
    try {
      setIsUpdating(true);
      const body = {
        isCompleted: currentDay.isCompleted,
        tasks: currentDay.tasks,
        trackers: currentDay.trackers,
      };

      const res = await fetchRequest(
        `progress/user/${user.id}/boost-camp/${userActiveProgress.id}/day/${currentDay?.id}/`,
        "PUT",
        body
      );
      console.log(res);
      if (res?.success) {
        setUserActiveProgress((prev) => updateProgressDay(prev, res?.data));
        toast.success("Day was successfully updated!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const onUpdateTasks = (id, isChecked) => {
    console.log(id, isChecked);
    const newTasks = currentDay?.tasks?.map((el) =>
      el?.id === id ? { ...el, isChecked: !isChecked } : el
    );
    console.log(newTasks);
    setCurrentDay((prev) => ({ ...prev, tasks: newTasks }));
  };

  const onCompleteDay = () => {
    setCurrentDay((prev) => ({ ...prev, isCompleted: true }));
  };

  const initialSlide = useMemo(
    () => (user?.testMode ? 0 : userActiveProgress?.passedDays),
    [userActiveProgress, user]
  );

  useEffect(() => {
    if (userActiveProgress?.days?.length > currentDayIndex + 1)
      setCurrentDay(userActiveProgress?.days[currentDayIndex]);
  }, [currentDayIndex, userActiveProgress?.days]);

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        className={styles.modal}
        onRequestClose={() => setIsOpen(false)}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalInner}>
          <p className={styles.title}>{userActiveProgress?.title}</p>
          <div onClick={() => setIsOpen(false)} className={styles.closeBtn}>
            <CrossIcon />
          </div>
          {isLoading ? (
            <div className={styles.loader}>
              <Spinner color={"#8C42E3"} size={30} />
            </div>
          ) : (
            <div className={styles.container}>
              <div className={styles.slider}>
                <UserBoostcampSlider
                  boostcamp={userActiveProgress}
                  currentDay={currentDayIndex}
                  setCurrentDay={setCurrentDayIndex}
                  initialSlide={initialSlide}
                  isOpen={isOpen}
                />
              </div>
              <div className={styles.stats}>
                <div className={styles.trackers}>
                  <DailyTaskSetItem
                    icon={<ChecklistIcon />}
                    title="Current weight"
                    color={"#60C05533"}
                    btnColor={"#60C055"}
                    onClick={() => setIsOpenWeight(true)}
                    value={currentDay?.trackers || null}
                    type={"weight"}
                    infoPosition={"bottom"}
                    isClosed={currentDay?.isClose || currentDay?.isCompleted}
                  />
                  <DailyTaskSetItem
                    icon={<FastingIcon />}
                    title="Fasting window"
                    color={"#F0512233"}
                    btnColor={"#F05122"}
                    onClick={() => setIsOpenFasting(true)}
                    value={currentDay?.trackers || null}
                    type={"fasting"}
                    isClosed={currentDay?.isClose || currentDay?.isCompleted}
                  />
                  <DailyTaskSetItem
                    icon={<WaterIcon />}
                    title="Water intake"
                    color={"#79DCD533"}
                    btnColor={"#79DCD5"}
                    onClick={() => setIsOpenWater(true)}
                    value={currentDay?.trackers || null}
                    type={"water"}
                    isClosed={currentDay?.isClose || currentDay?.isCompleted}
                  />
                  <DailyTaskSetItem
                    icon={<SleepIcon />}
                    title="Sleep tracker"
                    color={"#8C42E326"}
                    btnColor={"#8C42E3"}
                    onClick={() => setIsOpenSleep(true)}
                    value={currentDay?.trackers || null}
                    type={"sleep"}
                    isClosed={currentDay?.isClose || currentDay?.isCompleted}
                  />
                </div>
                <div className={styles.tasks}>
                  <ChecklistTasks
                    currentDay={currentDay}
                    tasks={currentDay?.tasks || []}
                    onSetActiveTask={onUpdateTasks}
                    onCompleteDay={onCompleteDay}
                    isLoading={false}
                    isOpenCompleteTrackers={false}
                  />
                </div>
              </div>
              <Button
                title="Update Day"
                styleType="filled"
                size="large"
                loading={isUpdating}
                backgroundColor={"#8c42e3"}
                onClick={onUpdateDay}
                classes={styles.updateBtn}
              />
            </div>
          )}
        </div>
      </Modal>
      <SleepTracker
        isOpen={isOpenSleep}
        setIsOpen={setIsOpenSleep}
        currentDay={currentDay}
        setCurrentDay={setCurrentDay}
      />
      <WaterTracker
        isOpen={isOpenWater}
        setIsOpen={setIsOpenWater}
        currentDay={currentDay}
        setCurrentDay={setCurrentDay}
      />
      <FastingTracker
        isOpen={isOpenFasting}
        setIsOpen={setIsOpenFasting}
        currentDay={currentDay}
        setCurrentDay={setCurrentDay}
      />
      <WeightTracker
        isOpen={isOpenWeight}
        setIsOpen={setIsOpenWeight}
        currentDay={currentDay}
        setCurrentDay={setCurrentDay}
      />
    </>
  );
};

export default UserBoostcamp;
