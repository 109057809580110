import { DELETE_ADMIN, SET_ADMINS } from "../../actionTypes/adminData.js";

import fetchRequest from "utils/fetchRequest.js";

// action
export const setAdmins = (payload) => ({ type: SET_ADMINS, payload });
export const deleteAdmin = (payload) => ({ type: DELETE_ADMIN, payload });

export const getAdminsThunk = () => async (dispatch) => {
  const response = await fetchRequest("admins", "GET");
  if (response && response.status === 200) {
    dispatch(setAdmins(response.data.rows));
  }
};

export const deleteAdminThunk = (id) => async (dispatch) => {
  const response = await fetchRequest(`admins/${id}`, "DELETE");

  setTimeout(() => dispatch(deleteAdmin(id)), 500);
};
