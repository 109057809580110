import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useWindowDimensions } from "../../hooks";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const StatisticCharts = ({
  activeTrackingType,
  trackingOption,
  statistics,
  activeTrackingBtn,
  isTestUser = false,
}) => {
  const [dataPoints, setDataPoints] = useState([]);
  const [labels, setLabels] = useState([]);

  const { width } = useWindowDimensions();

  const generateLabels = () => {
    if (trackingOption === "day") {
      setLabels(["1"]);
    } else {
      setLabels(
        statistics?.days.map((item, index) =>
          !isTestUser
            ? moment(new Date(item?.date.slice(0, -1))).format("D/MM/yyyy")
            : index + 1
        ) || [""]
      );
    }
  };

  const generateDataPoints = () => {
    if (activeTrackingType === "sleep") {
      setDataPoints(
        statistics?.days.map((item, index) => {
          return +item?.trackers?.sleep;
        }) || []
      );
    }

    if (activeTrackingType === "water") {
      setDataPoints(
        statistics?.days.map((item, index) => {
          const current =
            activeTrackingBtn === 0
              ? +item?.trackers?.water?.flOz
              : +item?.trackers?.water?.l;
          return activeTrackingBtn === 0 ? current : current > 3 ? 3 : current;
        }) || []
      );
    }
    if (activeTrackingType === "eat") {
      setDataPoints(
        statistics?.days.map((item, index) => {
          return +item?.trackers?.eat;
        }) || []
      );
    }
    if (activeTrackingType === "weight") {
      setDataPoints(
        statistics?.days.map((item, index) => {
          const current =
            activeTrackingBtn === 0
              ? +item?.trackers?.weight?.ibs
              : +item?.trackers?.weight?.kg;
          return current;
        }) || []
      );
    }
    if (activeTrackingType === "body") {
      setDataPoints(
        statistics?.days.map((item, index) => {
          return +item?.trackers?.proportions?.bust;
        }) || []
      );
    }
  };

  useEffect(() => {
    generateLabels();
    generateDataPoints();
  }, [statistics, trackingOption, activeTrackingType, activeTrackingBtn]);

  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const renderColor = () => {
    if (activeTrackingType === "sleep") {
      return "#8c42e3";
    }
    if (activeTrackingType === "water") {
      return "#79DCD5";
    }
    if (activeTrackingType === "eat") {
      return "#F05122";
    }
    if (activeTrackingType === "weight") {
      return "#60C055";
    }
    if (activeTrackingType === "body") {
      return "#FCD930";
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: "",
        data: dataPoints,
        borderColor: renderColor(),
        backgroundColor: "rgba(255, 255, 255, 0)",
        borderWidth: width <= 768 ? 2 : 3,
        hoverBorderWidth: width <= 768 ? 2 : 3,
        pointRadius: width <= 768 ? 3 : 5,
        pointHoverRadius: width <= 768 ? 5 : 7,
        pointBackgroundColor: renderColor(),
        // fill: false,
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export default StatisticCharts;
