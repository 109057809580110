import clsx from "clsx";
import React, { FC, useState } from "react";
import Checkbox from "../Checkbox";
import styles from "./styles.module.scss";

const TaskItem = ({ isChecked, title, points, onPress }) => {
  return (
    <div className={styles.wrapper}>
      <Checkbox isChecked={isChecked} setIsChecked={onPress} />
      <p className={clsx(styles.title)}>{title}</p>
      <p className={clsx(styles.points)}>+{points}</p>
    </div>
  );
};

export default TaskItem;
