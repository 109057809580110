import React from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";

const PageItem = ({ page = 1, isActive = false, onPress }) => {
  return (
    <div
      onClick={onPress}
      className={clsx(styles.wrapper, { [styles.activeWrapper]: isActive })}
    >
      <span className={clsx(styles.text, { [styles.activeText]: isActive })}>
        {page}
      </span>
    </div>
  );
};

export default PageItem;
