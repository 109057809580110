import { useToggleModal } from "hooks";
import { LogoutModal } from "modals";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
// sections
import { Sidebar } from "sections";
import { fetchRequest } from "utils";
//styles
import styles from "./styles.module.scss";

import { setMyRole } from "../../redux/actions/profileData";

const Dashboard = () => {
  const adminModal = useToggleModal();
  const [admin, setAdmin] = useState(null);
  const dispatch = useDispatch();

  const getAdmin = async () => {
    const response = await fetchRequest("admins/me", "GET");
    if (response.status === 200) {
      dispatch(setMyRole(response.data.role));
    }
  };

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <>
      <div className={styles.dashboard}>
        <Sidebar admin={admin} onClick={adminModal.showModal} />
        <div className={styles.dashboard__inner}>
          <Outlet />
        </div>
      </div>
      {adminModal.isShow && (
        <LogoutModal
          showModal={adminModal.showModal}
          closeModal={adminModal.closeModal}
          isOpen={adminModal.isShow}
        />
      )}
    </>
  );
};

export default Dashboard;
