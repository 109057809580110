import React from "react";
import { ClipLoader } from "react-spinners";
import clsx from "clsx";
import styles from "./styles.module.scss";
import { CheckBigIcon } from "assets";

const Checkbox = ({
  isChecked,
  setIsChecked,
  iconSize = 12,
  loading = false,
  disabled,
}) => {
  return (
    <div
      onClick={() => {
        if (!loading && !disabled) {
          setIsChecked(!isChecked);
        }
      }}
      className={clsx({
        [styles.checked]: isChecked,
        [styles.unChecked]: !isChecked,
        [styles.loading]: loading,
        [styles.disabled]: disabled,
      })}
      style={{
        maxWidth: iconSize + 4,
        minWidth: iconSize + 4,
        height: iconSize + 4,
      }}
    >
      {loading ? (
        <>
          <ClipLoader color={"#8C42E3"} size={iconSize - 4 || 4} />
        </>
      ) : (
        isChecked && <CheckBigIcon width={iconSize} height={iconSize} />
      )}
    </div>
  );
};

export default Checkbox;
