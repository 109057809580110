import React, { FC } from "react";
import styles from "./styles.module.scss";
import { StartIcon } from "../../assets";

const ReviewItemPreview = ({
  avatar,
  name,
  city,
  rating,
  text,
  isMobile = false,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.avatarBlock}>
        <img src={avatar} className={styles.avatar} />
      </div>
      <div className={styles.textBlock}>
        <p className={styles.name}>{name}</p>
        <p className={styles.city}>{city}</p>
        <div className={styles.ratingWrapper}>
          {Array(rating)
            .fill(0)
            .map((_, index) => (
              <StartIcon key={index} />
            ))}
        </div>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};

export default ReviewItemPreview;
