//react
import React, { useState, useCallback, useEffect } from "react";

import clsx from "clsx";
import { Spinner } from "react-activity";
import { toast } from "react-toastify";

//components
import {
  Input,
  QuestionItem,
  Button,
  RecipesItem,
  ReviewItem,
  RichTextEditor,
  AttachMealPlanBtn,
} from "components";
import BoostCampPriceItem from "./BoostCampPriceItem";
import { CustomAlert } from "components";
//navigation
import { useNavigate } from "react-router-dom";

//header
import CreateBoostCampHeader from "./BoostCampHeader/boostCampHeader";

//assets
import { ImgPlaceholder, Add, PlusIcon } from "assets";

//utils
import { fetchRequest } from "utils";

//hooks
import { useToggleModal } from "../../../../hooks";

//services
import { deleteFile, uploadFile } from "services/file.service";

//styles
import styles from "./styles.module.scss";

const CreateBoostcamps = ({}) => {
  const [title, setTitle] = useState("");
  const [numberOfDay, setNumberOfDay] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [price, setPrice] = useState("");
  const [discountEnable, setDiscountEnable] = useState(false);
  const [discount, setDiscount] = useState("");
  const [discountOptions, setDiscountOptions] = useState("%");
  const [preview, setPreview] = useState();
  const [picture, setPicture] = useState();
  const [photo, setPhoto] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [fullOverview, setFullOverview] = useState("");
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [priceItems, setPriceItems] = useState([
    {
      title: "Monday 1",
      price: "",
      discount: { count: 0, type: "percentage" },
      link: "",
    },
    {
      title: "Monday 2",
      price: "",
      discount: { count: 0, type: "percentage" },
      link: "",
    },
    {
      title: "Monday 3",
      price: "",
      discount: { count: 0, type: "percentage" },
      link: "",
    },
    {
      title: "Monday 4",
      price: "",
      discount: { count: 0, type: "percentage" },
      link: "",
    },
  ]);
  const [reviews, setReviews] = useState([]);
  const [mealPlans, setMealPlans] = useState([
    { title: "Week 1 + Week 3", meal: null },
    { title: "Week 2 + Week 4", meal: null },
  ]);

  const [recipes, setRecipes] = useState([
    { title: "Upload PDF file for shopping list", fileUrl: "" },
  ]);

  const [mealPlanPdf, setMealPlanPdf] = useState([
    {
      title: "Upload PDF file for meal plan",
      fileUrl: "",
    },
  ]);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
  }, []);

  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [isLoadingDeleteFile, setIsLoadingDeleteFile] = useState(false);

  //errorState
  const [titleError, setTitleError] = useState(false);
  const [numberOfDayError, setNumberOfDayError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [pictureError, setPictureError] = useState(false);
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [fullOverviewError, setFullOverviewError] = useState(false);
  const [mealPdfError, setMealPdfError] = useState(false);
  const [shoppingPdfError, setShoppingPdfError] = useState(false);
  const [printRecipeErrors, setPrintRecipeErrors] = useState({
    price: false,
    title: false,
    text: false,
  });
  const [reviewsError, setReviewsError] = useState(false);

  const [serverError, setServerError] = useState(false);

  //print
  const [printRecipePrice, setPrintRecipePrice] = useState();
  const [printRecipeTitle, setPrintRecipeTitle] = useState();
  const [printRecipeText, setPrintRecipeText] = useState();

  const cancelModal = useToggleModal();

  const showErrors = ({
    titleErr,
    numberOfDayErr,
    previewErr,
    printRecipeErr,
    shortDescriptionErr,
    mealPdfErr,
    shopingPdfErr,
    fullOverviewErr,
  }) => {
    setTitleError(titleErr);
    setNumberOfDayError(numberOfDayErr);
    setPictureError(previewErr);
    setPrintRecipeErrors(printRecipeErr);
    setShortDescriptionError(shortDescriptionErr);
    setMealPdfError(mealPdfErr);
    setShoppingPdfError(shopingPdfErr);
    setFullOverviewError(fullOverviewErr);

    if (titleErr) toast.error("Title field must be at least 3 letters long");
    if (numberOfDayErr) toast.error("Number of days field is required");
    if (previewErr) toast.error("Preview is required");
    if (printRecipeErr?.price)
      toast.error("Price for the Print Recipe field is required");
    if (printRecipeErr?.title)
      toast.error("Price for the Print Recipe is required");
    if (printRecipeErr?.text)
      toast.error("Print recipe description field is required");
    if (shortDescriptionErr) toast.error("Short description is required");
    if (mealPdfErr) toast.error("Meal Plan PDF is required");
    if (shopingPdfErr) toast.error("Shopping list is required");
    if (fullOverviewErr) toast.error("Full overview field is required");

    const printRecipeErrs =
      printRecipeErr?.price || printRecipeErr?.title || printRecipeErr?.text;

    const hasAnyError =
      titleErr ||
      numberOfDayErr ||
      previewErr ||
      printRecipeErrs ||
      shortDescriptionErr ||
      mealPdfErr ||
      shopingPdfErr ||
      fullOverviewErr;

    console.log(hasAnyError, {
      titleErr,
      numberOfDayErr,
      previewErr,
      printRecipeErr,
      shortDescriptionErr,
      mealPdfErr,
      shopingPdfErr,
      fullOverviewErr,
      printRecipeErrs,
    });

    return hasAnyError;
  };

  const saveBoostcamp = async () => {
    try {
      //ERROR handle start
      const hasTitleError = title.length < 3;
      const hasNumberOfDayError = !numberOfDay.length;
      const hasPreviewError = !preview;
      const hasShortDescriptionError = !shortDescription;
      const hasMealPdfError = !mealPlanPdf[0]?.fileUrl;
      const hasShoppingPdfError = !recipes[0]?.fileUrl;
      const hasFullOverviewError = !fullOverview;

      const hasErrors = showErrors({
        titleErr: hasTitleError,
        numberOfDayErr: hasNumberOfDayError,
        previewErr: hasPreviewError,
        printRecipeErr: {
          price: !printRecipePrice,
          title: !printRecipeTitle,
          text: !printRecipeText,
        },
        shortDescriptionErr: hasShortDescriptionError,
        mealPdfErr: hasMealPdfError,
        shopingPdfErr: hasShoppingPdfError,
        fullOverviewErr: hasFullOverviewError,
      });
      if (hasErrors) return;
      //ERROR handle end

      setIsLoadingSave(true);
      const reviewsCopy = reviews.map((item) => ({
        name: item.name,
        city: item.city,
        text: item.text,
        rating: item.rating.value,
        avatar: item.avatar,
      }));

      const body = {
        title,
        daysNumber: numberOfDay,
        prices: priceItems,
        description: shortDescription,
        picture: preview,
        overview: fullOverview,
        faq: questions,
        days: [
          {
            title: " ",
            shortDescription: " ",
            fullDescription: " ",
            order: 1,
            tasks: [
              {
                task: "",
                points: 0,
              },
            ],
            remainders: [],
          },
        ],
        reviews: reviewsCopy,
        mealPlan: mealPlans?.map((item) => item?.meal?.id),
        shoppingListPdf: recipes[0]?.fileUrl,
        mealPlanPdf: mealPlanPdf[0]?.fileUrl,
        printRecipe: {
          price: printRecipePrice,
          title: printRecipeTitle,
          text: printRecipeText,
        },
      };

      const res = await fetchRequest("boost-camps", "POST", body);
      if (res?.status === 201) {
        navigate(`filldays/${res?.data?.id}`);
      } else {
        setServerError(res);
      }
      setIsLoadingSave(false);
    } catch (error) {
      setIsLoadingSave(false);
      console.log(error);
      setServerError(error);
    }
  };

  const navigateToNextStep = () => {
    // navigate("fillDaysBoostCamps", {
    //   replace: false,
    //   state: {
    //     title,
    //     daysNumber: numberOfDay,
    //     price,
    //     description: shortDescription,
    //     overview: fullOverview,
    //     faq: questions,
    //   },
    // });

    saveBoostcamp();
  };

  const [questions, setQuestions] = useState([{ question: "", answer: "" }]);

  const navigate = useNavigate();

  const onChangefullOverview = (e) => {
    setFullOverview(e);
  };

  const onChangeShortDescription = (e) => {
    setShortDescription(e);
  };

  const onChangeTitle = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const onChangeNumberOfDay = useCallback((e) => {
    setNumberOfDay(e.target.value);
  });

  // const onChangeStartDate = useCallback((e) => {
  //   setStartDate(e.target.value);
  // });

  // const onChangePrice = useCallback((e) => {
  //   setPrice(e.target.value);
  // });

  // const onChangeDiscount = useCallback((e) => {
  //   setDiscount(e.target.value);
  // });

  // const onChangeDiscountOptions = useCallback((e) => {
  //   setDiscountOptions(e.target.value);
  // });

  const onChangePrintRecipePrice = useCallback((e) => {
    setPrintRecipePrice(e.target.value);
  });
  const onChangePrintRecipeTitle = useCallback((e) => {
    setPrintRecipeTitle(e.target.value);
  });
  const onChangePrintRecipeText = (e) => {
    setPrintRecipeText(e);
  };

  const onDeleteImg = () => {};

  const onDeleteAnswer = (index) => {
    const questionsCopy = [...questions];
    questionsCopy.splice(index, 1);
    setQuestions(questionsCopy);
  };

  const onAddAnswer = () => {
    setQuestions((prev) => [...prev, { answer: "", question: "" }]);
  };

  const handleQuestionsChange = useCallback(
    (e, index) => {
      const questionsCopy = [...questions];
      questionsCopy[index].question = e.target.value;
      setQuestions(questionsCopy);
    },
    [questions]
  );

  const handleAnswerChange = useCallback(
    (e, index) => {
      const questionsCopy = [...questions];
      questionsCopy[index].answer = e.target.value;
      setQuestions(questionsCopy);
    },
    [questions]
  );

  const handleAddNewDate = useCallback(() => {
    const priceItemsCopy = [...priceItems];
    priceItemsCopy.push({
      title: `Monday ${priceItemsCopy.length + 1}`,
      price: "",
      discount: { count: 0, type: "percentage" },
      link: "",
    });
    setPriceItems(priceItemsCopy);
  }, [priceItems]);

  const onAddReview = useCallback(() => {
    const reviewsCopy = [...reviews];
    reviewsCopy.push({ name: "", city: "", text: "", rating: 5, avatar: "" });
    setReviews(reviewsCopy);
  });

  const onSelectFile = async (e) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setIsLoadingUploadFile(true);
      // setPicture(URL.createObjectURL(e.target.files[0]));
      const res = await uploadFile(e.target.files[0]);
      setPreview(res?.data?.url);
      setIsLoadingUploadFile(false);
    } catch (error) {
      setIsLoadingUploadFile(false);

      console.log(error);
    }
  };

  const onDeleteFile = async (e) => {
    try {
      setIsLoadingDeleteFile(true);

      const res = await deleteFile(preview);
      setPreview(undefined);
      setIsLoadingDeleteFile(false);
    } catch (e) {
      setIsLoadingDeleteFile(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (picture) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [picture]);

  return (
    <>
      <div className={styles.wrapper}>
        <CreateBoostCampHeader numbersOfStep={1} />
        <div className={styles.bootcampsSettingsWrapper}>
          <div className={styles.bootcampsSettingsLeftContent}>
            <Input
              value={title}
              onChange={onChangeTitle}
              label={"Title"}
              errorBorder={titleError}
            />
            <div className={styles.bootcampsSettingsNumbersOfDayWrapper}>
              <div className={styles.bootcampsSettingsNumbersOfDayInp}>
                <Input
                  type={"number"}
                  value={numberOfDay}
                  onChange={onChangeNumberOfDay}
                  label={"Number of days"}
                  errorBorder={numberOfDayError}
                />
              </div>
            </div>
            <div className={styles.bootcampsSettingsPriceWrapper}>
              <div className={styles.bootcampsSettingsPriceHeader}>
                <div
                  style={{ flex: 2, marginRight: "16px" }}
                  className={styles.inputTitle}
                >
                  Date
                </div>
                <div
                  style={{ flex: 1, marginRight: "16px" }}
                  className={styles.inputTitle}
                >
                  Price
                </div>
                <div
                  style={{
                    flex: 1,
                  }}
                  className={styles.inputTitle}
                >
                  Discount
                </div>
                <div style={{ flex: 1.85 }} className={styles.inputTitle}>
                  Discount type
                </div>
              </div>
              {priceItems.map((item, index) => (
                <BoostCampPriceItem
                  key={index}
                  item={item}
                  index={index}
                  priceItems={priceItems}
                  setPriceItems={setPriceItems}
                />
              ))}
              <div
                style={{ marginTop: "10px" }}
                onClick={handleAddNewDate}
                className={styles.faqAddQuestionBtn}
              >
                <PlusIcon />
                <div className={styles.faqAddQuestionBtnTitle}>
                  Add new Date
                </div>
              </div>
            </div>
            <div className={styles.bootcampsSettingsNumbersOfDayWrapper}>
              <div style={{ flex: 1, maxWidth: 350 }}>
                <Input
                  type={"number"}
                  value={printRecipePrice}
                  onChange={onChangePrintRecipePrice}
                  label={"Price for the Print Recipe feature ($)"}
                  errorBorder={printRecipeErrors.price}
                />
              </div>
            </div>
            <div className={styles.bootcampsSettingsNumbersOfDayWrapper}>
              <div style={{ flex: 1 }}>
                <Input
                  value={printRecipeTitle}
                  onChange={onChangePrintRecipeTitle}
                  label={
                    "Title of the Print Recipe feature (displaying next to checkbox)"
                  }
                  errorBorder={printRecipeErrors.title}
                />
              </div>
            </div>
            <div className={styles.boostcampsSettingsDescriptionWrapper}>
              <RichTextEditor
                initialValue={printRecipeText}
                setValue={onChangePrintRecipeText}
                label={"Print recipe description"}
                disableMedia
                error={printRecipeErrors.text}
              />
            </div>
            <div className={styles.boostcampsSettingsDescriptionWrapper}>
              <RichTextEditor
                initialValue={shortDescription}
                setValue={onChangeShortDescription}
                label={"Short description"}
                error={shortDescriptionError}
              />
            </div>
          </div>
          <div className={styles.bootcampsSettingsRightContent}>
            {preview ? (
              <img
                src={preview}
                className={clsx(styles.bootcampsSettingsPhoto, {
                  [styles.bootcampsSettingsPhotoError]: pictureError,
                })}
                alt={"photo"}
              />
            ) : (
              <div
                className={clsx(styles.bootcampsSettingsPhoto, {
                  [styles.bootcampsSettingsPhotoError]: pictureError,
                })}
              >
                <ImgPlaceholder />
              </div>
            )}
            <div className={styles.flex}>
              <label
                htmlFor={"photoInput"}
                className={styles.bootcampsSettingsUploadImgWrapper}
              >
                {isLoadingUploadFile ? (
                  <span>
                    <Spinner size={12} />
                  </span>
                ) : (
                  "Upload New"
                )}
                <input
                  id={"photoInput"}
                  type={"file"}
                  accept="image/*"
                  onChange={onSelectFile}
                  onClick={(e) => (e.target.value = null)}
                  className={styles.bootcampsSettingsUploadImgBtn}
                />
              </label>
              <div
                onClick={onDeleteFile}
                className={styles.bootcampsSettingsDeleteImgBtn}
              >
                {isLoadingDeleteFile ? (
                  <span>
                    <Spinner size={12} />
                  </span>
                ) : (
                  "Delete photo"
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.faqSectionWrapper}>
          <div className={styles.faqSectionTitleWrapper}>
            <div className={styles.faqTitle}>Meal Plan</div>
            <div className={styles.mealPlansBtnWrapper}>
              <div
                onClick={() => {
                  setMealPlans([
                    { title: "Week 1 + Week 3", meal: null },
                    { title: "Week 2 + Week 4", meal: null },
                  ]);
                }}
                className={clsx(styles.mealPlanBtn, {
                  [styles.mealPlanBtnInactive]: mealPlans?.length === 4,
                })}
              >
                2 week
              </div>
              <div
                onClick={() => {
                  setMealPlans([
                    { title: "Week 1", meal: null },
                    { title: "Week 2", meal: null },
                    { title: "Week 3", meal: null },
                    { title: "Week 4", meal: null },
                  ]);
                }}
                className={clsx(styles.mealPlanBtn, {
                  [styles.mealPlanBtnInactive]: mealPlans?.length === 2,
                })}
              >
                4 week
              </div>
            </div>
          </div>
          <div className={styles.attachMealPlanBtnWrapper}>
            {mealPlans.map((item, index) => {
              return (
                <AttachMealPlanBtn
                  meal={item?.meal}
                  title={item?.title}
                  index={index}
                  key={index}
                  setMealPlans={setMealPlans}
                  error={serverError?.field === `mealPlan[${index}]`}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.faqSectionWrapper}>
          <div className={styles.faqTitle}>Meal Plan PDF</div>
          <div className={styles.boostcampsSettingsDescriptionWrapper}>
            {mealPlanPdf.map((item, index) => (
              <RecipesItem
                key={index}
                item={item}
                index={index}
                setRecipes={setMealPlanPdf}
                id={`${index}_meal`}
                error={mealPdfError}
              />
            ))}
          </div>
        </div>
        <div className={styles.faqSectionWrapper}>
          <div className={styles.faqTitle}>Shopping list</div>
          <div className={styles.boostcampsSettingsDescriptionWrapper}>
            {recipes.map((item, index) => (
              <RecipesItem
                key={index}
                item={item}
                index={index}
                setRecipes={setRecipes}
                id={`${index}_recipe`}
                error={shoppingPdfError}
              />
            ))}
          </div>
        </div>
        <div className={styles.faqSectionWrapper}>
          <div className={styles.faqTitle}>Full overview</div>
          <div className={styles.boostcampsSettingsDescriptionWrapper}>
            {/*<TextAreaEditor*/}
            {/*  value={fullOverview}*/}
            {/*  onChange={onChangefullOverview}*/}
            {/*  classes={styles.fullOverview}*/}
            {/*  borderError={fullOverviewError}*/}
            {/*/>*/}
            <RichTextEditor
              initialValue={fullOverview}
              setValue={onChangefullOverview}
              error={fullOverviewError}
            />
          </div>
        </div>
        <div className={styles.faqSectionWrapper}>
          <div className={styles.faqTitle}>FAQ</div>
          {questions.map((item, index) => (
            <QuestionItem
              data={item}
              key={index}
              index={index}
              onDelete={() => onDeleteAnswer(index)}
              onQuestionsChange={handleQuestionsChange}
              onAnswerChange={handleAnswerChange}
            />
          ))}
          <div onClick={onAddAnswer} className={styles.faqAddQuestionBtn}>
            <Add />
            <div className={styles.faqAddQuestionBtnTitle}>Add Question</div>
          </div>
        </div>
        <div className={styles.reviewsSectionWrapper}>
          <div className={styles.reviewsSectionTitle}>Reviews</div>
          <div className={styles.reviewsSectionSubTitle}>Upload reviews</div>
          {reviews.map((item, index) => (
            <ReviewItem
              key={index}
              item={item}
              index={index}
              setReviews={setReviews}
            />
          ))}
          <div
            style={{ marginTop: "30px" }}
            onClick={onAddReview}
            className={styles.faqAddQuestionBtn}
          >
            <PlusIcon />
            <div className={styles.faqAddQuestionBtnTitle}>Add Review</div>
          </div>
        </div>
        <div className={styles.saveSectionWrapper}>
          <div style={{ marginRight: "10px" }}>
            <Button
              loading={isLoadingSave}
              onClick={navigateToNextStep}
              title={"Next step"}
            />
          </div>
          <div>
            <Button
              title={"Cancel"}
              onClick={cancelModal.showModal}
              emptyStyle={true}
            />
          </div>
        </div>
      </div>
      {cancelModal.isShow && (
        <CustomAlert
          showModal={cancelModal.showModal}
          closeModal={cancelModal.closeModal}
          title={"Are you sure you want to exit?"}
          text={""}
          titleBtn={"Yes"}
          titleSecondBtn={"No"}
          isOpen={cancelModal.isShow}
          onClick={() => navigate("/boostcamps/manage")}
        />
      )}
    </>
  );
};

export default CreateBoostcamps;
