import React from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { CheckBigIcon, HourGlass } from "assets";

const DayOfWeekItem = ({ type = "active", title, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={clsx(styles.wrapper, {
        [styles.activeWrapper]: type === "active",
        [styles.completeWrapper]: type === "complete",
      })}
    >
      {type === "active" && (
        <HourGlass color={type === "active" ? "#fff" : "#FCD930"} />
      )}
      {type === "complete" && (
        <CheckBigIcon width={20} height={20} style={{ color: "#60C055" }} />
      )}
      {type === "default" && <div className={styles.default} />}
      <span
        className={clsx(styles.title, {
          [styles.activeTitle]: type === "active",
          [styles.completeTitle]: type === "complete",
        })}
      >
        {title}
      </span>
    </div>
  );
};

export default DayOfWeekItem;
