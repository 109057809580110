import {
  Reports,
  Instructor,
  Settings,
  Statistics,
  Students,
  Logs,
  BoostCamps,
  CalendarIcon,
  RecipesSalad,
  ShopingIcon,
  Intro,
  User,
} from "assets";

export const menu = [
  {
    icon: () => <Reports />,
    title: "Statistics",
    to: "/statistics",
  },
  {
    icon: () => <User />,
    title: "Users",
    to: "/users",
    links: [
      {
        title: "PWA Users",
        to: "/users/main",
      },
      {
        title: "PWA Test Users",
        to: "/users/test",
      },
      {
        title: "PWA Beta Testers",
        to: "/users/beta",
      },
      // {
      //   title: "PWA VIP Users",
      //   to: "/users/vip",
      // },
    ],
  },
  {
    icon: () => <BoostCamps />,
    title: "Boost Сamps",
    to: "/boostcamps/manage",
    links: [
      {
        title: "Manage Boost Camps",
        to: "/boostcamps/manage",
      },
      {
        title: "Create Boost Camps",
        to: "/boostcamps/create",
      },
    ],
  },
  {
    icon: () => <RecipesSalad />,
    title: "Recipes",
    to: "",
    links: [
      {
        title: "Manage Recipes",
        to: "/recipes/manage",
      },
      {
        title: "Create New Recipe",
        to: "/recipes/create",
      },
      {
        title: "Ingredients",
        to: "/ingredients",
      },
      {
        title: "Categories",
        to: "/categories",
      },
      {
        title: "Units",
        to: "/units",
      },
      {
        title: "Tags",
        to: "/tags",
      },
    ],
  },
  {
    icon: () => <CalendarIcon />,
    title: "Meal Plans",
    to: "/mealplans",
    links: [
      {
        title: "Manage Meal Plans",
        to: "/mealplans/manage",
      },
      {
        title: "Create New Meal Plan",
        to: "/mealplans/create",
      },
    ],
  },
  {
    icon: () => <ShopingIcon />,
    title: "Shopping Lists",
    links: [
      {
        title: "Manage Shopping Lists",
        to: "/shopping-list/manage",
      },
      {
        title: "Create New Shopping List",
        to: "/shopping-list/create",
      },
    ],
  },
  {
    icon: () => <Intro />,
    title: "Intro Screens",
    to: "/intro-screens/manage",
  },
  // {
  //   icon: () => <Reports />,
  //   title: "User Requests",
  //   to: "/requests",
  // },
  {
    icon: () => <BoostCamps />,
    title: "Notifications",
    to: "/notifications",
  },
];

export const menuAdmin = [
  {
    icon: () => <Reports />,
    title: "Statistics",
    to: "/statistics",
  },
  {
    icon: () => <User />,
    title: "Users",
    to: "/users",
    links: [
      {
        title: "PWA Users",
        to: "/users/main",
      },
      {
        title: "PWA Test Users",
        to: "/users/test",
      },
      {
        title: "PWA Beta Testers",
        to: "/users/beta",
      },
      // {
      //   title: "PWA VIP Users",
      //   to: "/users/vip",
      // },
    ],
  },
  {
    icon: () => <BoostCamps />,
    title: "Boost Сamps",
    to: "/boostcamps/manage",
    links: [
      {
        title: "Manage Boost Camps",
        to: "/boostcamps/manage",
      },
      {
        title: "Create Boost Camps",
        to: "/boostcamps/create",
      },
    ],
  },
  {
    icon: () => <RecipesSalad />,
    title: "Recipes",
    to: "",
    links: [
      {
        title: "Manage Recipes",
        to: "/recipes/manage",
      },
      {
        title: "Create New Recipe",
        to: "/recipes/create",
      },
      {
        title: "Ingredients",
        to: "/ingredients",
      },
      {
        title: "Categories",
        to: "/categories",
      },
      {
        title: "Units",
        to: "/units",
      },
      {
        title: "Tags",
        to: "/tags",
      },
    ],
  },
  {
    icon: () => <CalendarIcon />,
    title: "Meal Plans",
    to: "/mealplans",
    links: [
      {
        title: "Manage Meal Plans",
        to: "/mealplans/manage",
      },
      {
        title: "Create New Meal Plan",
        to: "/mealplans/create",
      },
    ],
  },
  {
    icon: () => <ShopingIcon />,
    title: "Shopping Lists",
    links: [
      {
        title: "Manage Shopping Lists",
        to: "/shopping-list/manage",
      },
      {
        title: "Create New Shopping List",
        to: "/shopping-list/create",
      },
    ],
  },
  {
    icon: () => <Intro />,
    title: "Intro Screens",
    to: "/intro-screens/manage",
  },
  {
    icon: () => <ShopingIcon />,
    title: "Tutorials",
    links: [
      {
        title: "PWA Tutorial",
        to: "/tutorials/home",
      },
      {
        title: "Boostcamp Tutorial",
        to: "/tutorials/boostcamp",
      },
    ],
  },
  // {
  //   icon: () => <Reports />,
  //   title: "User Requests",
  //   to: "/requests",
  // },
  {
    icon: () => <Settings />,
    title: "Settings",
    to: "/settings",
  },
  {
    icon: () => <BoostCamps />,
    title: "Notifications",
    to: "/notifications",
  },
];
