//react
import React, { useState, useEffect } from "react";
import { Spinner } from "react-activity";

//components
import { TutorialItem } from "components";

//assets
import { Plus } from "assets";

//navigation
import { useNavigate } from "react-router-dom";

//styles
import styles from "./styles.module.scss";

//utils
import { fetchRequest } from "utils";

const HomeTutorial = () => {
  const [tooltips, setTooltips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onGetTooltips = async () => {
    try {
      setIsLoading(true);
      const response = await fetchRequest(`tooltips/?type=GENERAL`, "GET");
      if (response?.status === 200) {
        setTooltips(response?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    onGetTooltips();
  }, []);

  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <p className={styles.header__title}>PWA Tutorial</p>
        </div>
        <div className={styles.tutorialListContainer}>
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Spinner color={"#8C42E3"} size={40} />
            </div>
          ) : (
            <div className={styles.tutorialList}>
              {tooltips.map((item, index) => (
                <TutorialItem key={item?.id} item={item} index={index} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeTutorial;
