import React, { InputHTMLAttributes } from "react";
import clsx from "clsx";
//styles
import styles from "./styles.module.scss";

const Input = ({
  label,
  placeholder = "",
  type = "text",
  classes,
  classesInner,
  error,
  id,
  name,
  onChange,
  value,
  errorBorder = false,
  disabled = false,
}) => {
  return (
    <div className={clsx(styles.input, classesInner)}>
      {label && <h4>{label}</h4>}
      <input
        type={type}
        placeholder={placeholder}
        className={clsx(styles.input__main, classes, {
          [styles.error]: error,
          [styles.borderError]: errorBorder,
        })}
        name={name}
        onChange={onChange}
        value={value}
        onWheel={() => document.activeElement.blur()}
        disabled={disabled}
      />
      {error && <p>{error}</p>}
    </div>
  );
};

export default Input;
