import { AttachIcon, FileIcon, PencilSmallIcon } from "assets";
import React, { useState } from "react";
import styles from "./styles.module.scss";
import AttachMealModal from "components/AttachMealModal";
import clsx from "clsx";

const AttachMealPlanBtn = ({ meal, title, index, setMealPlans, error }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onSelectMeal = (item) => {
    setMealPlans((prev) => {
      const copy = [...prev];
      copy[index].meal = item;
      return copy;
    });
  };

  return (
    <div className={clsx(styles.wrapper, error && styles.wrapper_error)}>
      <p className={styles.title}>{title}</p>
      {!meal ? (
        <div onClick={() => setIsOpenModal(true)} className={styles.attachBtn}>
          <AttachIcon />
          Attach Meal Plan
        </div>
      ) : (
        <div
          onClick={() => setIsOpenModal(true)}
          className={styles.mealPlanContainer}
        >
          <FileIcon />
          <div className={styles.infoBlock}>
            <p className={styles.title}>{meal?.title}</p>
            <p className={styles.description}>{meal?.description}</p>
          </div>
          <PencilSmallIcon
            width={20}
            height={20}
            style={{ color: "#8C42E3" }}
          />
        </div>
      )}
      <AttachMealModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        onSelectMeal={onSelectMeal}
      />
    </div>
  );
};

export default AttachMealPlanBtn;
