//react
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Spinner } from "react-activity";
import styled from "@emotion/styled";

//components
import { Button, IntroScreenItem } from "components";

//assets
import { Plus } from "assets";

//navigation
import { useNavigate } from "react-router-dom";

//styles
import styles from "./styles.module.scss";

//utils
import { fetchRequest } from "utils";
import { useDebounce } from "hooks";

const ManageIntroScreens = () => {
  const [introScreens, setIntroScreens] = useState([]);
  const [rerender, setRerender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [reorderedIntroScreens, setReorderedIntroScreens] = useState([]);
  const debouncedReorderedIntroScreens = useDebounce(
    reorderedIntroScreens,
    2000
  );

  const onGetInfoScreens = async (page) => {
    try {
      setIsLoading(true);
      const response = await fetchRequest(`info-blocks`, "GET");
      console.log("response", response);
      if (response?.status === 200) {
        setIntroScreens(response?.data);
      }
      // setIntroScreens([
      //   {
      //     id: "1230",
      //     body: "Test long long",
      //     title: "Title",
      //     date: new Date(),
      //     order: 0,
      //   },
      //   {
      //     id: "1231",
      //     body: "Test",
      //     title: "Title",
      //     date: new Date(),
      //     order: 1,
      //   },
      // ]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    onGetInfoScreens();
  }, [rerender]);

  const navigate = useNavigate();

  const reorderIntroScreens = async (introScreens) => {
    try {
      // setIsLoading(true);
      const introScreensIds = introScreens.map((int) => int?.id);
      const body = {
        ids: introScreensIds,
      };
      const response = await fetchRequest(`info-blocks/order`, "PUT", body);
      if (response?.status === 202) {
        const mapedIntroScreens = response?.data?.map((scr, index) => ({
          ...scr,
          order: index + 1,
        }));
        setIntroScreens(mapedIntroScreens);
        console.log("response?.data", response?.data);
      }
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    if (
      debouncedReorderedIntroScreens &&
      debouncedReorderedIntroScreens?.length > 0
    )
      reorderIntroScreens(debouncedReorderedIntroScreens);
  }, [debouncedReorderedIntroScreens]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newIntroScreens = reorder(
      introScreens,
      result.source.index,
      result.destination.index
    );

    setIntroScreens(newIntroScreens);
    setReorderedIntroScreens(newIntroScreens);
  }

  const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  `;

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <p className={styles.header__title}>Intro Screens</p>
          <div>
            <Button
              onClick={() => navigate("/intro-screens/create")}
              title={"Add New Intro Screen"}
              icon={() => <Plus />}
            />
          </div>
        </div>
        <div className={styles.boostCampsList}>
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Spinner color={"#8C42E3"} size={40} />
            </div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="list"
                className={styles.boostCampsListDroppable}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {introScreens.map((item, index) => (
                      <Draggable
                        draggableId={item?.id}
                        index={index}
                        key={item?.id}
                        // disableInteractiveElementBlocking={false}
                      >
                        {(provided) => (
                          <StyledDiv
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <IntroScreenItem
                              key={item?.id}
                              item={item}
                              setRerender={setRerender}
                              index={index}
                            />
                          </StyledDiv>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageIntroScreens;
