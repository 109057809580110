import React from "react";

// assets
import { Done } from "assets";
const TopModal = ({ title = "" }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: "37%",
        width: 440,
        height: 136,
        backgroundColor: "#EEE3FB",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        zIndex: 999,
        borderRadius: 20,
      }}
    >
      <p
        style={{
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: 20,
          lineHeight: "30px",
        }}
      >
        {title}
      </p>
      <div>
        <Done />
      </div>
    </div>
  );
};

export default TopModal;
