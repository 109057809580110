//react
import moment from "moment";
import React, { useRef, useState } from "react";
import { Spinner } from "react-activity";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

//assets
import {
  Filter,
  Delete,
  DotsIcon,
  PencilSmallIcon,
  CopyIcon,
  Drag,
} from "../../assets";

//modals
import { DeleteModal } from "modals";

//hooks
import { useOutsideClick, useToggleModal } from "hooks";

//components
import { Switch } from "components";

//navigation
import { useNavigate } from "react-router-dom";

//styles
import styles from "./styles.module.scss";

//utils
import { fetchRequest } from "utils";

const IntroScreenItem = ({ item, setRerender, index }) => {
  const navigate = useNavigate();
  const deleteModal = useToggleModal();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(true);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  // const [isLoadingDublicate, setIsLoadingDublicate] = useState(false);

  const ref = useRef();
  useOutsideClick(ref, () => setIsOpenMenu(false));

  const onDeleteIntroScreen = async () => {
    try {
      setIsLoadingDelete(true);
      const res = await fetchRequest(`info-blocks/${item?.id}`, "DELETE");
      setIsLoadingDelete(false);
      deleteModal.closeModal();

      setRerender((prev) => !prev);
    } catch (error) {
      console.log(error);
      setIsLoadingDelete(false);
    }
  };

  // const onDublicate = async () => {
  //   try {
  //     setIsLoadingDublicate(true);
  //     const res = await fetchRequest(
  //       `boost-camps/${item.id}/duplicate`,
  //       "POST"
  //     );
  //     if (res?.success) {
  //       setRerender((prev) => !prev);
  //     }
  //     setIsLoadingDublicate(false);
  //   } catch (error) {
  //     setIsLoadingDublicate(false);
  //   }
  // };

  function extractContent(s, space) {
    var span = document.createElement("span");
    span.innerHTML = s;
    if (space) {
      var children = span.querySelectorAll("*");
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else children[i].innerText += " ";
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
  }

  return (
    <>
      <div
        // onClick={() => navigate(`/boostcamps/edit/main/${item?.id}`)}
        ref={ref}
        className={styles.wrapper}
      >
        <Drag />
        <div className={styles.order}>
          {!isNaN(item?.order) ? item?.order : ""}
        </div>
        <div className={styles.photoContainer}>
          {isLoadingImg && (
            <div className={styles.photoLoader}>
              <Spinner color={"#8C42E3"} size={14} />
            </div>
          )}
          <img
            alt="picture"
            src={item?.image}
            onLoad={() => setIsLoadingImg(false)}
            className={styles.photo}
          />
        </div>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{item?.title}</p>
          <p className={styles.subtitle}>
            {extractContent(item?.body).substring(0, 250)}
          </p>
          <p className={styles.date}>
            Edited on {moment(item?.updatedAt).format("MMMM DD, YYYY")}
          </p>
        </div>

        <div
          className={styles.filterBtn}
          onClick={() => setIsOpenMenu((prev) => !prev)}
        >
          <DotsIcon className={styles.menuBtn} />
        </div>
        {isOpenMenu && (
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className={styles.menuWrapper}
          >
            {/* <div onClick={onDublicate} className={styles.menuButton}>
              {isLoadingDublicate ? (
                <div className={styles.deleteLoader}>
                  <Spinner color={"#000"} size={11} />
                </div>
              ) : (
                <>
                  <div className={styles.copyIconWrapper}>
                    <CopyIcon />
                  </div>

                  <span style={{ marginLeft: "7px" }}>Duplicate</span>
                </>
              )}
            </div> */}
            <div
              onClick={() => navigate(`/intro-screens/edit/${item?.id}`)}
              className={styles.menuButton}
            >
              <PencilSmallIcon width={19} height={19} />
              <span style={{ marginLeft: "7px" }}>Edit</span>
            </div>
            <div
              onClick={() => {
                setIsOpenMenu(false);
                deleteModal.showModal();
              }}
              className={styles.menuButton}
            >
              {isLoadingDelete ? (
                <div className={styles.deleteLoader}>
                  <Spinner color={"#EE3758"} size={11} />
                </div>
              ) : (
                <>
                  <Delete />
                  <span style={{ color: "#EE3758" }}>Delete</span>
                </>
              )}
            </div>
          </motion.div>
        )}
      </div>

      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={"Are you sure you want to delete?"}
          onClick={onDeleteIntroScreen}
          loading={isLoadingDelete}
        />
      )}
    </>
  );
};

export default IntroScreenItem;
