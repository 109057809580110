import React from "react";
//components
import { Button } from "components";
//styles
import styles from "./styles.module.scss";

const AdminItem = ({ admin, onClick }) => {
  return (
    <div className={styles.adminItem}>
      <p>{admin?.email}</p>
      <Button
        title="Delete"
        darkStyle
        classes={styles.adminItem__btn}
        onClick={() => onClick(admin)}
      />
    </div>
  );
};

export default AdminItem;
