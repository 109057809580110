import React from "react";
import clsx from "clsx";
//sections
import { ModalMain } from "sections";
//components
import { Button } from "components";
//assets
import { Close } from "assets";
//styles
import styles from "./styles.module.scss";

const CustomAlert = ({
  showModal,
  closeModal,
  title,
  text,
  titleBtn = "Delete",
  isOpen,
  onClick,
  loading = false,
  titleSecondBtn = "Cancel",
}) => {
  return (
    <ModalMain
      showModal={showModal}
      closeModal={closeModal}
      classes={styles.alert}
      isOpen={isOpen}
    >
      <button className={styles.alert__close} onClick={closeModal}>
        <Close />
      </button>
      <h2>{title}</h2>
      <p>{text}</p>
      <div className={styles.alert__btns}>
        <Button
          title={titleSecondBtn}
          emptyStyle
          classes={styles.alert__btn}
          onClick={closeModal}
        />
        <Button
          title={titleBtn}
          classes={clsx(styles.alert__btn, styles.main)}
          onClick={onClick}
          loading={loading}
        />
      </div>
    </ModalMain>
  );
};

export default CustomAlert;
