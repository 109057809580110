import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  DailyTaskSetItem,
  DayItem,
  ChecklistTasks,
  DailyReminders,
} from "../index";
import {
  CalendarIcon,
  ChecklistIcon,
  SleepIcon,
  WaterIcon,
  CalendarColorIcon,
  CrossIcon,
} from "../../assets";
import Fasting from "../../assets/fasting.png";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "hooks";
import { motion } from "framer-motion";

const DaysInformation = ({ boostcamp }) => {
  const [currentDay, setCurrentDay] = useState(0);
  const { width, height } = useWindowDimensions();
  const [isOpenDaysMenu, setIsOpenDaysMenu] = useState(false);
  const navigate = useNavigate();

  const openMenuHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsOpenDaysMenu(true);
  };

  const closeMenuHandler = () => {
    setIsOpenDaysMenu(false);
  };

  const checkDayStatus = (item, index, currentDay) => {
    if (index !== currentDay) {
      return "opened";
    } else if (index === currentDay) {
      return "selected";
    }
  };

  useEffect(() => {
    if (isOpenDaysMenu) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isOpenDaysMenu]);

  return (
    <>
      {isOpenDaysMenu && (
        <motion.div
          initial={{ right: "-400px", opacity: 0.5 }}
          animate={{ right: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
          className={styles.daysMenu}
        >
          <div onClick={closeMenuHandler} className={styles.menuWrapper} />
          <div className={styles.menuModal}>
            <div className={styles.modalHeader}>
              <div className={styles.crossBtn} onClick={closeMenuHandler}>
                <CrossIcon />
              </div>
              <p>Boost Camp Calendar</p>
            </div>
            {boostcamp?.days?.map((item, index) => {
              if (!item?.picture) {
                return;
              }
              return (
                <DayItem
                  index={index}
                  idBoostcamp={boostcamp?.id || ""}
                  status={checkDayStatus(item, index, currentDay)}
                  title={item?.title}
                  description={item?.shortDescription}
                  image={item?.picture}
                  key={index}
                  onClick={() => setCurrentDay(index)}
                />
              );
            })}
          </div>
        </motion.div>
      )}
      <div className={styles.wrapper}>
        {width > 1440 && (
          <div className={styles.daysList}>
            {boostcamp?.days?.map((item, index) => {
              if (!item?.picture) {
                return;
              }
              return (
                <DayItem
                  index={index}
                  status={currentDay === index ? "selected" : "open"}
                  title={item?.title}
                  description={item?.shortDescription}
                  image={item?.picture}
                  onClick={() => setCurrentDay(index)}
                  key={index}
                />
              );
            })}
          </div>
        )}
        <div className={styles.boostcampContent}>
          <div className={styles.boostcampHeader}>
            <h2 className={styles.dayTitle}>
              {boostcamp?.days[currentDay]?.title}
            </h2>
            <div className={"html-wrapper"}>
              <div
                className={styles.daySubtitle}
                dangerouslySetInnerHTML={{
                  __html: boostcamp?.days[currentDay]?.shortDescription || "",
                }}
              />
            </div>
            {/*<p className={styles.daySubtitle}>*/}
            {/*  {`${progress?.days[currentDay]?.shortDescription}`}*/}
            {/*</p>*/}
            <img
              src={boostcamp?.days[currentDay]?.picture}
              className={styles.dayPreview}
              alt={"preview"}
            />
          </div>
          <div className={styles.dayOverviewWrapper}>
            <div className={"html-wrapper"}>
              <div
                className={styles.dayOverview}
                dangerouslySetInnerHTML={{
                  __html: boostcamp?.days[currentDay]?.fullDescription || "",
                }}
              />
            </div>
            {/*<p className={styles.dayOverview}>*/}
            {/*  {progress?.days[currentDay]?.fullDescription}*/}
            {/*</p>*/}
          </div>
        </div>
        <div className={styles.tasksList}>
          {width <= 1440 && width > 768 && (
            <div onClick={openMenuHandler} className={styles.openDayBtn}>
              <img
                src={boostcamp?.days[currentDay]?.picture}
                className={styles.dayImg}
              />
              <p className={styles.dayTitle}>
                {boostcamp?.days[currentDay]?.title}
              </p>
              <p className={styles.changeDayBtn}>change day</p>
              <CalendarColorIcon />
            </div>
          )}
          <DailyTaskSetItem
            icon={<ChecklistIcon />}
            title="Current weight"
            color={"#60C05533"}
            btnColor={"#60C055"}
            onClick={() => {}}
            value={null}
            type={"weight"}
            infoPosition={"bottom"}
          />
          <DailyTaskSetItem
            icon={<img src={Fasting} className={styles.fasting} />}
            title="Fasting window"
            color={"#F0512233"}
            btnColor={"#F05122"}
            onClick={() => {}}
            value={null}
            type={"fasting"}
          />
          <DailyTaskSetItem
            icon={<WaterIcon />}
            title="Water intake"
            color={"#79DCD533"}
            btnColor={"#79DCD5"}
            onClick={() => {}}
            value={null}
            type={"water"}
          />
          {/* <DailyTaskSetItem
            icon={<BodyIcon />}
            title="What’s your proportion today?"
            color={"#FCD93033"}
            btnColor={"#FCD930"}
            onClick={() => (isClosed ? null : dispatch(setIsOpenBodyTracker(true)))}
            value={progress?.days[currentDay]?.trackers || null}
            type={"body"}
          /> */}
          <DailyTaskSetItem
            icon={<SleepIcon />}
            title="Sleep tracker"
            color={"#8C42E326"}
            btnColor={"#8C42E3"}
            onClick={() => {}}
            value={boostcamp?.days[currentDay]?.trackers || null}
            type={"sleep"}
          />

          <ChecklistTasks
            tasks={boostcamp?.days[currentDay]?.tasks}
            onSetActiveTask={() => []}
            onCompleteDay={() => {}}
            isLoading={() => {}}
            isOpenCompleteTrackers={false}
          />
          <DailyReminders
            remainders={boostcamp?.days[currentDay]?.remainders}
          />
          {boostcamp?.mealPlan?.length > 0 && (
            <div
              onClick={() => navigate(`/mealplans/${boostcamp?.id}`)}
              className={styles.mealPlanBtn}
            >
              <CalendarIcon />
              <p>Meal Plan</p>
            </div>
          )}
          {/*{boostcamp?.recipes?.full && (*/}
          {/*  <>*/}
          {/*    <Button*/}
          {/*      styleType="filled"*/}
          {/*      size="medium"*/}
          {/*      title="Meal Plan"*/}
          {/*      backgroundColor="#60C055"*/}
          {/*      leftIcon={*/}
          {/*        <CalendarIcon style={{ width: "26px", height: "auto" }} />*/}
          {/*      }*/}
          {/*      onClick={() => {*/}
          {/*        setIsOpenMealPlan(true);*/}
          {/*        setIsOpenReceips(true);*/}
          {/*      }}*/}
          {/*      style={{*/}
          {/*        marginBottom: width <= 768 ? "10px" : "20px",*/}
          {/*        backgroundColor: "#60C055",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <Button*/}
          {/*      styleType="filled"*/}
          {/*      size="medium"*/}
          {/*      title="Receipts"*/}
          {/*      backgroundColor="#F05122"*/}
          {/*      leftIcon={<SaladIcon />}*/}
          {/*      onClick={() => {*/}
          {/*        setIsOpenMealPlan(false);*/}
          {/*        setIsOpenReceips(true);*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </>*/}
          {/*)}*/}
          {/*{boostcamp?.prices[0]?.link && (*/}
          {/*  <>*/}
          {/*    <div className={styles.divider} />*/}
          {/*    <Button*/}
          {/*      styleType="filled"*/}
          {/*      size="medium"*/}
          {/*      title="Our Community"*/}
          {/*      backgroundColor="#4064AC"*/}
          {/*      rightIcon={<FacebookIcon />}*/}
          {/*      onClick={() =>*/}
          {/*        window.open(*/}
          {/*          boostcamp?.prices[0]?.link,*/}
          {/*          "_blank",*/}
          {/*          "noopener,noreferrer"*/}
          {/*        )*/}
          {/*      }*/}
          {/*    />*/}
          {/*  </>*/}
          {/*)}*/}
        </div>
      </div>
    </>
  );
};

export default DaysInformation;
