//react
import React, { useState, useEffect, useCallback } from "react";
import { Spinner } from "react-activity";

//components
import { Button, PageItem, UsersItem } from "components";

//assets
import { PlusSquareIcon, SearchIcon } from "assets";

//navigation
import { useNavigate } from "react-router-dom";

//styles
import styles from "./styles.module.scss";

//utils
import { fetchRequest } from "utils";
import { Manage } from "layouts";
import CreateUser from "./CreateUser";

const limit = 20;

const UsersScreen = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [users, setUsers] = useState([]);
  const [rerender, setRerender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenNewModal, setIsOpenNewModal] = useState(false);

  const onGetUsers = async (page, limit, q = "") => {
    try {
      setIsLoading(true);

      let url = `users?page=${page}&limit=${limit}&sort=-createdAt&testMode=false`;
      if (q) {
        url = url + `&q=${q}`;
      }
      const res = await fetchRequest(url, "GET");
      console.log("res", res?.data?.rows);
      if (res?.success) {
        setPagesCount(Math.ceil(res?.data?.count / limit));
        setUsers(res?.data?.rows);
        setPage(page);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  // useEffect(() => {
  //   onGetUsers();
  // }, [rerender]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetUsers(1, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!search) {
      onGetUsers(page, limit, "");
    }
  }, [search]);

  const navigate = useNavigate();
  const onChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  return (
    <div className={styles.wrapper}>
      <CreateUser
        isOpen={isOpenNewModal}
        setIsOpen={setIsOpenNewModal}
        onGetUsers={onGetUsers}
      />
      <div className={styles.header}>
        <h1 className={styles.title}>Users</h1>
        {/* <div className={styles.createMealBtnWrapper}>
          <Button
            onClick={() => setIsOpenNewModal(true)}
            title={"Create Test User"}
            icon={() => <PlusSquareIcon />}
          />
        </div> */}
      </div>
      <Manage>
        <div className={styles.searchContainer}>
          <div className={styles.searchInputWrapper}>
            <SearchIcon />
            <input
              value={search}
              onChange={onChangeSearch}
              className={styles.searchInput}
              placeholder={"Search..."}
            />
          </div>
        </div>

        <div className={styles.listHeader}>
          <span className={styles.listHeaderName}>Name</span>
          <span className={styles.listHeaderEmail}>Email</span>
          <span className={styles.listHeaderBoost}>Current Boostcamp</span>
          <span className={styles.listHeaderDate}>Register Date</span>
        </div>
        <div className={styles.list}>
          {isLoading ? (
            <div className={styles.loader}>
              <Spinner size={30} color={"#8C42E3"} />
            </div>
          ) : (
            users.map((item, index) => <UsersItem item={item} key={item.id} />)
          )}
        </div>
        <div className={styles.paginationContainer}>
          {Array(pagesCount === 0 ? 1 : pagesCount)
            .fill("_")
            .map((item, index) => (
              <PageItem
                onPress={() => onGetUsers(index + 1, limit, search)}
                key={index}
                page={index + 1}
                isActive={index + 1 === page}
              />
            ))}
          {pagesCount > 1 && page !== pagesCount && (
            <div
              onClick={() => onGetUsers(page + 1, limit, search)}
              className={styles.nextBtn}
            >
              Next
            </div>
          )}
        </div>
      </Manage>
    </div>
  );
};

export default UsersScreen;
