import React, { FC, useState } from "react";

import styles from "./styles.module.scss";
import { MinusIcon, PlusIcon } from "../../assets";
import clsx from "clsx";

const FaqItem = ({ answer, question }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className={clsx(styles.wrapper, { [styles.wrapperActive]: isOpen })}
      >
        <p className={styles.title}>{question}</p>
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      {isOpen && <p className={styles.answer}>{answer}</p>}
    </>
  );
};

export default FaqItem;
