//react
import React, { useState, useRef, useEffect } from "react";

//router
import { useNavigate, useLocation } from "react-router";

//layouts
import { Manage } from "layouts";

//components
import { Header, Chart } from "components";

// styles
import styles from "./styles.module.scss";

//assets
import { IncrementCounter } from "assets";
import { fetchRequest } from "utils";
import { Spinner } from "react-activity";

const Statistics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [statistic, setStatistic] = useState(null);

  const onGetStats = async () => {
    try {
      setIsLoading(true);
      const res = await fetchRequest("statistic", "GET");
      if (res?.status === 200) {
        setStatistic(res?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    onGetStats();
  }, []);

  return (
    <>
      <Header title={"Statistics"} />
      <div className={styles.dividerHeader} />
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <Spinner color={"#8c42e3"} size={25} />
        </div>
      ) : (
        <div className={styles.contentWrapper}>
          <div className={styles.graphicsWrapper}></div>
          <div className={styles.statisticsContainer}>
            <div className={styles.statisticItem}>
              <div>
                <div className={styles.statisticTitle}>Registred Users</div>
                <div className={styles.statisticPrice}>
                  {statistic?.total?.user || 0}
                </div>
                <div className={styles.statisticTodayTitle}>Today</div>
                <div className={styles.flex}>
                  <div className={styles.statisticTodayPrice}>
                    {" "}
                    {statistic?.today?.user || 0}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.statisticItem}>
              <div>
                <div className={styles.statisticTitle}>
                  Total Sales of Boost Camps
                </div>
                <div className={styles.statisticPrice}>
                  ${statistic?.total?.money || 0}{" "}
                </div>
                <div className={styles.statisticTodayTitle}>Today</div>
                <div className={styles.flex}>
                  <div className={styles.statisticTodayPrice}>
                    ${statistic?.today?.money || 0}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.statisticItem}>
              <div>
                <div className={styles.statisticTitle}>Sold Boost Camps</div>
                <div className={styles.statisticPrice}>
                  {statistic?.total?.sold || 0}{" "}
                </div>
                <div className={styles.statisticTodayTitle}>Today</div>
                <div className={styles.flex}>
                  <div className={styles.statisticTodayPrice}>
                    {" "}
                    {statistic?.today?.sold || 0}{" "}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className={styles.statisticItem}>
              <div>
                <div className={styles.statisticTitle}>
                  Total number of reports
                </div>
                <div className={styles.statisticPrice}>
                  {" "}
                  {statistic?.total?.report || 0}{" "}
                </div>
                <div className={styles.statisticTodayTitle}>Today</div>
                <div className={styles.flex}>
                  <div className={styles.statisticTodayPrice}>
                    {" "}
                    {statistic?.today?.report || 0}{" "}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Statistics;
