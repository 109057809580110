import { combineReducers } from "redux";
import { profileReducer } from "./reducers/profileReducer";
import { adminsReducer } from "./reducers/adminReducer";
import { instructorReducer } from "./reducers/instructorReducer";
import { mealReducer } from "./reducers/mealReducer";

const rootReducer = combineReducers({
  profileData: profileReducer,
  adminsData: adminsReducer,
  instructorData: instructorReducer,
  mealData: mealReducer,
});

export default rootReducer;
