import React from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";

const Switch = ({ isChecked = false, setIsChecked }) => {
  return (
    <label
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.switch}
    >
      <input
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(!isChecked);
        }}
        type="checkbox"
      />
      <span className={clsx(styles.slider, styles.round)}></span>
    </label>
  );
};

export default Switch;
