//react
import React, { useState, useEffect, useMemo } from "react";
import { Spinner } from "react-activity";
import moment from "moment";

//components
import {
  Button,
  DiscountPoints,
  Switch,
  UserBoostcamp,
  UserGraph,
} from "components";
import { UserScreenModal } from "./UserScreenModal";

//assets
import { ArrowLeft } from "assets";
import avatar from "../../../assets/avatar.png";

//navigation
import { useNavigate, useParams } from "react-router-dom";

//styles
import styles from "./styles.module.scss";

//utils
import { fetchRequest } from "utils";

const UserScreen = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userProgress, setUserProgress] = useState(null);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [userActiveProgress, setUserActiveProgress] = useState(null);
  const [isLoadingActiveProgress, setIsLoadingActiveProgress] = useState(false);
  const [isVip, setIsVip] = useState(false);
  const [isLoadingVip, setIsLoadingVip] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [totalPoints, setTotalPoints] = useState(0);

  const navigate = useNavigate();
  const params = useParams();

  const onGetUser = async (id) => {
    try {
      setIsLoading(true);
      const res = await fetchRequest(`users/${id}`, "GET");
      setUser(res?.data);
      setIsVip(res?.data?.vip?.status || false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const onGetUserProgress = async (id) => {
    try {
      setIsLoadingProgress(true);
      const res = await fetchRequest(`progress/user/${id}`, "GET");
      setUserProgress(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingProgress(false);
    }
  };
  const onGetUserActiveProgress = async (id) => {
    try {
      setIsLoadingActiveProgress(true);
      const res = await fetchRequest(`progress/user/${id}/active`, "GET");
      setUserActiveProgress(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingActiveProgress(false);
    }
  };

  const onToggleVip = async (userId) => {
    try {
      setIsLoadingVip(true);
      const body = {
        vip: {
          status: !isVip,
        },
      };
      if (user?.vip?.boostCamps?.length > 0) {
        body.vip.boostCamps = user?.vip?.boostCamps;
      }
      const response = await fetchRequest(`users/${userId}/vip`, "PUT", body);
      console.log("response onToggleVip", response);
      if (response?.success) {
        setIsVip((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingVip(false);
    }
  };

  const completedBoostcamps = useMemo(
    () => userProgress?.rows?.filter((b) => b?.isCompleted)?.length || 0,
    [userProgress]
  );

  useEffect(() => {
    if (params?.id) {
      onGetUser(params.id);
      onGetUserProgress(params.id);
      onGetUserActiveProgress(params.id);
    }
  }, [params]);

  useEffect(() => {
    let total = 0;
    if (userActiveProgress?.days && userActiveProgress?.days?.length > 0) {
      userActiveProgress?.days.forEach((day) =>
        day?.tasks?.forEach((el) => (total += +el?.points))
      );
      setTotalPoints(total);
    }
  }, [userActiveProgress]);

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.back} onClick={() => navigate(-1)}>
            <ArrowLeft />
          </div>
          <h1 className={styles.title}>User’s Profile</h1>
        </div>
        <div className={styles.loaderWrapper}>
          <Spinner size={30} color={"#8c42e3"} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <UserScreenModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        user={user}
        isLoadingUser={isLoading}
        userProgress={userProgress}
        isLoadingProgress={isLoadingProgress}
      />
      <div className={styles.header}>
        <div className={styles.back} onClick={() => navigate(-1)}>
          <ArrowLeft />
        </div>
        <h1 className={styles.title}>User’s Profile</h1>
      </div>
      <div className={styles.body}>
        <div className={styles.user}>
          <div className={styles.userContainer}>
            <img
              src={user?.picture || avatar}
              alt="User Avatar"
              className={styles.userAvatar}
            />
            <div className={styles.userInfo}>
              <p className={styles.userName}>
                {user?.firstName} {user?.lastName}
              </p>
              <p className={styles.userEmail}>{user?.email}</p>
              <p className={styles.userDate}>
                {moment(user?.createdAt).format("L")}
              </p>
            </div>
          </div>
          <div className={styles.userStat}>
            <div className={styles.userStatInfo}>
              <span>{completedBoostcamps}</span>
              Completed Boostcamps
            </div>
            {!user?.testMode && (
              <>
                <div className={styles.userStatDivider} />
                <div className={styles.userStatInfo}>
                  <span>${user?.spent || "0"}</span>
                  Total Spent
                </div>
              </>
            )}
            {!user?.testMode && !user?.betaTest && (
              <>
                <div className={styles.userStatDivider} />
                <div className={styles.userStatVip}>
                  <Switch
                    isChecked={isVip}
                    setIsChecked={() => {
                      if (!isLoadingVip) {
                        onToggleVip(user.id);
                      }
                    }}
                  />
                  VIP Mode
                  <Button
                    title="VIP boostcaps"
                    classes={styles.userStatVip__btn}
                    onClick={() => setIsOpenModal(true)}
                    disabled={!isVip}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {isLoadingActiveProgress ? (
          <div className={styles.loaderBoostcampWrapper}>
            <Spinner size={30} color={"#8c42e3"} />
          </div>
        ) : (
          <div className={styles.content}>
            <div className={styles.contentLeft}>
              <DiscountPoints
                maxPoints={totalPoints}
                points={userActiveProgress?.points}
              />
            </div>
            <div className={styles.contentBoost}>
              {userActiveProgress ? (
                <>
                  <div className={styles.contentBoostCurrent}>
                    <UserBoostcamp
                      user={user}
                      userActiveProgress={userActiveProgress}
                      setUserActiveProgress={setUserActiveProgress}
                      isLoading={isLoadingActiveProgress}
                    />
                  </div>
                  <div className={styles.contentBoostDivider} />
                  <div className={styles.contentBoostGraph}>
                    <UserGraph user={user} />
                  </div>
                </>
              ) : (
                <div className={styles.empty}>
                  <span>
                    There is nothing to display here as the user doesn’t have
                    any Boostcamp yet.
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserScreen;
