import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { WaterIcon } from "assets";
import { Button } from "components";
import styles from "./styles.module.scss";
import Slider from "rc-slider";
import "./index.css";
import clsx from "clsx";

const allOptins = [
  { lLabel: "0", lValue: 0, ozLabel: "0", ozValue: 0 },
  { lLabel: "0.5", lValue: 0.5, ozLabel: "17", ozValue: 16 },
  { lLabel: "1", lValue: 1, ozLabel: "34", ozValue: 32 },
  { lLabel: "1.5", lValue: 1.5, ozLabel: "51", ozValue: 48 },
  { lLabel: "2", lValue: 2, ozLabel: "68", ozValue: 64 },
  { lLabel: "2.5", lValue: 2.5, ozLabel: "85", ozValue: 80 },
  { lLabel: "3", lValue: 3, ozLabel: "101", ozValue: 96 },
  { lLabel: ">3", lValue: 4, ozLabel: ">101", ozValue: 100 },
];

const WaterTracker = ({ isOpen, setIsOpen, currentDay, setCurrentDay }) => {
  const [options, setOptions] = useState(allOptins);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeType, setActiveType] = useState(0);
  const [currentValue, setCurrentValue] = useState({ lValue: 0, ozValue: 0 });
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (options[activeIndex]) setCurrentValue(options[activeIndex]);
  }, [activeType, activeIndex, options]);

  const calculateRecommendedWater = () => {
    const weightKg = currentDay?.trackers?.weight?.kg;
    const weightIbs = currentDay?.trackers?.weight?.ibs;

    const recommendedL = weightKg ? Number(weightKg) * 0.035 : 0;
    const recommendedOz = weightKg ? Number(weightIbs) * 0.6 : 0;
    if (activeType === 0) {
      return recommendedL.toFixed(1);
    } else {
      return recommendedOz.toFixed(1);
    }
  };

  const onChangeWater = async () => {
    try {
      setIsLoadingSubmit(true);
      if (currentDay?.trackers) {
        const body = {
          ...currentDay,
          trackers: {
            ...currentDay?.trackers,
            water: {
              l: currentValue?.lValue || 0,
              flOz: currentValue?.ozValue || 0,
            },
          },
        };
        setCurrentDay(body);
        onClose();
      }
      setIsLoadingSubmit(false);
    } catch (error) {
      setIsLoadingSubmit(false);
      console.log(error);
    }
  };
  useEffect(() => {
    if (currentDay) {
      setCurrentValue({
        lValue: currentDay?.trackers?.water?.l || 0,
        ozValue: currentDay?.trackers?.water?.flOz || 0,
      });
      setActiveType(0);

      const index = options.findIndex(
        (item) => item.lValue === currentDay?.trackers?.water?.l
      );
      setActiveIndex(index > 0 ? index : 0);
    }
  }, [currentDay]);

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          borderRadius: "30px",
          padding: "25px",
          outline: "none",
          overflow: "hidden",
        },
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen={isOpen}
    >
      <div className={styles.contentBox}>
        <div className={styles.header}>
          <div className={styles.iconWrapper}>
            <WaterIcon />
          </div>
          <h5>
            How much water did
            <br /> you drink today?
          </h5>
        </div>
        <div className={styles.sliderWrapper}>
          <div className={styles.sliderValueWrapper}>
            <span>
              {options[activeIndex]?.[activeType === 0 ? "lLabel" : "ozLabel"]}
            </span>
            {/* <span>
              {activeType === 0 ? currentValue.lLabel : currentValue.ozLabel}
            </span> */}
            <div className={styles.btnContainer}>
              <div
                onClick={() => setActiveType(0)}
                className={clsx(styles.typeBtn, {
                  [styles.typeBtnActive]: activeType === 0,
                })}
              >
                L
              </div>
              <div
                onClick={() => setActiveType(1)}
                className={clsx(styles.typeBtn, {
                  [styles.typeBtnActive]: activeType === 1,
                })}
              >
                Fl Oz
              </div>
            </div>
          </div>
          <p className={styles.recommended}>
            Recommended amount of water:{" "}
            <b>
              {calculateRecommendedWater() !== `0.0`
                ? `${calculateRecommendedWater()} ${
                    activeType === 0 ? "L" : "Fl Oz"
                  } `
                : "—"}
            </b>
          </p>
          <Slider
            min={0}
            max={7}
            defaultValue={activeIndex}
            onChange={(value) => {
              setActiveIndex(value);
              setCurrentValue(options[value]);
            }}
            trackStyle={{ height: "15px", backgroundColor: "#79DCD5" }}
            railStyle={{ height: "15px", backgroundColor: "#E4E4E4" }}
            handleStyle={{
              height: "26px",
              width: "26px",
              backgroundColor: "#fff",
              borderColor: "#79DCD5",
              borderWidth: "8px",
              opacity: 1,
            }}
          />
          <div className={styles.sliderOptionsWrapper}>
            {options?.map((item, index) => {
              return (
                <span className={styles.sliderOption} key={index}>
                  {activeType === 0 ? item?.lLabel : item?.ozLabel}
                </span>
              );
            })}
          </div>
        </div>

        <Button
          title={"SUBMIT"}
          styleType={"filled"}
          size={"small"}
          backgroundColor={"#79DCD5"}
          loading={isLoadingSubmit}
          onClick={onChangeWater}
        />
      </div>
    </Modal>
  );
};

export default WaterTracker;
