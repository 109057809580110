//react
import React from "react";

//components
import { Input } from "components";

//styles
import styles from "./styles.module.scss";
import { Delete } from "assets";

const ReminderItem = ({ item, setDaysInfo, indexOfDays, index, daysInfo }) => {
  const onChangeTitle = (e) => {
    setDaysInfo((prev) => {
      const copy = [...prev];
      copy[indexOfDays].remainders[index] = e.target.value;
      return copy;
    });
  };

  const onDeleteReminder = () => {
    const copy = [...daysInfo];
    const copyReminders = [...copy[indexOfDays].remainders];
    copyReminders.splice(index, 1);
    copy[indexOfDays].remainders = [...copyReminders];
    setDaysInfo(copy);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleInputWrapper}>
        <Input
          value={item}
          onChange={onChangeTitle}
          placeholder={`Reminder ${index + 1}`}
        />
      </div>
      <div onClick={onDeleteReminder} className={styles.delete}>
        <Delete />
      </div>
    </div>
  );
};

export default ReminderItem;
