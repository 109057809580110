//react
import moment from "moment";
import React, { useRef, useState } from "react";
import { Spinner } from "react-activity";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

//assets
import {
  Filter,
  Delete,
  DotsIcon,
  PencilSmallIcon,
  CopyIcon,
  ImgPlaceholder,
} from "../../assets";

//modals
import { DeleteModal } from "modals";

//hooks
import { useOutsideClick, useToggleModal } from "hooks";

//components
import { Switch } from "components";

//navigation
import { useNavigate } from "react-router-dom";

//styles
import styles from "./styles.module.scss";

//utils
import { fetchRequest } from "utils";

const TutorialItem = ({ item, index }) => {
  const navigate = useNavigate();
  const [isLoadingImg, setIsLoadingImg] = useState(true);

  const navigateToEdit = () => {
    navigate(`/tutorials/edit/${item?._id}`);
  };

  return (
    <>
      <div
        // onClick={() => navigate(`/boostcamps/edit/${item?.id}`)}

        className={styles.wrapper}
      >
        {item?.screenshot ? (
          <>
            {isLoadingImg && (
              <div className={styles.photoLoader}>
                <Spinner color={"#8C42E3"} size={14} />
              </div>
            )}
            <img
              alt="screenshot"
              src={item?.screenshot}
              onLoad={() => setIsLoadingImg(false)}
              className={styles.photo}
            />
          </>
        ) : (
          <div className={styles.photo}>
            <ImgPlaceholder width={30} />
          </div>
        )}
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{item?.title}</p>
          <p className={styles.subtitle}>{item?.description}</p>
        </div>
        <div
          className={styles.filterBtn}
          onClick={(e) => {
            navigateToEdit();
          }}
        >
          {/* <DotsIcon className={styles.menuBtn} /> */}
          <PencilSmallIcon width={19} height={19} />
        </div>
      </div>
    </>
  );
};

export default TutorialItem;
