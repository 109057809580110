import { CrossIcon, DotsIcon } from 'assets';
import { Button, Checkbox, Input, InputSelect } from 'components';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import styles from './styles.module.scss';
import { useOutsideClick, useToggleModal } from 'hooks';
import moment from 'moment';
import { motion } from 'framer-motion';
import { PencilSmallIcon, Delete } from 'assets';
import { fetchRequest } from 'utils';
import { Spinner } from 'react-activity';
import { DeleteModal } from 'modals';
import Modal from 'react-modal';

const limit = 25;

const EditIngredient = ({
  isOpen,
  closeModal,
  onGetIngredients,
  initName,
  initCategory,
  id,
}) => {
  const [name, setName] = useState(initName);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(initCategory);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);

  const onGetCategories = async () => {
    try {
      setIsLoadingCategories(true);
      const res = await fetchRequest('categories', 'GET');
      if (res?.success) {
        setCategories(res?.data);
      }
      setIsLoadingCategories(false);
    } catch (error) {
      setIsLoadingCategories(false);
    }
  };

  const onEditIngredient = async () => {
    try {
      setIsLoadingEdit(true);
      const body = {
        name: name.trim(),
        category: category.id,
      };
      console.log(body);
      const res = await fetchRequest(`ingredients/${id}`, 'PUT', body);
      if (res?.success) {
        onGetIngredients(1, limit, '');
        setName('');
        setCategory('');
        closeModal();
      }
      setIsLoadingEdit(false);
    } catch (error) {
      setIsLoadingEdit(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      onGetCategories();
    }
  }, [isOpen]);

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className={styles.modal}
      onRequestClose={() => closeModal()}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalInner}>
        <p className={styles.title}>Edit Ingredient</p>
        <div onClick={() => closeModal()} className={styles.closeBtn}>
          <CrossIcon />
        </div>
        {isLoadingCategories && (
          <div className={styles.loader}>
            <Spinner color={'#8C42E3'} size={30} />
          </div>
        )}
        {!isLoadingCategories && (
          <>
            <Input
              value={name}
              onChange={onChangeName}
              label={'Ingredient Name'}
              placeholder={'Ingredient Name'}
              classesInner={styles.name}
            />
            <InputSelect
              value={category?.text}
              onChange={setCategory}
              label={'Category'}
              placeholder={'Category'}
              data={categories}
              classesInner={styles.category}
            />
            <Button
              title={'Save'}
              onClick={onEditIngredient}
              disabled={!category?.id || name?.length < 2}
              loading={isLoadingEdit}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

const IngredientItem = ({ item, onGetIngredients }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const deleteModal = useToggleModal();
  const editModal = useToggleModal();

  const ref = useRef(null);

  useOutsideClick(ref, () => setIsOpenMenu(false));

  const onDelete = async () => {
    try {
      setIsLoadingDelete(true);
      const res = await fetchRequest(`ingredients/${item.id}`, 'DELETE');
      if (res?.success) {
        onGetIngredients(1, limit, '');
      }
      setIsLoadingDelete(false);
    } catch (error) {
      setIsLoadingDelete(false);
    }
  };

  return (
    <div ref={ref} className={styles.wrapper}>
      {/* <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} /> */}
      <p className={styles.title}>{item?.name}</p>
      <p className={styles.category}>{item?.category?.text}</p>
      <div className={styles.dateWrapper}>
        <p>{moment(item?.createdAt).format('L')}</p>
        <DotsIcon
          style={{ cursor: 'pointer' }}
          onClick={() => setIsOpenMenu((prev) => !prev)}
        />
      </div>
      {isOpenMenu && (
        <motion.div
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}
          className={styles.menuWrapper}
        >
          <div
            onClick={() => {
              setIsOpenMenu(false);
              editModal.showModal();
            }}
            className={styles.menuButton}
          >
            <PencilSmallIcon width={19} height={19} />
            <span style={{ marginLeft: '7px' }}>Edit</span>
          </div>
          <div
            onClick={() => {
              setIsOpenMenu(false);
              deleteModal.showModal();
            }}
            className={styles.menuButton}
          >
            {isLoadingDelete ? (
              <div className={styles.deleteLoader}>
                <Spinner color={'#EE3758'} size={11} />
              </div>
            ) : (
              <>
                <Delete />
                <span style={{ color: '#EE3758' }}>Delete</span>
              </>
            )}
          </div>
        </motion.div>
      )}
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={'Are you sure you want to delete?'}
          onClick={onDelete}
          loading={isLoadingDelete}
        />
      )}
      {editModal.isShow && (
        <EditIngredient
          isOpen={editModal.isShow}
          closeModal={editModal.closeModal}
          onGetIngredients={onGetIngredients}
          initName={item?.name}
          initCategory={item?.category}
          id={item?.id}
        />
      )}
    </div>
  );
};

export default IngredientItem;
