import { React, useState } from 'react';
import Modal from 'react-modal';
import { useEffect } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import { CrossIcon, IngredientIcon } from 'assets';
import { fetchRequest } from 'utils';
import { Spinner } from 'react-activity';

const RecipePreview = ({
  isOpen,
  setIsOpen,
  preview = null,
  title = null,
  ingredients = [],
  description = null,
  tags = [],
  cookTimeHours,
  cookTimeMinutes,
  date,
  id = null,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const renderName = (item) => {
    if (!!item?.hyperLink) {
      return (
        <span>
          <a
            className={styles.ingredientItemLink}
            href={item?.hyperLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            {item?.ingredient?.name}
          </a>
        </span>
      );
    } else {
      return <span>{item?.ingredient?.name}</span>;
    }
  };

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        className={styles.modal}
        onRequestClose={() => setIsOpen(false)}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalInner}>
          {isLoading ? (
            <div className={styles.loader}>
              <Spinner size={30} color={'#8c42e3'} />
            </div>
          ) : (
            <>
              <div className={styles.header}>
                <img src={preview} className={styles.headerImg} />

                <div
                  onClick={() => setIsOpen(false)}
                  className={styles.closeBtn}
                >
                  <CrossIcon width={30} height={30} style={{ color: '#fff' }} />
                </div>
                <div className={styles.headerInfo}>
                  <p className={styles.title}>{title}</p>
                  <div className={styles.footer}>
                    <div className={styles.footerItem}>
                      <IngredientIcon />
                      <span>{ingredients?.length} ingredients</span>
                    </div>
                    {/* <div className={styles.footerItem}>
                <CalendarIcon />
                <span>{date && format(new Date(date), "yyyy-MM-dd")}</span>
              </div> */}
                  </div>
                </div>
              </div>
              <div className={styles.contentContainer}>
                <p className={styles.sectionTitle}>Ingredients</p>
                {ingredients?.map((item, index) => {
                  return (
                    <div key={index} className={styles.ingredientItem}>
                      <div className={styles.amountWrapper}>
                        {item?.units?.map((el, i) => {
                          return (
                            <p key={i}>
                              {el?.amount} {el?.unit?.text}
                              {item?.units?.length - 1 !== i ? (
                                <span>/</span>
                              ) : (
                                ''
                              )}
                            </p>
                          );
                        })}
                      </div>
                      {renderName(item)}
                    </div>
                  );
                })}
                <p className={styles.sectionTitle}>Preparation</p>
                <div className={'html-wrapper'}>
                  <div
                    className={styles.shortOverview}
                    dangerouslySetInnerHTML={{
                      __html: description || '',
                    }}
                  />
                </div>
                <div className={styles.tagsWrapper}>
                  {tags?.map((item, index) => (
                    <div className={styles.tag} key={item?.id}>
                      {item?.text}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default RecipePreview;
