//react
import React, { useState, useEffect, useRef } from "react";

//components
import EditBoostCampsHeader from "../EditBoostCampsHeader";
import { DayItem } from "../../FillDaysBoostCamps/DayItem";
import { Button } from "components";
import { CustomAlert } from "components";

//navigation
import { useParams, useNavigate } from "react-router-dom";
//styles
import styles from "./styles.module.scss";
import { fetchRequest } from "utils";
import { useToggleModal } from "../../../../../hooks";
import { Spinner } from "react-activity";
import { HouseIcon } from "assets";

const EditBoostCampsDays = ({
  daysInfo,
  setDaysInfo,
  maxDays,
  setMaxDays,
  isLoadingBoostcampDays,
}) => {
  const params = useParams();

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingTest, setIsLoadingTest] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    let total = 0;

    daysInfo.forEach((day) =>
      day?.tasks?.forEach((el) => (total += +el?.points))
    );
    setTotalPoints(total);
  }, [daysInfo]);
  const cancelModal = useToggleModal();

  const navigate = useNavigate();

  const onSaveBoostCamps = async () => {
    try {
      setIsLoadingSave(true);
      const body = {
        days: daysInfo,
      };

      const res = await fetchRequest(`boost-camps/${params?.id}`, "PUT", body);
      setIsLoadingSave(false);
    } catch (error) {
      setIsLoadingSave(false);
      console.log(error);
    }
  };

  const onTestBoostCamps = async () => {
    try {
      setIsLoadingTest(true);
      const body = {
        days: daysInfo,
      };
      const res = await fetchRequest(`boost-camps/${params?.id}`, "PUT", body);
      if (res?.status === 202) {
        navigate(`/preview/${params?.id}`);
      }

      setIsLoadingTest(false);
    } catch (error) {
      setIsLoadingTest(false);
      console.log(error);
    }
  };

  return (
    <>
      {isLoadingBoostcampDays ? (
        <div className={styles.loaderContainer}>
          <Spinner color={"#8C42E3"} size={40} />
        </div>
      ) : (
        <>
          {daysInfo?.map((item, index) => {
            return (
              <DayItem
                key={index}
                item={item}
                daysInfo={daysInfo}
                setDaysInfo={setDaysInfo}
                index={index}
                maxDays={maxDays}
                id={params?.id}
              />
            );
          })}
          <div className={styles.btnsContainer}>
            <div style={{ marginRight: "10px" }}>
              <Button
                onClick={() => onTestBoostCamps()}
                loading={isLoadingTest}
                icon={() => <HouseIcon />}
                title={"Preview"}
                classes={styles.greenBtn}
              />
            </div>
            <div style={{ marginRight: "10px" }}>
              <Button
                title={"Update Boost Camp"}
                onClick={onSaveBoostCamps}
                loading={isLoadingSave}
              />
            </div>
            <div>
              <Button
                title={"Cancel"}
                onClick={cancelModal.showModal}
                emptyStyle
              />
            </div>
          </div>
        </>
      )}
      {cancelModal.isShow && (
        <CustomAlert
          showModal={cancelModal.showModal}
          closeModal={cancelModal.closeModal}
          title={"Are you sure you want to exit?"}
          text={""}
          titleBtn={"Yes"}
          titleSecondBtn={"No"}
          isOpen={cancelModal.isShow}
          onClick={() => navigate("/boostcamps/manage")}
        />
      )}
    </>
  );
};

export default EditBoostCampsDays;
