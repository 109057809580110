import React from "react";
import Button from "../Button";
import TaskItem from "../TaskItem";
import styles from "./styles.module.scss";
import clsx from "clsx";

const ChecklistTasks = ({
  currentDay,
  tasks,
  onSetActiveTask,
  isLoading,
  onCompleteDay,
}) => {
  return (
    <div style={{ position: "relative" }}>
      <div className={clsx(styles.wrapper)}>
        <h3 className={styles.title}>Tasks</h3>
        <div style={{ overflowY: "scroll" }} className={styles.checklist}>
          {tasks?.map((item, index) => (
            <TaskItem
              isChecked={item?.isChecked}
              title={item.task}
              points={item.points}
              key={`checklistTask-${item?.id}-${index}`}
              onPress={() => onSetActiveTask(item?.id, item?.isChecked)}
            />
          ))}
        </div>
        {onCompleteDay && !currentDay?.isCompleted && !currentDay?.isClose && (
          <div className={styles.btn}>
            <Button
              title="Complete Day"
              styleType="filled"
              size="large"
              loading={isLoading}
              backgroundColor={"#8c42e3"}
              onClick={onCompleteDay}
            />
          </div>
        )}
      </div>

      {/*{!isAvailableTrackers && <CloseDayIcon className={styles.blockIcon} />}*/}
    </div>
  );
};

export default ChecklistTasks;
