import axios from 'axios';

const fileDownloader = async (url, fileName) => {
  await axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      return response;
    })
    .catch((e) => e);
};

export default fileDownloader;
