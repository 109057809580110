import React from "react";
import { useNavigate } from "react-router";
//components
import { CustomAlert } from "components";

const DeleteModal = ({
  showModal,
  closeModal,
  isOpen,
  title,
  text,
  onClick,
  loading = false,
}) => {
  return (
    <CustomAlert
      title={title}
      text={text}
      titleBtn="Delete"
      showModal={showModal}
      closeModal={closeModal}
      isOpen={isOpen}
      onClick={onClick}
      loading={loading}
    />
  );
};

export default DeleteModal;
