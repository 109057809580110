import { DotsIcon, FileSearch, PencilSmallIcon, Delete } from 'assets';
import { Checkbox } from 'components';
import React, { useEffect, useState, CopyIcon, useRef } from 'react';
import styles from './styles.module.scss';
import { motion } from 'framer-motion';
import { Spinner } from 'react-activity';
import { useNavigate } from 'react-router-dom';
import { fetchRequest } from 'utils';
import { useOutsideClick, useToggleModal } from 'hooks';
import { DeleteModal } from 'modals';

const ShoppingItem = ({ item, onGetShoppingLists }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [ingredientsCounter, setIngredientsCounter] = useState(0);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const deleteModal = useToggleModal();

  const menuRef = useRef(null);

  useOutsideClick(menuRef, () => setIsOpenMenu(false));

  useEffect(() => {
    let counter = 0;
    item?.categories?.forEach((category) => {
      counter = counter + category?.ingredients?.length;
    });
    setIngredientsCounter(counter);
  }, [item]);

  const navigate = useNavigate();
  const onDelete = async () => {
    try {
      setIsLoadingDelete(true);
      const res = await fetchRequest(`shopping-lists/${item.id}`, 'DELETE');
      if (res?.status === 204) {
        onGetShoppingLists(1, 20, '');
      }
      setIsLoadingDelete(true);
    } catch (error) {
      setIsLoadingDelete(false);
    }
  };
  return (
    <div className={styles.wrapper}>
      {/* <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} /> */}
      <div className={styles.descriptionWrapper}>
        <p className={styles.title}>{item?.title}</p>
        <p className={styles.description}>{item?.description}</p>
      </div>
      <div className={styles.ingredientsContainer}>
        <div className={styles.ingredientsWrapper}>
          <span className={styles.ingredientsWrapperTitle}>
            {ingredientsCounter} ingredients
          </span>
        </div>
        <div className={styles.dotsWrapper} ref={menuRef}>
          <DotsIcon
            onClick={() => setIsOpenMenu((prev) => !prev)}
            className={styles.dotsMenu}
          />

          {isOpenMenu && (
            <motion.div
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{
                type: 'spring',
                stiffness: 260,
                damping: 20,
              }}
              className={styles.menuWrapper}
            >
              <div
                onClick={() =>
                  navigate(`/shopping-list/edit`, {
                    state: {
                      shoppingList: item,
                    },
                  })
                }
                className={styles.menuButton}
              >
                <PencilSmallIcon width={19} height={19} />
                <span style={{ marginLeft: '7px' }}>Edit</span>
              </div>
              <div
                onClick={() => {
                  setIsOpenMenu(false);
                  deleteModal.showModal();
                }}
                className={styles.menuButton}
              >
                {isLoadingDelete ? (
                  <div className={styles.deleteLoader}>
                    <Spinner color={'#EE3758'} size={11} />
                  </div>
                ) : (
                  <>
                    <Delete />
                    <span style={{ color: '#EE3758' }}>Delete</span>
                  </>
                )}
              </div>
            </motion.div>
          )}
          {deleteModal.isShow && (
            <DeleteModal
              showModal={deleteModal.showModal}
              closeModal={deleteModal.closeModal}
              isOpen={deleteModal.isShow}
              title={'Are you sure you want to delete?'}
              onClick={onDelete}
              loading={isLoadingDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShoppingItem;
