import React, { useMemo, useState } from "react";

import { Input } from "components";

import styles from "./styles.module.scss";
import { Delete, Upload } from "assets";
import { uploadFile, deleteFile } from "services/file.service";
import { Spinner } from "react-activity";
import { fileDownloader } from "utils";

const RecipesItem = ({ item, index, setRecipes, id, error }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const onChangeTitle = (e) => {
    setRecipes((prev) => {
      const copy = [...prev];
      copy[index].title = e.target.value;
      return copy;
    });
  };

  const onSelectFile = async (e) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setIsLoading(true);
      const res = await uploadFile(e.target.files[0]);
      setRecipes((prev) => {
        const copy = [...prev];
        copy[index].fileUrl = res?.data?.url;
        return copy;
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onDeleteFile = async (e) => {
    try {
      const res = await deleteFile(item?.fileUrl);

      if (res?.status === 204) {
        setRecipes((prev) => {
          const copy = [...prev];
          copy[index].fileUrl = "";
          return copy;
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  function getStringAfterFirstAppearance(str, character) {
    var index = str.indexOf(character);
    if (index !== -1) {
      return str.substring(index + 1);
    }
    return str;
  }

  const itemUrl = useMemo(() => {
    const fileName = decodeURI(
      item?.fileUrl?.replace(/%20/g, " ")?.replace("+", " ") || ""
    );
    const firstRegEx = /[^/]*$/.exec(fileName);

    if (firstRegEx?.length > 0) {
      const afterSymbol = getStringAfterFirstAppearance(firstRegEx[0], "-");
      return afterSymbol;
    } else return fileName;
  }, [item, item?.fileUrl]);

  const onDownloadMealPlan = async () => {
    try {
      if (item && itemUrl) {
        const fileName = itemUrl.endsWith(".pdf")
          ? itemUrl.substring(0, itemUrl.length - 4)
          : itemUrl;
        setDownloadLoading(true);
        await fileDownloader(item?.fileUrl, `${fileName}`);
        setDownloadLoading(false);
      }
    } catch (error) {
      setDownloadLoading(false);
    }
  };

  return (
    <>
      <div
        style={{ marginBottom: item?.fileUrl ? "10px" : "0" }}
        className={styles.wrapper}
      >
        <div className={styles.inputWrapper}>
          <Input
            value={item?.fileUrl ? itemUrl : item?.title}
            onChange={onChangeTitle}
            placeholder={"Test"}
            disabled={true}
            errorBorder={error}
          />
        </div>

        {item?.fileUrl ? (
          <div onClick={onDeleteFile} className={styles.deleteBtn}>
            <Delete />
          </div>
        ) : (
          <div className={styles.deleteBtn} />
        )}
      </div>

      <div className={styles.buttonsWrapper}>
        <label className={styles.faqAddQuestionBtn} htmlFor={`upload ${id}`}>
          {isLoading ? (
            <>
              <Upload />
              <span style={{ marginLeft: "20px" }}>
                <Spinner color={"#8c42e3"} size={12} />
              </span>
            </>
          ) : (
            <>
              <Upload />

              <span className={styles.faqAddQuestionBtnTitle}>
                {item?.fileUrl ? "Uploaded" : "Upload PDF"}
              </span>
            </>
          )}
          <input
            id={`upload ${id}`}
            type={"file"}
            accept="application/pdf"
            onClick={(e) => (e.target.value = null)}
            onChange={onSelectFile}
            style={{ display: "none" }}
            disabled={item?.fileUrl}
          />
        </label>
        {item?.fileUrl && (
          <a onClick={onDownloadMealPlan} className={styles.faqAddQuestionBtn}>
            {downloadLoading ? (
              <Spinner color={"#8c42e3"} size={12} />
            ) : (
              <Upload />
            )}

            <span className={styles.faqAddQuestionBtnTitle}>Download PDF</span>
          </a>
        )}
      </div>
    </>
  );
};

export default RecipesItem;
