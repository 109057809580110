import React, { FC, ReactNode, useState, useEffect, useRef } from "react";
import { InfoIcon, LockIcon, PencilSmallIcon, PenIcon } from "../../assets";

import { Button } from "../index";
import styles from "./styles.module.scss";
import { motion } from "framer-motion";
import clsx from "clsx";

const DailyTaskSetItem = ({
  icon,
  title,
  color,
  btnColor,
  onClick = () => {},
  value = null,
  type,
  infoPosition = "top",
  isClosed = false,
}) => {
  const [formattedValue, setFormattedValue] = useState("");
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [infoText, setInfoText] = useState("");

  const infoRef = useRef(null);

  const formatValue = (value) => {
    if (type === "sleep") {
      setInfoText("How long did you sleep last night?");
    }
    if (type === "water") {
      setInfoText("How much water have you had today?");
    }
    if (type === "fasting") {
      setInfoText(
        "How long has it been since your last meal or caloric beverage?"
      );
    }
    if (type === "weight") {
      setInfoText("How much do you weigh?");
    }
    if (value === null) {
      return;
    }

    if (type === "sleep") {
      setFormattedValue(
        value?.sleep === 2
          ? "≤2 h"
          : value?.sleep === 9
          ? "≥9 h"
          : `${value?.sleep || ""}${value?.sleep ? " h" : ""}`
      );
    }
    if (type === "water") {
      setFormattedValue(
        value?.water?.l === 4
          ? `>3 l`
          : value?.water?.l === 0
          ? ""
          : `${value?.water?.l} l`
      );
      setInfoText("How much water have you had today?");
    }
    if (type === "fasting") {
      setFormattedValue(value?.eat > 0 ? `${value?.eat} h` : "");
      setInfoText(
        "How long has it been since your last meal or caloric beverage?"
      );
    }
    if (type === "weight") {
      setFormattedValue(value?.weight?.kg > 0 ? `${value?.weight.kg} kg` : "");
      setInfoText("How much do you weigh?");
    }
    if (type === "body") {
      setFormattedValue(
        value?.proportions.bust.cm > 0 &&
          value.proportions.waist.cm > 0 &&
          value.proportions.hips.cm > 0
          ? `${value.proportions.bust.cm}/${value.proportions.waist.cm}/${value.proportions.hips.cm}`
          : ""
      );
    }
  };

  useEffect(() => {
    formatValue(value);
  }, [value]);

  return (
    <div className={styles.wrapper}>
      <div style={{ background: color }} className={styles.iconWrapper}>
        <div
          onMouseOver={() => setIsOpenInfo(true)}
          onMouseLeave={() => setIsOpenInfo(false)}
          ref={infoRef}
          className={styles.infoBtn}
        >
          {isOpenInfo && (
            <motion.div
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
              className={clsx(styles.infoBlock, {})}
            >
              {infoText}
            </motion.div>
          )}
          <InfoIcon style={{ color: btnColor }} />
        </div>
        {icon}
      </div>
      <p>{title}</p>
      <div className={styles.btnWrapper}>
        {formattedValue || isClosed ? (
          <div style={{ cursor: "pointer" }} onClick={onClick}>
            <div className={styles.editIcon}>
              <div
                style={{ borderColor: btnColor }}
                className={styles.editIconWrapper}
              >
                <PencilSmallIcon color={btnColor} />
              </div>
            </div>
            <button
              style={{ backgroundColor: btnColor }}
              className={styles.btnValue}
            >
              {formattedValue || "0"}
            </button>
          </div>
        ) : (
          <div style={{ position: "relative" }} onClick={onClick}>
            {/* <div
              style={{ backgroundColor: color, borderColor: btnColor }}
              className={styles.lockedWrapper}
            />
            <div className={styles.lockedIconWrapper}>
              <LockIcon style={{ color: btnColor }} />
            </div> */}
            <button
              style={{
                borderColor: btnColor,
                color: btnColor,
                backgroundColor: "transparent",
                borderStyle: "dashed",
              }}
              className={styles.btnValue}
            >
              Set
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DailyTaskSetItem;
