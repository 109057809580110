import {
  Delete,
  PlusIcon,
  DeleteSmall,
  ImgPlaceholder,
  ArrowLeft,
} from "assets";
import {
  Input,
  InputSelect,
  TextAreaEditor,
  Button,
  SearchIngredients,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-activity";
import { fetchRequest } from "utils";
import styles from "./styles.module.scss";
import TopModal from "modals/TopModal";
import { useToggleModal } from "hooks";
import { useNavigate, useParams } from "react-router-dom";

const EditTooltip = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [screenshot, setScreenshot] = useState("");
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const savedModal = useToggleModal();
  const updatedShown = () => {
    savedModal.showModal();

    setTimeout(() => {
      savedModal.closeModal();
    }, 3000);
  };

  const onChangeName = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const onChangeShortDescription = useCallback((e) => {
    setDescription(e.target.value);
  }, []);

  const onGetTooltip = async () => {
    try {
      setIsLoading(true);
      const res = await fetchRequest(`tooltips/${params?.id}`, "GET");
      if (res.success) {
        console.log(res.data);
        setTitle(res?.data?.title || "");
        setDescription(res?.data?.description || "");
        setScreenshot(res?.data?.screenshot || "");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetTooltip();
  }, []);

  const saveToolip = async () => {
    try {
      // setTitleError(!title);
      // setDescriptionError(!description);
      // if (!title || !description) {
      //   return;
      // }
      setIsSaveLoading(true);
      const res = await fetchRequest(`tooltips/${params?.id}`, "PUT", {
        title: title,
        description: description,
      });
      if (res?.success) {
        updatedShown();
      } else {
        alert(res?.message || "Something went wrong");
      }
    } catch (error) {
    } finally {
      setIsSaveLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.back} onClick={() => navigate(-1)}>
            <ArrowLeft />
          </div>
          <h1 className={styles.title}>Edit Tooltip</h1>
        </div>
        <div className={styles.loaderWrapper}>
          <Spinner size={40} color={"#8C42E3"} />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.back} onClick={() => navigate(-1)}>
            <ArrowLeft />
          </div>
          <h1 className={styles.title}>Edit Tooltip</h1>
        </div>
        <div className={styles.imageWrapper}>
          {screenshot ? (
            <img
              src={screenshot}
              alt="Tooltip Photo"
              className={styles.image}
            />
          ) : (
            <div className={styles.image}>
              <ImgPlaceholder />
            </div>
          )}
        </div>
        <div className={styles.headerInputWrapper}>
          <Input
            value={title}
            onChange={onChangeName}
            label={"Tooltip Title"}
            placeholder={"Title"}
            classes={styles.input}
            errorBorder={titleError}
          />
          <TextAreaEditor
            value={description}
            onChange={onChangeShortDescription}
            label={"Tooltip Description"}
            placeholder={"Description"}
            classes={styles.description}
            borderError={descriptionError}
          />
        </div>
        <div className={styles.footer}>
          <Button title={"Cancel"} classes={styles.cancelBtn} />
          <Button
            onClick={saveToolip}
            loading={isSaveLoading}
            title={"Save"}
            classes={styles.saveBtn}
          />
        </div>
      </div>
      {savedModal.isShow && <TopModal title="Sucessfully saved" />}
    </>
  );
};

export default EditTooltip;
