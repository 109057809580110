import { Arrow, ArrowDown, Delete, PlusIcon } from "assets";
import {
  AddRecipeModal,
  Button,
  DayOfWeekItem,
  Input,
  InputSelect,
  RecipeItem,
  RichTextEditor,
  TextAreaEditor,
} from "components";
import React, { useEffect, useState, useRef } from "react";
import { useCallback } from "react";
import styles from "./styles.module.scss";
import { Switch } from "components";
import { fetchRequest } from "utils";
import TopModal from "modals/TopModal";
import { useNavigate, useParams } from "react-router-dom";
import { useToggleModal } from "hooks";
import clsx from "clsx";
import { useOutsideClick } from "hooks";
import { Spinner } from "react-activity";
import { DeleteModal } from "modals";
import { useMemo } from "react";

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const DEFAULT_WEEK_MEAL = [
  {
    order: 0,
    title: "30 mins before every meal",
    recipes: [],
    info: "",
  },
  {
    order: 1,
    title: "BREAK your FAST",
    recipes: [],
    info: "",
  },
  {
    order: 2,
    title: "LUNCH",
    recipes: [],
    info: "",
  },
  {
    order: 3,
    title: "DINNER",
    recipes: [],
    info: "",
  },
  {
    order: 4,
    title: "MEAL ADD ONS Up to 2 PER DAY",
    recipes: [],
    info: "",
  },
];

const DEFAULT_BEV_MEAL = {
  title: "BEVERAGES",
  enabled: false,
  desc: "",
};
const DEFAULT_ADD_ON_MEAL = {
  title: "MEAL ADD ONS",
  enabled: false,
  desc: "",
};
const DEFAULT_ADD_ON_MEAL2 = {
  title: "MEAL ADD ONS",
  enabled: false,
  desc: "",
};

const DEFAULT_PREP_LIST = {
  type: "first",
  title: "Week 1 + 3 PREP LIST",
  description: "",
};
const DEFAULT_PREP_LIST_2 = {
  type: "second",
  title: "Week 2 + 4 PREP LIST",
  description: "",
};

const ShoppingListSelector = ({ value, setValue }) => {
  const [isOpenList, setIsOpenList] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [shopingLists, setShopingLists] = useState([]);
  const ref = useRef(null);
  const limit = 10;
  useOutsideClick(ref, () => setIsOpenList(false));

  const onGetLists = async (page, limit, q = "") => {
    try {
      page === 1 && setIsLoading(true);
      page > 1 && setIsLoadingPagination(true);
      let url = `shopping-lists?page=${page}&limit=${limit}`;
      if (q) {
        url = url + `&q=${q}`;
      }
      const res = await fetchRequest(url, "GET");
      if (res?.success) {
        setPagesCount(Math.ceil(res?.data?.count / limit));
        if (page === 1) {
          setShopingLists(res?.data?.rows);
        } else {
          setShopingLists((prev) => [...prev, ...res?.data?.rows]);
        }

        setPage(page);
      }
      page === 1 && setIsLoading(false);
      page > 1 && setIsLoadingPagination(false);
    } catch (error) {
      setIsLoading(false);
      setIsLoadingPagination(false);
    }
  };

  useEffect(() => {
    onGetLists(page, limit);
  }, []);

  const handleScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1;
    if (bottom && !isLoadingPagination && pagesCount !== page) {
      onGetLists(page + 1, limit);
      // incrementPage();
    }
  };

  return (
    <div ref={ref} className={clsx(styles.input)}>
      <h4>Shopping List</h4>
      <div
        onClick={() => setIsOpenList((prev) => !prev)}
        className={clsx(styles.input__main, {})}
      >
        <span
          style={{ color: value?.title ? "#000" : "#afafaf" }}
          className={styles.value}
        >
          {value?.title ? value?.title : "Shopping List"}
        </span>
        <Arrow
          className={clsx(styles.arrow, { [styles.openArrow]: isOpenList })}
        />
      </div>
      {isOpenList && (
        <div onScroll={handleScroll} className={styles.dataList}>
          {isLoading && (
            <div className={styles.listLoading}>
              <Spinner size={20} color={"#8C42E3"} />
            </div>
          )}
          {!isLoading &&
            shopingLists?.map((item) => {
              return (
                <div
                  onClick={() => {
                    setValue(item);
                    setIsOpenList(false);
                  }}
                  key={item?.id}
                  className={styles.dataItem}
                >
                  {item?.title}
                </div>
              );
            })}
          {isLoadingPagination && (
            <div className={styles.listLoadingPagination}>
              <Spinner size={10} color={"#8C42E3"} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const EditMealPlan = () => {
  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [shopingList, setShopingList] = useState();
  const [isOpenAddRecipeModal, setIsOpenAddRecipeModal] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedMeal, setSelectedMeal] = useState(0);
  const [isAdd30mins, setIsAdd30mins] = useState(true);
  const [isMealPerDay, setIsMealPerDay] = useState(true);
  const [isLoadingCreateMealPlan, setIsLoadingCreateMealPlan] = useState(false);
  const [id, setId] = useState("");
  const [isLoadingGetMealPlan, setIsLoadingGetMealPlan] = useState(false);
  const [isLoadingGetMealPlanDay, setIsLoadingGetMealPlanDay] = useState(false);
  const [week, setWeek] = useState([
    {
      order: 0,
      title: "Monday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 1,
      title: "Tuesday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 2,
      title: "Wednesday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 3,
      title: "Thursday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 4,
      title: "Friday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 5,
      title: "Saturday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 6,
      title: "Sunday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
  ]);
  const [prepList, setPrepList] = useState(DEFAULT_PREP_LIST);
  // const [prepListSecond, setPrepListSecond] = useState(DEFAULT_PREP_LIST_2);

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const savedModal = useToggleModal();
  const updatedShown = () => {
    savedModal.showModal();

    setTimeout(() => {
      savedModal.closeModal();
    }, 3000);
  };
  const navigation = useNavigate();

  const onChangeShortDescription = useCallback((e) => {
    setShortDescription(e.target.value);
  });

  const onSameForWholeWeek = (selectedDay, mealIndex) => {
    const weekDay = week?.find((day) => day?.order === selectedDay);
    const meal = { ...weekDay?.meal[mealIndex] };
    const filledWeek = week.map((item, index) => {
      const copy = { ...item };
      copy.meal[mealIndex] = { ...meal };
      return copy;
    });
    setWeek(filledWeek);
  };
  const onSameForWholeWeekBeveragesAndAddOns = (selectedDay, type) => {
    const weekDay = week?.find((day) => day?.order === selectedDay);
    const copyType = weekDay[type];
    const filledWeek = week.map((item, index) => {
      return {
        ...item,
        [type]: copyType,
      };
    });
    setWeek(filledWeek);
  };
  const onChangeInfo = useCallback((e, index, selectedDay) => {
    setWeek((prev) => {
      const copy = [...prev];
      copy[selectedDay].meal[index].info = e.target.value;
      return copy;
    });
  }, []);
  const onChangeMealName = useCallback((e, index, selectedDay) => {
    setWeek((prev) => {
      const copy = [...prev];
      copy[selectedDay].meal[index].title = e.target.value;
      return copy;
    });
  }, []);

  const onChangeBeveragesAndAddOnsText = useCallback((e, selectedDay, type) => {
    setWeek((prev) => {
      return prev.map((el) =>
        el.order === selectedDay
          ? {
              ...el,
              [type]: { ...el[type], desc: e },
            }
          : el
      );
    });
  }, []);
  const onChangeBeveragesAndAddOnsTitle = useCallback(
    (e, selectedDay, type) => {
      setWeek((prev) => {
        return prev.map((el) =>
          el.order === selectedDay
            ? {
                ...el,
                [type]: { ...el[type], title: e.target.value },
              }
            : el
        );
      });
    },
    []
  );
  const onChangeBeveragesAndAddOnsEnabled = useCallback((selectedDay, type) => {
    setWeek((prev) => {
      return prev.map((el) =>
        el.order === selectedDay
          ? {
              ...el,
              [type]: { ...el[type], enabled: !el[type].enabled },
            }
          : el
      );
    });
  }, []);

  const onChagePrepListTitle = useCallback((e) => {
    setPrepList((prev) => ({ ...prev, title: e.target.value }));
  }, []);
  const onChagePrepListText = useCallback((e) => {
    setPrepList((prev) => ({ ...prev, description: e }));
  }, []);
  // const onChagePrepListSecondTitle = useCallback((e) => {
  //   setPrepListSecond((prev) => ({ ...prev, title: e.target.value }));
  // }, []);
  // const onChagePrepListSecondText = useCallback((e) => {
  //   setPrepListSecond((prev) => ({ ...prev, description: e }));
  // }, []);

  const params = useParams();

  const onGetMealPlan = async () => {
    try {
      setIsLoadingGetMealPlan(true);
      const res = await fetchRequest(`meal-plans/${params?.id}/all`, "GET");
      if (res?.success) {
        setName(res?.data?.title);
        setShortDescription(res?.data?.description);

        const mapedWeek = res?.data?.week?.map((item, index) => ({
          ...item,
          beverages: item?.beverages || DEFAULT_BEV_MEAL,
          addOn: item?.addOn || DEFAULT_ADD_ON_MEAL,
          addOnSecond: item?.addOnSecond || DEFAULT_ADD_ON_MEAL2,
        }));
        setWeek(mapedWeek);
        if (res?.data?.week[0]?.meal?.length === 5) {
          setIsAdd30mins(true);
          setIsMealPerDay(true);
        } else {
          res?.data?.week[0]?.meal.some(
            (meal) => meal?.title === "30 mins before every meal"
          )
            ? setIsAdd30mins(true)
            : setIsAdd30mins(false);
          res?.data?.week[0]?.meal.some(
            (meal) => meal?.title === "MEAL ADD ONS Up to 2 PER DAY"
          )
            ? setIsMealPerDay(true)
            : setIsMealPerDay(false);
        }
        const findedPrepList = res?.data?.prepLists?.find(
          (pList) => pList?.type === "first"
        );
        setPrepList(findedPrepList || DEFAULT_PREP_LIST);
        // const findedPrepListSecond = res?.data?.prepLists?.find(
        //   (pList) => pList?.type === "second"
        // );
        // setPrepListSecond(findedPrepListSecond || DEFAULT_PREP_LIST_2);

        if (res?.data?.shoppingList) {
          const res_sl = await fetchRequest(
            `shopping-lists/${res?.data?.shoppingList}`,
            "GET"
          );
          console.log("res_sl", res_sl);
          setShopingList({
            title: res_sl?.data?.title,
            id: res_sl?.data?.id || res?.data?.shoppingList,
          });
        } else {
          setShopingList({ title: "", id: res?.data?.shoppingList });
        }
      }
      setIsLoadingGetMealPlan(false);
    } catch (e) {
      setIsLoadingGetMealPlan(false);
    }
  };

  useEffect(() => {
    onGetMealPlan();
  }, []);

  const onCreateMealPlans = async () => {
    try {
      if (!name) {
        setTitleError(true);
        return;
      } else {
        setTitleError(false);
      }
      if (!shortDescription) {
        setDescriptionError(true);
        return;
      } else {
        setDescriptionError(false);
      }
      setIsLoadingCreateMealPlan(true);

      console.log("week", week);

      const body = {
        title: name,
        description: shortDescription,
        shoppingList: shopingList?.id,
        week: week?.map((item, index) => ({
          ...item,
          meal: item?.meal?.map((el) => ({
            order: isAdd30mins ? el?.order : el?.order - 1,
            recipes: el?.recipes?.map((r) => r?.id),
            title: el?.title,
          })),
          beverages: item?.beverages || DEFAULT_BEV_MEAL,
          addOn: item?.addOn || DEFAULT_ADD_ON_MEAL,
          addOnSecond: item?.addOnSecond || DEFAULT_ADD_ON_MEAL2,
        })),
        prepLists: [prepList],
      };

      let url = params?.id ? `meal-plans/${params?.id}` : "meal-plans";
      let method = params?.id ? "PUT" : "POST";
      const res = await fetchRequest(url, method, body);

      if (res?.success) {
        setId(res?.data?.id);
        updatedShown();
        // navigation("/mealplans/manage");
      } else {
        alert(res?.message || "Something went wrong");
      }
      setIsLoadingCreateMealPlan(false);
    } catch (e) {
      console.log("e", e);
      setIsLoadingCreateMealPlan(false);
    }
  };

  const deleteModal = useToggleModal();
  const [deleteItemIndex, setDeleteItemIndex] = useState(null);
  const onDeleteMeal = useCallback(() => {
    if (deleteItemIndex == null) return;
    const filterWeek = week?.map((w, index) => ({
      ...w,
      meal:
        index == selectedDay
          ? w.meal.filter((m, meal_index) => meal_index != deleteItemIndex)
          : w.meal,
    }));
    setWeek(filterWeek);
    deleteModal.closeModal();
    setDeleteItemIndex(null);
    // console.log({ week, filterWeek });
  }, [deleteItemIndex, week, selectedDay]);

  const selectedWeekDay = useMemo(
    () => week?.find((day) => day?.order === selectedDay),
    [week, selectedDay]
  );

  return (
    <>
      <AddRecipeModal
        isOpen={isOpenAddRecipeModal}
        setIsOpen={setIsOpenAddRecipeModal}
        selectedDay={selectedDay}
        selectedMeal={selectedMeal}
        setWeek={setWeek}
        week={week}
      />
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Edit Meal Plan</h1>
        {isLoadingGetMealPlan ? (
          <div className={styles.loaderContainer}>
            <Spinner color={"#8C42E3"} size={40} />
          </div>
        ) : (
          <>
            <div className={styles.headerInputWrapper}>
              <Input
                value={name}
                onChange={onChangeName}
                label={"Meal Plan Name"}
                placeholder={"Meal Plan Name"}
                errorBorder={titleError}
              />
              <div style={{ height: 20 }} />
              <RichTextEditor
                label={"Short Description"}
                initialValue={shortDescription}
                setValue={setShortDescription}
              />
              <div style={{ height: 40 }} />
              {/* <TextAreaEditor
            value={shortDescription}
            onChange={onChangeShortDescription}
            label={"Short Description"}
            placeholder={"Short Description"}
            classes={styles.textarea}
            borderError={descriptionError}
          /> */}
              <ShoppingListSelector
                value={shopingList}
                setValue={setShopingList}
              />
            </div>
            <div className={styles.daysContainer}>
              {week.map((item, index) => (
                <DayOfWeekItem
                  key={item?.order || index}
                  type={
                    (item?.order || index) === selectedDay
                      ? "active"
                      : "default"
                  }
                  onClick={() => setSelectedDay(item?.order || index)}
                  title={item?.title}
                />
              ))}
            </div>
            {isLoadingGetMealPlanDay ? (
              <>
                <div className={styles.loaderContainer}>
                  <Spinner color={"#8C42E3"} size={40} />
                </div>
              </>
            ) : (
              <>
                <div className={styles.addMealBtnWrapper}>
                  <div
                    onClick={() => {
                      const copy = [...week];
                      const array = copy.map((item, index) => ({
                        ...item,
                        meal: [
                          {
                            order: 0,
                            title: "Meal Title",
                            recipes: [],
                            info: "",
                          },
                          ...item?.meal,
                        ],
                      }));
                      setWeek(array);
                    }}
                    className={styles.addMealBtn}
                  >
                    <PlusIcon />
                    <span>Add Meal</span>
                  </div>
                </div>
                {selectedWeekDay?.meal?.map((item, index) => {
                  return (
                    <div key={index} className={styles.launchItem}>
                      <div className={styles.header}>
                        <Input
                          value={item?.title}
                          onChange={(e) =>
                            onChangeMealName(e, index, selectedDay)
                          }
                          placeholder={"Meal Name"}
                          errorBorder={""}
                          classesInner={styles.headerTitleInput}
                        />
                        <div
                          onClick={() => {
                            setDeleteItemIndex(index);
                            deleteModal.showModal();
                          }}
                          className={styles.mealDeleteBtn}
                        >
                          <Delete />
                          <span style={{ color: "#EE3758" }}>Delete</span>
                        </div>
                        {/* <span className={styles.headerTitle}>{item?.title}</span> */}
                        <div
                          onClick={() => onSameForWholeWeek(selectedDay, index)}
                          className={styles.sameForWeekBtn}
                        >
                          <span>Same for whole week</span>
                          <ArrowDown className={styles.arrow} />
                        </div>
                      </div>
                      <div className={styles.recipesList}>
                        {item?.recipes?.map((item, i) => {
                          return (
                            <RecipeItem
                              withDelete={true}
                              deleteHandler={() => {
                                setWeek((prev) => {
                                  const copy = [...prev];
                                  copy[selectedDay].meal[index].recipes = copy[
                                    selectedDay
                                  ].meal[index].recipes.filter(
                                    (e, index1) => index1 !== i
                                  );
                                  return copy;
                                });
                              }}
                              item={item}
                              key={`${item?.id}-${i}`}
                            />
                          );
                        })}
                      </div>
                      <div className={styles.contentContainer}>
                        <div className={styles.addBtnWrapper}>
                          <div
                            onClick={() => {
                              setSelectedMeal(index);
                              setIsOpenAddRecipeModal(true);
                            }}
                            className={styles.addRecipesBtn}
                          >
                            <PlusIcon />
                            <span>Add Recipes</span>
                          </div>
                        </div>

                        {/* <div className={styles.additionalText}>
      <Input
        key={`${selectedDay}_${index}`}
        value={item?.info}
        onChange={(e) => onChangeInfo(e, index, selectedDay)}
        placeholder="Additional text info"
      />
    </div> */}
                      </div>
                    </div>
                  );
                })}
                <div className={styles.addMealBtnWrapper}>
                  <div
                    onClick={() => {
                      const copy = [...week];
                      const array = copy.map((item, index) => ({
                        ...item,
                        meal: [
                          ...item?.meal,
                          {
                            order: item?.meal?.length,
                            title: "Meal Title",
                            recipes: [],
                            info: "",
                          },
                        ],
                      }));
                      setWeek(array);
                    }}
                    className={styles.addMealBtn}
                  >
                    <PlusIcon />
                    <span>Add Meal</span>
                  </div>
                </div>

                <div className={styles.selectContainer}>
                  <Input
                    value={selectedWeekDay?.beverages?.title}
                    onChange={(e) =>
                      onChangeBeveragesAndAddOnsTitle(
                        e,
                        selectedDay,
                        "beverages"
                      )
                    }
                    placeholder={"BEVERAGES"}
                    errorBorder={""}
                    classesInner={styles.selectInput}
                  />
                  <Switch
                    isChecked={selectedWeekDay?.beverages?.enabled}
                    setIsChecked={() => {
                      onChangeBeveragesAndAddOnsEnabled(
                        selectedDay,
                        "beverages"
                      );
                    }}
                  />
                  <div
                    onClick={() =>
                      onSameForWholeWeekBeveragesAndAddOns(
                        selectedDay,
                        "beverages"
                      )
                    }
                    className={styles.sameForWeekBtn}
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <span>Same for whole week</span>
                    <ArrowDown className={styles.arrow} />
                  </div>
                </div>
                {selectedWeekDay?.beverages?.enabled && (
                  <div className={styles.mealRichTextWrapper}>
                    <RichTextEditor
                      key={`richText-beverages-${selectedDay}`}
                      initialValue={selectedWeekDay?.beverages?.desc}
                      setValue={(e) =>
                        onChangeBeveragesAndAddOnsText(
                          e,
                          selectedDay,
                          "beverages"
                        )
                      }
                    />
                  </div>
                )}
                <div className={styles.selectContainer}>
                  <Input
                    value={selectedWeekDay?.addOn?.title}
                    onChange={(e) =>
                      onChangeBeveragesAndAddOnsTitle(e, selectedDay, "addOn")
                    }
                    placeholder={"MEAL ADD ONS"}
                    errorBorder={""}
                    classesInner={styles.selectInput}
                  />
                  <Switch
                    isChecked={selectedWeekDay?.addOn?.enabled}
                    setIsChecked={() => {
                      onChangeBeveragesAndAddOnsEnabled(selectedDay, "addOn");
                    }}
                  />
                  <div
                    onClick={() =>
                      onSameForWholeWeekBeveragesAndAddOns(selectedDay, "addOn")
                    }
                    className={styles.sameForWeekBtn}
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <span>Same for whole week</span>
                    <ArrowDown className={styles.arrow} />
                  </div>
                </div>
                {selectedWeekDay?.addOn?.enabled && (
                  <div className={styles.mealRichTextWrapper}>
                    <RichTextEditor
                      key={`richText-addOn-${selectedDay}`}
                      initialValue={selectedWeekDay?.addOn?.desc}
                      setValue={(e) =>
                        onChangeBeveragesAndAddOnsText(e, selectedDay, "addOn")
                      }
                    />
                  </div>
                )}
                <div className={styles.selectContainer}>
                  <Input
                    value={selectedWeekDay?.addOnSecond?.title}
                    onChange={(e) =>
                      onChangeBeveragesAndAddOnsTitle(
                        e,
                        selectedDay,
                        "addOnSecond"
                      )
                    }
                    placeholder={"MEAL ADD ONS"}
                    errorBorder={""}
                    classesInner={styles.selectInput}
                  />
                  <Switch
                    isChecked={selectedWeekDay?.addOnSecond?.enabled}
                    setIsChecked={() => {
                      onChangeBeveragesAndAddOnsEnabled(
                        selectedDay,
                        "addOnSecond"
                      );
                    }}
                  />
                  <div
                    onClick={() =>
                      onSameForWholeWeekBeveragesAndAddOns(
                        selectedDay,
                        "addOnSecond"
                      )
                    }
                    className={styles.sameForWeekBtn}
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <span>Same for whole week</span>
                    <ArrowDown className={styles.arrow} />
                  </div>
                </div>
                {selectedWeekDay?.addOnSecond?.enabled && (
                  <div className={styles.mealRichTextWrapper}>
                    <RichTextEditor
                      key={`richText-addOnSecond-${selectedDay}`}
                      initialValue={selectedWeekDay?.addOnSecond?.desc}
                      setValue={(e) =>
                        onChangeBeveragesAndAddOnsText(
                          e,
                          selectedDay,
                          "addOnSecond"
                        )
                      }
                    />
                  </div>
                )}
              </>
            )}

            <div className={styles.selectContainer}>
              <Input
                value={prepList.title}
                onChange={(e) => onChagePrepListTitle(e)}
                placeholder={"Week 1 + 3 PREP LIST"}
                errorBorder={""}
                classesInner={styles.selectInput}
              />
            </div>
            <div className={styles.mealRichTextWrapper}>
              <RichTextEditor
                key={`richText-prepList1`}
                initialValue={prepList.description}
                setValue={(e) => onChagePrepListText(e)}
              />
            </div>
            {/* <div className={styles.selectContainer}>
              <Input
                value={prepListSecond.title}
                onChange={(e) => onChagePrepListSecondTitle(e)}
                placeholder={"Week 2 + 4 PREP LIST"}
                errorBorder={""}
                classesInner={styles.selectInput}
              />
            </div>
            <div className={styles.mealRichTextWrapper}>
              <RichTextEditor
                key={`richText-PrepList2`}
                initialValue={prepListSecond.description}
                setValue={(e) => onChagePrepListSecondText(e)}
              />
            </div> */}

            <div className={styles.footer}>
              <Button title={"Cancel"} classes={styles.cancelBtn} />
              <Button
                onClick={() => onCreateMealPlans()}
                loading={isLoadingCreateMealPlan}
                title={"Save"}
                classes={styles.saveBtn}
              />
              {/* <Button
            title={"Save & Create Another One"}
            classes={styles.createBtn}
          /> */}
            </div>
          </>
        )}
      </div>
      {savedModal.isShow && <TopModal title="Sucessfully saved" />}
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={"Are you sure you want to delete?"}
          onClick={onDeleteMeal}
          // loading={isLoadingDelete}
        />
      )}
    </>
  );
};

export default EditMealPlan;
