import React from "react";
import { ArrowUp } from "assets";
import styles from "./styles.module.scss";

const TrackersInput = ({ label, value, setValue }) => {
  const onChangeValue = (e) => {
    setValue(e.target.value);
  };

  const increaseValue = () => {
    setValue((prev) => +prev + 1);
  };

  const descreaseValue = () => {
    if (value > 0) {
      setValue((prev) => prev - 1);
    }
  };

  return (
    <div>
      {label && <p className={styles.label}>{label}</p>}
      <div className={styles.wrapper}>
        <input
          value={value}
          onChange={onChangeValue}
          type={"string"}
          className={styles.input}
          maxLength={4}
        />

        <div className={styles.arrowBtnWrapper}>
          <div onClick={increaseValue} className={styles.increaseBtn}>
            <ArrowUp />
          </div>
          <div onClick={descreaseValue} className={styles.decreaseBtn}>
            <ArrowUp />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackersInput;
