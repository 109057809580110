//react
import React, { useState, useRef } from "react";

//components
import { Input } from "components";

//assets
import { Delete, Arrow } from "assets";

//styles
import styles from "./styles.module.scss";
import clsx from "clsx";

import { useOutsideClick } from "hooks";

const BoostCampPriceItem = ({ item, index = 0, priceItems, setPriceItems }) => {
  const [isVisibleOptions, setIsVisibleOptions] = useState(false);
  const selectRef = useRef(null);

  useOutsideClick(selectRef, () => {
    setIsVisibleOptions(false);
  });

  const onChangeDate = (e) => {
    const copy = [...priceItems];
    copy[index].startDate = e.target.value;
    setPriceItems(copy);
  };

  const onChangePrice = (e) => {
    const copy = [...priceItems];
    copy[index].price = e.target.value;
    setPriceItems(copy);
  };
  const onChangeCommunity = (e) => {
    const copy = [...priceItems];
    copy[index].link = e.target.value;
    setPriceItems(copy);
  };

  const clearCommunity = () => {
    const copy = [...priceItems];
    copy[index].link = "";
    setPriceItems(copy);
  };

  const onChangeDiscount = (e) => {
    const copy = [...priceItems];
    copy[index].discount.count = e.target.value;
    setPriceItems(copy);
  };
  const onChangeDiscountType = (type) => {
    const copy = [...priceItems];
    copy[index].discount.type = type;
    setPriceItems(copy);
  };

  const onDeletePriceItem = (index) => {
    setPriceItems((prev) => {
      const copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.flexRow}>
        <div style={{ flex: 2, marginRight: "16px" }}>
          <Input
            placeholder="Monday"
            value={item?.title}
            onChange={onChangeDate}
            disabled={true}
          />
        </div>
        <div style={{ flex: 1, marginRight: "16px" }}>
          <Input
            placeholder="Price"
            type={"number"}
            value={item?.price}
            onChange={onChangePrice}
          />
        </div>
        <div
          style={{
            flex: 2.5,
            display: "flex",
            flexDirection: "row",
            position: "relative",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "16px" }}>
            <Input
              placeholder="$"
              type={"number"}
              value={item?.discount.count}
              onChange={onChangeDiscount}
            />
          </div>
          <div
            ref={selectRef}
            onClick={() => setIsVisibleOptions((prev) => !prev)}
            className={clsx(styles.discountTypeWrapper, {
              [styles.discountTypeWrapperOpen]: isVisibleOptions,
            })}
          >
            <span className={styles.discountTypeTitle}>
              {item?.discount.type === "percentage" ? "%" : "$"}
            </span>
            <span
              className={clsx(styles.discountTypeArrow, {
                [styles.discountTypeArrowOpen]: isVisibleOptions,
              })}
            >
              <Arrow />
            </span>
            {isVisibleOptions && (
              <div className={styles.optionsList}>
                <div
                  onClick={() => onChangeDiscountType("percentage")}
                  className={styles.optionItem}
                >
                  %
                </div>
                <div
                  onClick={() => onChangeDiscountType("count")}
                  className={styles.optionItem}
                >
                  $
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={styles.deleteBtn}
          onClick={() => onDeletePriceItem(index)}
        >
          <Delete />
        </div>
      </div>
      <div className={styles.communityWrapper}>
        <div className={styles.communityInputContainer}>
          <Input
            placeholder="Facebook link"
            value={item?.link}
            onChange={onChangeCommunity}
          />
        </div>
        <div onClick={clearCommunity} className={styles.deleteBtn}>
          <Delete />
        </div>
      </div>
    </div>
  );
};

export default BoostCampPriceItem;
