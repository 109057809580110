import {
  DotsIcon,
  Delete,
  PencilSmallIcon,
  CopyIcon,
  Filter,
  CrossIcon,
  AddLinkIcon,
} from 'assets';
import { Checkbox } from 'components';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import Chiken from '../../assets/chiken.png';
import { motion } from 'framer-motion';
import { useRef } from 'react';
import { useOutsideClick, useToggleModal } from 'hooks';
import { fetchRequest } from 'utils';
import { Spinner } from 'react-activity';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { DeleteModal } from 'modals';
const RecipeItem = ({
  item,
  onGetRecipes,
  isSelect = false,
  onClick = () => {},
  withDelete = false,
  deleteHandler,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingDublicate, setIsLoadingDublicate] = useState(false);
  const deleteModal = useToggleModal();

  const navigate = useNavigate();

  const ref = useRef();

  useOutsideClick(ref, () => setIsOpenMenu(false));

  const onDublicate = async () => {
    try {
      setIsLoadingDublicate(true);
      const res = await fetchRequest(`recipes/${item.id}`, 'POST');
      if (res?.success) {
        onGetRecipes(1, 20, '');
      }
      setIsLoadingDublicate(false);
    } catch (error) {
      setIsLoadingDublicate(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsLoadingDelete(true);
      const res = await fetchRequest(`recipes/${item.id}`, 'DELETE');
      onGetRecipes(1, 20, '');
      setIsLoadingDelete(true);
    } catch (error) {
      setIsLoadingDelete(false);
    }
  };

  async function copyTextToClipboard() {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(
        `https://www.manageinsulin.com/recipe/${item?.id}`
      );
    } else {
      return document.execCommand(
        'copy',
        true,
        `https://www.manageinsulin.com/recipe/${item?.id}`
      );
    }
  }

  return (
    <div
      onClick={() => {
        onClick(item);
      }}
      ref={ref}
      className={styles.wrapper}
    >
      {/* <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} /> */}
      <img src={item?.image} className={styles.img} alt={'Recipe Image'} />
      <p className={styles.title}>{item?.title}</p>
      <span className={styles.ingredientTitle}>
        {item?.ingredients?.length} ingredients
      </span>
      <span className={styles.cookTime}>
        {item?.date && format(new Date(item?.date), 'yyyy-MM-dd')}
      </span>
      <div className={styles.tagsContainer}>
        {item?.tags?.map((item, index) => (
          <div className={styles.tag} key={item?.id}>
            {item?.text}
          </div>
        ))}
      </div>
      {!isSelect && !withDelete && (
        <DotsIcon
          onClick={() => setIsOpenMenu((prev) => !prev)}
          className={styles.menuBtn}
        />
      )}

      {withDelete && (
        <CrossIcon
          onClick={deleteHandler}
          style={{ color: '#afafaf' }}
          width={30}
          height={30}
          className={styles.menuBtn}
        />
      )}

      {isOpenMenu && (
        <motion.div
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}
          className={styles.menuWrapper}
        >
          <div
            onClick={() => {
              copyTextToClipboard();
              setIsOpenMenu(false);
            }}
            className={styles.menuButton}
          >
            <div className={styles.copyIconWrapper}>
              <AddLinkIcon style={{ color: '#000' }} />
            </div>

            <span style={{ marginLeft: '7px' }}>Copy Link</span>
          </div>
          <div onClick={onDublicate} className={styles.menuButton}>
            {isLoadingDublicate ? (
              <div className={styles.deleteLoader}>
                <Spinner color={'#000'} size={11} />
              </div>
            ) : (
              <>
                <div className={styles.copyIconWrapper}>
                  <CopyIcon />
                </div>

                <span style={{ marginLeft: '7px' }}>Duplicate</span>
              </>
            )}
          </div>
          <div
            onClick={() => navigate(`/recipes/edit/${item?.id}`)}
            className={styles.menuButton}
          >
            <PencilSmallIcon width={19} height={19} />
            <span style={{ marginLeft: '7px' }}>Edit</span>
          </div>
          <div
            onClick={() => {
              setIsOpenMenu(false);
              deleteModal.showModal();
            }}
            className={styles.menuButton}
          >
            {isLoadingDelete ? (
              <div className={styles.deleteLoader}>
                <Spinner color={'#EE3758'} size={11} />
              </div>
            ) : (
              <>
                <Delete />
                <span style={{ color: '#EE3758' }}>Delete</span>
              </>
            )}
          </div>
        </motion.div>
      )}
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={'Are you sure you want to delete?'}
          onClick={onDelete}
          loading={isLoadingDelete}
        />
      )}
    </div>
  );
};

export default RecipeItem;
