import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
//consts
import { menu, menuAdmin } from "consts";
// assets
import { ArrowUp } from "../../assets";
//styles
import styles from "./styles.module.scss";

const Sidebar = ({ onClick, admin }) => {
  const { myRole } = useSelector((state) => state.profileData);

  const menuBtns = myRole === "admin" ? menu : menuAdmin;

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar__row}>
        <div className={styles.sidebar__logo}>Boost Camp</div>
        <h4>Admin tools</h4>
        {admin?.admin?.super ? (
          <div className={styles.sidebar__menu}>
            {menu.map((item) => (
              <NavLink key={item.to} to={item.to}>
                {item.icon()}
                {item.title}
              </NavLink>
            ))}
          </div>
        ) : (
          <div className={styles.sidebar__menu}>
            {menuBtns.map((item, index) => {
              if (item?.links?.length > 0) {
                return (
                  <SidebarNavLinkExtended
                    key={`${item.to}-${index}`}
                    item={item}
                  />
                );
              }
              return (
                // <div onClick={() => setIsOpenBoostCampsBtn(false)}>
                <NavLink className={styles.link} key={item.to} to={item.to}>
                  {item.icon()}
                  {item.title}
                </NavLink>
                // </div>
              );
            })}
          </div>
        )}
      </div>
      <div className={styles.sidebar__profile}>
        <h4>{myRole}</h4>
        <button style={{ fontWeight: "500" }} onClick={onClick}>
          Log out
        </button>
      </div>
    </div>
  );
};

const SidebarNavLinkExtended = ({ item }) => {
  const [isOpenBtn, setIsOpenBtn] = useState(false);

  return (
    <div>
      <div
        className={clsx(styles.dropdownBtn, {
          [styles.dropdownBtnActive]: isOpenBtn,
        })}
        onClick={() => setIsOpenBtn((prev) => !prev)}
      >
        {item.icon()}
        {item.title}
        <div className={styles.arrowWrapper}>
          <ArrowUp />
        </div>
      </div>
      {isOpenBtn && (
        <div className={styles.navigationBtnWrapper}>
          {item?.links?.map((el, index) => (
            <NavLink
              className={styles.linkMenu}
              key={`${el.to}-${index}`}
              to={el.to}
            >
              {el.title}
            </NavLink>
          ))}
          <div className={styles.border} />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
