import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import Modal from "react-modal";
import { SearchIcon, SmallCheck } from "assets";
import { useCallback } from "react";
import { Spinner } from "react-activity";
import clsx from "clsx";
import { Checkbox, MealPlanItem, RecipeItem } from "components";
import { fetchRequest } from "utils";
const limit = 30;
const AttachMealModal = ({ isOpen, setIsOpen, onSelectMeal }) => {
  const [search, setSearch] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagesCount, setPagesCount] = useState(1);
  const [page, setPage] = useState(1);
  const [mealPlans, setMealPlans] = useState([]);

  const onChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const onGetMeal = async (page, limit, q = "", tags = []) => {
    try {
      setIsLoading(true);

      let url = `meal-plans?page=${page}&limit=${limit}`;
      if (q) {
        url = url + `&q=${q}`;
      }

      const res = await fetchRequest(url, "GET");
      if (res?.success) {
        setPagesCount(Math.ceil(res?.data?.count / limit));
        setMealPlans(res?.data?.rows);
        setPage(page);
      }
      // if (!q) {
      //   setSearch("");
      // }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onSelect = (item) => {
    onSelectMeal(item);
    setIsOpen(false);
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search && isOpen) {
        onGetMeal(1, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search, isOpen]);

  useEffect(() => {
    if (!search && isOpen) {
      onGetMeal(page, limit, "");
    }
  }, [search, isOpen]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className={styles.modal}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalInner}>
        <p className={styles.title}>Attach Meal Plan</p>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            value={search}
            onChange={onChangeSearch}
            className={styles.searchInput}
            placeholder={"Search by name..."}
          />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.recipesContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <Spinner color="#8C42E3" size={30} />
              </div>
            )}
            {!isLoading &&
              mealPlans?.map((item, index) => (
                <MealPlanItem
                  isSelect={true}
                  item={item}
                  key={item?.id}
                  onClick={(item) => onSelect(item)}
                />
              ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AttachMealModal;
