import { ArrowDown, PlusSquareIcon, SearchIcon } from "assets";
import { Button, Checkbox, Header, MealPlanItem, PageItem } from "components";
import { Manage } from "layouts";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { fetchRequest } from "utils";
import { Spinner } from "react-activity";
const limit = 20;
const MealPlansManage = () => {
  const [search, setSearch] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [mealPlans, setMealPlans] = useState([]);

  const navigate = useNavigate();

  const onGetMealPlans = async (page = 1, limit, q = "") => {
    try {
      setIsLoading(true);
      let url = `meal-plans?page=${page}&limit=${limit}&sort=-createdAt`;
      if (q) {
        url = url + `&q=${q}`;
      }
      const res = await fetchRequest(url, "GET");
      if (res?.success) {
        setPagesCount(Math.ceil(res?.data?.count / limit));
        setMealPlans(res?.data?.rows);
        setPage(page);
      }
      // if (!q) {
      //   setSearch("");
      // }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetMealPlans(1, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!search) {
      onGetMealPlans(page, limit, "");
    }
  }, [search]);

  const onChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>Meal Plans</h1>
        <div className={styles.createMealBtnWrapper}>
          <Button
            title={"Create New Meal Plan"}
            onClick={() => navigate("/mealplans/create")}
            icon={() => <PlusSquareIcon />}
          />
        </div>
      </div>
      <Manage>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            value={search}
            onChange={onChangeSearch}
            className={styles.searchInput}
            placeholder={"Search by title, description..."}
          />
        </div>
        <div className={styles.listHeader}>
          <div className={styles.listHeaderCheckedWrapper}>
            {/* <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} /> */}
            <span className={styles.listHeaderCheckedWrapperTitle}>
              Meal Plan Name
            </span>
            {/* <ArrowDown className={styles.listHeaderCheckedWrapperArrow} /> */}
          </div>
          <span className={styles.listHeaderShoppingTitle}>Shopping List</span>
        </div>
        <div className={styles.list}>
          {isLoading && (
            <div className={styles.listLoader}>
              <Spinner color={"#8C42E3"} size={40} />
            </div>
          )}
          {!isLoading &&
            mealPlans.map((item, index) => (
              <MealPlanItem
                item={item}
                key={item.id}
                onGetMealPlans={onGetMealPlans}
              />
            ))}
        </div>
        <div className={styles.paginationContainer}>
          {Array(pagesCount === 0 ? 1 : pagesCount)
            .fill("_")
            .map((item, index) => (
              <PageItem
                onPress={() => onGetMealPlans(index + 1, limit, search)}
                key={index}
                page={index + 1}
                isActive={index + 1 === page}
              />
            ))}
          {pagesCount > 1 && page !== pagesCount && (
            <div
              onClick={() => onGetMealPlans(page + 1, limit, search)}
              className={styles.nextBtn}
            >
              Next
            </div>
          )}
        </div>
      </Manage>
    </div>
  );
};

export default MealPlansManage;
