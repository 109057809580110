import { Delete, PlusIcon, DeleteSmall, ImgPlaceholder } from "assets";
import {
  Input,
  InputSelect,
  TextAreaEditor,
  Button,
  SearchIngredients,
  RichTextEditor,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-activity";
import { fetchRequest } from "utils";
import styles from "./styles.module.scss";
import TopModal from "modals/TopModal";
import { useToggleModal } from "hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteFile, uploadFile } from "services/file.service";
import clsx from "clsx";

const EditIntroScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [preview, setPreview] = useState();

  const [isLoadingIntroScreen, setIsLoadingIntroScreen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [isLoadingDeleteFile, setIsLoadingDeleteFile] = useState(false);

  const [pictureError, setPictureError] = useState(false);

  const savedModal = useToggleModal();
  const updatedShown = () => {
    savedModal.showModal();

    setTimeout(() => {
      savedModal.closeModal();
    }, 3000);
  };
  useEffect(() => {
    if (name?.length > 2 && shortDescription?.length > 3 && !!preview) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [name, shortDescription, preview]);

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const onChangeShortDescription = useCallback((e) => {
    setShortDescription(e);
  }, []);

  const onEditIntroScreen = async () => {
    try {
      setIsSaveLoading(true);

      const body = {
        title: name,
        body: shortDescription,
        image: preview,
      };

      const res = await fetchRequest(`info-blocks/${params?.id}`, "PUT", body);
      if (res?.success) {
        updatedShown();
        // navigate("/intro-screens/manage");
      } else {
        alert(res?.message || "Something went wrong");
      }
      setIsSaveLoading(false);
    } catch (e) {
      setIsSaveLoading(false);
    }
  };

  const onGetIntroScreen = async () => {
    try {
      setIsLoadingIntroScreen(true);
      const res = await fetchRequest(`info-blocks/${params?.id}`, "GET");
      if (res?.success) {
        setName(res?.data?.title);
        setShortDescription(res?.data?.body);
        setPreview(res?.data?.image);
      }
    } catch (e) {
    } finally {
      setIsLoadingIntroScreen(false);
    }
  };

  useEffect(() => {
    onGetIntroScreen();
  }, []);

  const onSelectFile = async (e) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setIsLoadingUploadFile(true);
      const res = await uploadFile(e.target.files[0]);
      setPreview(res?.data?.url);
      setIsLoadingUploadFile(false);
    } catch (error) {
      setIsLoadingUploadFile(false);

      console.log(error);
    }
  };
  const onDeleteFile = async (e) => {
    try {
      setIsLoadingDeleteFile(true);
      const res = await deleteFile(preview);
      setPreview(undefined);
      setIsLoadingDeleteFile(false);
    } catch (e) {
      setIsLoadingDeleteFile(false);

      console.log(e);
    }
  };

  if (isLoadingIntroScreen) {
    return (
      <div className={styles.wrapper}>
        <h1>Edit Intro Screen</h1>
        <div className={styles.loaderWrapper}>
          <Spinner size={40} color={"#8C42E3"} />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={styles.wrapper}>
        <h1>Edit Intro Screen</h1>

        <div className={styles.infoImageWrapper}>
          {preview ? (
            <img
              src={preview}
              className={clsx(styles.infoImage, {
                [styles.infoImageError]: pictureError,
              })}
              alt={"photo"}
            />
          ) : (
            <div
              className={clsx(styles.infoImage, {
                [styles.infoImageError]: pictureError,
              })}
            >
              <ImgPlaceholder />
            </div>
          )}
          <div className={styles.flex}>
            <label
              htmlFor={"photoInput"}
              className={styles.infoImageUploadWrapper}
            >
              {isLoadingUploadFile ? (
                <span>
                  <Spinner size={12} />
                </span>
              ) : (
                "Upload New"
              )}
              <input
                id={"photoInput"}
                type={"file"}
                accept="image/*"
                onChange={onSelectFile}
                className={styles.infoImageUploadBtn}
              />
            </label>
            <div onClick={onDeleteFile} className={styles.infoImageDeleteBtn}>
              {isLoadingDeleteFile ? (
                <span>
                  <Spinner size={12} />
                </span>
              ) : (
                "Delete photo"
              )}
            </div>
          </div>
        </div>
        <div className={styles.divider} />

        <div className={styles.inputsWrapper}>
          <Input
            value={name}
            onChange={onChangeName}
            label={"Title"}
            placeholder={"Title"}
            classes={styles.input}
          />
          <RichTextEditor
            initialValue={shortDescription}
            setValue={onChangeShortDescription}
            label={"Description"}
          />
        </div>

        <div className={styles.footer}>
          <Button title={"Cancel"} classes={styles.cancelBtn} />
          <Button
            onClick={() => onEditIntroScreen()}
            disabled={isDisabled}
            loading={isSaveLoading}
            title={"Save"}
            classes={styles.saveBtn}
          />
          {/* <Button
            onClick={() => onCreateShopingList(true)}
            disabled={isDisabled}
            loading={isSaveAnotherLoading}
            title={"Save & Create Another One"}
            classes={styles.createBtn}
          /> */}
        </div>
      </div>
      {savedModal.isShow && <TopModal title="Sucessfully saved" />}
    </>
  );
};

export default EditIntroScreen;
