//react
import React, { useState, useCallback, useEffect, useRef } from "react";

//components
import { Input, TextAreaEditor, QuestionItem, Button } from "components";
import { DayItem } from "./DayItem";
import TopModal from "../../../../modals/TopModal";
import { CustomAlert } from "components";

//navigation
import { useNavigate, useLocation, useParams } from "react-router-dom";

//hooks
import { useToggleModal } from "../../../../hooks";

//header
import CreateBoostCampHeader from "../CreateBoostCamps/BoostCampHeader/boostCampHeader";

//assets
import { BoostCamps, ImgPlaceholder, Add, Upload, HouseIcon } from "assets";

//styles
import styles from "./styles.module.scss";
import { fetchRequest } from "utils";

const FillDaysBoostCamps = ({}) => {
  const location = useLocation();
  const params = useParams();
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingTest, setIsLoadingTest] = useState(false);
  const [boostCamp, setBoostCamp] = useState({});

  const savedModal = useToggleModal();
  const cancelModal = useToggleModal();

  const updatedShown = () => {
    savedModal.showModal();

    setTimeout(() => {
      savedModal.closeModal();
      navigate("/boostcamps/manage");
    }, 1500);
  };

  const navigate = useNavigate();

  const onGetBoostCamp = async () => {
    try {
      const res = await fetchRequest(`boost-camps/${params?.id}`, "GET");
      if (res?.status === 200) {
        setBoostCamp(res?.data);
        setDaysInfo(res?.data?.days);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortDays = (days, maxDays) => {};

  const [daysInfo, setDaysInfo] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    let total = 0;

    daysInfo.forEach((day) =>
      day?.tasks?.forEach((el) => (total += +el?.points))
    );
    setTotalPoints(total);
  }, [daysInfo]);
  const onSaveBoostCamps = async () => {
    try {
      setIsLoadingSave(true);
      const body = {
        days: daysInfo,
      };

      const res = await fetchRequest(`boost-camps/${params?.id}`, "PUT", body);

      if (res?.status === 202) {
        updatedShown();
      }

      setIsLoadingSave(false);
    } catch (error) {
      setIsLoadingSave(false);
      console.log(error);
    }
  };

  const onTestBoostCamps = async () => {
    try {
      setIsLoadingTest(true);
      const body = {
        days: daysInfo,
      };

      const res = await fetchRequest(`boost-camps/${params?.id}`, "PUT", body);
      if (res?.status === 202) {
        navigate(`/preview/${params?.id}`);
      }

      setIsLoadingTest(false);
    } catch (error) {
      setIsLoadingTest(false);
      console.log(error);
    }
  };

  useEffect(() => {
    onGetBoostCamp();
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <CreateBoostCampHeader numbersOfStep={2} totalPoints={totalPoints} />
        {daysInfo?.map((item, index) => {
          return (
            <DayItem
              key={index}
              item={item}
              daysInfo={daysInfo}
              setDaysInfo={setDaysInfo}
              maxDays={boostCamp?.daysNumber}
              index={index}
              id={params?.id}
            />
          );
        })}
        <div className={styles.btnsContainer}>
          <div style={{ marginRight: "10px" }}>
            <Button
              onClick={() => onTestBoostCamps()}
              loading={isLoadingTest}
              icon={() => <HouseIcon />}
              title={"Preview"}
              classes={styles.greenBtn}
            />
          </div>
          <div style={{ marginRight: "10px" }}>
            <Button
              title={"Create Boost Camp"}
              onClick={onSaveBoostCamps}
              loading={isLoadingSave}
            />
          </div>
          <div>
            <Button
              title={"Cancel"}
              onClick={cancelModal.showModal}
              emptyStyle
            />
          </div>
        </div>
      </div>
      {savedModal.isShow && <TopModal title="Successfully saved" />}
      {cancelModal.isShow && (
        <CustomAlert
          showModal={cancelModal.showModal}
          closeModal={cancelModal.closeModal}
          title={"Are you sure you want to exit?"}
          text={""}
          titleBtn={"Yes"}
          titleSecondBtn={"No"}
          isOpen={cancelModal.isShow}
          onClick={() => navigate("/boostcamps/manage")}
        />
      )}
    </>
  );
};

export default FillDaysBoostCamps;
