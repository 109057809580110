//react
import React, { useState, useEffect } from "react";

//components
import EditBoostCampsDays from "./EditBoostCampsDays";
import EditBoostCampsMain from "./EditBoostCampsMain";

//navigation
import { useParams } from "react-router-dom";

//header
import EditBoostCampsHeader from "./EditBoostCampsHeader";

//utils
import { fetchRequest } from "utils";

//styles
import styles from "./styles.module.scss";

import { Spinner } from "react-activity";

const EditBoostCamp = ({}) => {
  const params = useParams();
  const [isLoadingBoostcamp, setIsLoadingBoostCamp] = useState(false);
  const [isLoadingBoostcampDays, setIsLoadingBoostCampDays] = useState(false);
  const [isLoadingBoostcampMeal, setIsLoadingBoostCampMeal] = useState(false);
  const [title, setTitle] = useState("");
  const [numberOfDay, setNumberOfDay] = useState("");
  const [preview, setPreview] = useState();
  const [daysInfo, setDaysInfo] = useState([]);
  const [maxDays, setMaxDays] = useState(0);
  const [shortDescription, setShortDescription] = useState("");
  const [fullOverview, setFullOverview] = useState("");
  const [questions, setQuestions] = useState([{ question: "", answer: "" }]);
  const [days, setDays] = useState([]);
  const [priceItems, setPriceItems] = useState([
    {
      title: "Monday 1",
      price: "",
      discount: { count: 0, type: "percentage" },
      link: "",
    },
    {
      title: "Monday 2",
      price: "",
      discount: { count: 0, type: "percentage" },
      link: "",
    },
    {
      title: "Monday 3",
      price: "",
      discount: { count: 0, type: "percentage" },
      link: "",
    },
    {
      title: "Monday 4",
      price: "",
      discount: { count: 0, type: "percentage" },
      link: "",
    },
  ]);
  const [recipes, setRecipes] = useState([
    { title: "Upload main PDF file for shopping list", fileUrl: "" },
  ]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [mealPlanPdf, setMealPlanPdf] = useState([
    {
      title: "Upload PDF file for meal plan",
      fileUrl: "",
    },
  ]);
  const [mealPlans, setMealPlans] = useState([
    { title: "Week 1 + Week 3", meal: null },
    { title: "Week 2 + Week 4", meal: null },
  ]);
  const [reviews, setReviews] = useState([
    {
      name: "",
      city: "",
      text: "",
      rating: { label: "5 stars", value: 5 },
      avatar: "",
    },
  ]);

  //print
  const [printRecipePrice, setPrintRecipePrice] = useState();
  const [printRecipeTitle, setPrintRecipeTitle] = useState();
  const [printRecipeText, setPrintRecipeText] = useState();

  const onGetBoostCamp = async () => {
    try {
      setIsLoadingBoostCamp(true);
      const res = await fetchRequest(`boost-camps/${params?.id}`, "GET");
      console.log("onGetBoostCamp", res?.data);
      if (res?.status === 200) {
        // setBoostCamp(res?.data);
        setTitle(res?.data?.title);
        setNumberOfDay(res?.data?.daysNumber);
        setPreview(res?.data?.picture);
        setShortDescription(res?.data?.description);
        setFullOverview(res?.data?.overview);
        setQuestions(res?.data?.faq);
        setDays(res?.data?.days);
        setPriceItems(res?.data?.prices);
        // setDaysInfo(res?.data?.days);
        setMaxDays(res?.data?.daysNumber);
        setPrintRecipePrice(res?.data?.printRecipe?.price);
        setPrintRecipeTitle(res?.data?.printRecipe?.title);
        setPrintRecipeText(res?.data?.printRecipe?.text);

        if (res?.data?.shoppingListPdf) {
          let recipesSorted = [
            {
              title: "Upload main PDF file for shoping list",
              fileUrl: res?.data?.shoppingListPdf,
            },
          ];
          setRecipes(recipesSorted);
        }
        if (res?.data?.mealPlanPdf) {
          let mealPlanSorted = [
            {
              title: "Upload PDF file for meal plan",
              fileUrl: res?.data?.mealPlanPdf,
            },
          ];
          setMealPlanPdf(mealPlanSorted);
        }
        // if (res?.data?.mealPlan?.length === 2) {
        //   setMealPlans([
        //     { title: "Week 1 + Week 3", meal: res?.data?.mealPlan[0] },
        //     { title: "Week 2 + Week 4", meal: res?.data?.mealPlan[1] },
        //   ]);
        // } else if (res?.data?.mealPlan?.length === 4) {
        //   setMealPlans([
        //     { title: "Week 1", meal: res?.data?.mealPlan[0] },
        //     { title: "Week 2", meal: res?.data?.mealPlan[1] },
        //     { title: "Week 3", meal: res?.data?.mealPlan[2] },
        //     { title: "Week 4", meal: res?.data?.mealPlan[3] },
        //   ]);
        // }
        if (res?.data?.reviews) {
          setReviews(
            res?.data.reviews?.map((item, index) => ({
              name: item.name,
              city: item.city,
              text: item.text,
              rating: {
                label: `${item.rating} ${item.rating > 1 ? "stars" : "star"}`,
                value: item.rating,
              },
              avatar: item.avatar,
            }))
          );
        }
      }
      setIsLoadingBoostCamp(false);
    } catch (error) {
      console.log(error);
      setIsLoadingBoostCamp(false);
    }
  };

  const onGetBoostCampDays = async () => {
    try {
      setIsLoadingBoostCampDays(true);
      const res = await fetchRequest(`boost-camps/${params?.id}/days`, "GET");
      console.log("onGetBoostCampDays", res?.data);
      if (res?.status === 200) {
        setDaysInfo(res?.data?.days);
      }
      setIsLoadingBoostCampDays(false);
    } catch (error) {
      console.log(error);
      setIsLoadingBoostCampDays(false);
    }
  };
  const onGetBoostCampMeal = async () => {
    try {
      setIsLoadingBoostCampMeal(true);
      const res = await fetchRequest(`boost-camps/${params?.id}/meal`, "GET");
      console.log("onGetBoostCampMeal", res?.data);
      if (res?.status === 200) {
        if (res?.data?.length === 2) {
          setMealPlans([
            { title: "Week 1 + Week 3", meal: res?.data[0] },
            { title: "Week 2 + Week 4", meal: res?.data[1] },
          ]);
        } else if (res?.data?.length === 4) {
          setMealPlans([
            { title: "Week 1", meal: res?.data[0] },
            { title: "Week 2", meal: res?.data[1] },
            { title: "Week 3", meal: res?.data[2] },
            { title: "Week 4", meal: res?.data[3] },
          ]);
        }
      }
      setIsLoadingBoostCampMeal(false);
    } catch (error) {
      console.log(error);
      setIsLoadingBoostCampMeal(false);
    }
  };

  useEffect(() => {
    onGetBoostCamp();
    onGetBoostCampMeal();
    onGetBoostCampDays();
  }, []);

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    <EditBoostCampsMain
      title={title}
      setTitle={setTitle}
      numberOfDay={numberOfDay}
      setNumberOfDay={setNumberOfDay}
      preview={preview}
      setPreview={setPreview}
      shortDescription={shortDescription}
      setShortDescription={setShortDescription}
      fullOverview={fullOverview}
      setFullOverview={setFullOverview}
      questions={questions}
      setQuestions={setQuestions}
      days={days}
      setDays={setDays}
      priceItems={priceItems}
      setPriceItems={setPriceItems}
      recipes={recipes}
      setRecipes={setRecipes}
      totalPoints={totalPoints}
      setTotalPoints={setTotalPoints}
      mealPlanPdf={mealPlanPdf}
      setMealPlanPdf={setMealPlanPdf}
      mealPlans={mealPlans}
      setMealPlans={setMealPlans}
      reviews={reviews}
      setReviews={setReviews}
      printRecipePrice={printRecipePrice}
      setPrintRecipePrice={setPrintRecipePrice}
      printRecipeTitle={printRecipeTitle}
      setPrintRecipeTitle={setPrintRecipeTitle}
      printRecipeText={printRecipeText}
      setPrintRecipeText={setPrintRecipeText}
      isLoadingBoostcampMeal={isLoadingBoostcampMeal}
    />,
    <EditBoostCampsDays
      daysInfo={daysInfo}
      setDaysInfo={setDaysInfo}
      maxDays={maxDays}
      setMaxDays={setMaxDays}
      isLoadingBoostcampDay={isLoadingBoostcampDays}
    />,
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <EditBoostCampsHeader
          id={params?.id}
          totalPoints={totalPoints}
          activetab={activeTab}
          setActiveTab={setActiveTab}
        />
        {isLoadingBoostcamp ? (
          <div className={styles.loaderContainer}>
            <Spinner color={"#8C42E3"} size={40} />
          </div>
        ) : (
          tabs[activeTab]
        )}
      </div>
    </>
  );
};

export default EditBoostCamp;
