import { ArrowDown, PlusSquareIcon, SearchIcon } from "assets";
import {
  Button,
  Checkbox,
  Header,
  MealPlanItem,
  PageItem,
  RecipeItem,
} from "components";
import ShoppingItem from "components/ShoppingItem";
import { Manage } from "layouts";
import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import styles from "./styles.module.scss";
import { fetchRequest } from "utils";
import { Spinner } from "react-activity";
import { useNavigate } from "react-router-dom";

const limit = 20;

const ManageShoppingList = () => {
  const [search, setSearch] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [shoppingLists, setShoppingLists] = useState([]);

  const navigate = useNavigate();

  const onGetShoppingList = async (page, limit, q = "", tags = []) => {
    try {
      setIsLoading(true);

      let url = `shopping-lists?page=${page}&limit=${limit}&sort=-createdAt`;
      if (q) {
        url = url + `&q=${q}`;
      }
      const res = await fetchRequest(url, "GET");
      if (res?.success) {
        setPagesCount(Math.ceil(res?.data?.count / limit));
        setShoppingLists(res?.data?.rows);
        setPage(page);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetShoppingList(1, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!search) {
      onGetShoppingList(page, limit, "");
    }
  }, [search]);
  const onChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>Shopping Lists</h1>
        <div className={styles.createMealBtnWrapper}>
          <Button
            onClick={() => navigate("/shopping-list/create")}
            title={"Create New Shopping List"}
            icon={() => <PlusSquareIcon />}
          />
        </div>
      </div>
      <Manage>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            value={search}
            onChange={onChangeSearch}
            className={styles.searchInput}
            placeholder={"Search by title, description..."}
          />
        </div>
        <div className={styles.listHeader}>
          <div className={styles.listHeaderCheckedWrapper}>
            <span className={styles.listHeaderCheckedWrapperTitle}>
              Shopping List Name
            </span>
          </div>
          <span className={styles.listHeaderIngredientTitle}>Ingredients</span>
        </div>
        <div className={styles.list}>
          {isLoading && (
            <div className={styles.loader}>
              <Spinner size={30} color={"#8C42E3"} />
            </div>
          )}
          {!isLoading &&
            shoppingLists.map((item, index) => (
              <ShoppingItem
                onGetShoppingLists={onGetShoppingList}
                key={item?.id}
                item={item}
              />
            ))}
        </div>
        <div className={styles.paginationContainer}>
          {Array(pagesCount === 0 ? 1 : pagesCount)
            .fill("_")
            .map((item, index) => (
              <PageItem
                onPress={() => onGetShoppingList(index + 1, limit, search)}
                key={index}
                page={index + 1}
                isActive={index + 1 === page}
              />
            ))}
          {pagesCount > 1 && page !== pagesCount && (
            <div
              onClick={() => onGetShoppingList(page + 1, limit, search)}
              className={styles.nextBtn}
            >
              Next
            </div>
          )}
        </div>
      </Manage>
    </div>
  );
};

export default ManageShoppingList;
