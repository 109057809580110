export const updateProgressDay = (prev, result) => {
  return prev
    ? {
        ...prev,
        days: prev?.days
          ? prev.days.map((d) =>
              d.id === result?.data?.data?.id ? result?.data?.data : d
            )
          : [],
      }
    : null;
};
