import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { Arrow } from "../../../assets/index";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { RecipeItem } from "components";
import axios from "axios";

import { fetchRequest } from "utils";
const MealPlan = () => {
  const params = useParams();
  const [isLoadingBoostcamp, setIsLoadingBoostcamp] = useState(false);
  const [boostcamp, setBoostcamp] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [mealLength, setMealLength] = useState(2);
  const [activeDay, setActiveDay] = useState(0);
  const [isLoadingMealPlan, setIsLoadingMealPlan] = useState(false);
  const [currentMealPlan, setCurrentMealPlan] = useState(null);

  const onGetBoostcamp = async () => {
    try {
      setIsLoadingBoostcamp(true);
      const res = await fetchRequest(`boost-camps/${params?.id}`, "GET");
      if (res?.success) {
        setBoostcamp(res?.data);
        setMealLength(res?.data?.mealPlan?.length || 2);
      }
      setIsLoadingBoostcamp(false);
    } catch (e) {
      setIsLoadingBoostcamp(false);
    }
  };

  const onGetMealPlan = async (id) => {
    try {
      setIsLoadingMealPlan(true);
      const res = await fetchRequest(`meal-plans/${id}`, "GET");
      if (res?.success) {
        setCurrentMealPlan(res?.data);
      }
      setIsLoadingMealPlan(false);
    } catch (e) {
      setIsLoadingMealPlan(false);
    }
  };

  useEffect(() => {
    if (
      boostcamp &&
      boostcamp?.mealPlan &&
      boostcamp?.mealPlan?.length > 0 &&
      boostcamp?.mealPlan[currentWeek]
    ) {
      onGetMealPlan(boostcamp.mealPlan[currentWeek].id);
    }
  }, [boostcamp, currentWeek]);

  useEffect(() => {
    onGetBoostcamp();
  }, []);

  const onDownLoad = async () => {
    await axios({
      url: boostcamp?.mealPlanPdf,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${"Meal Plan"}.pdf`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(boostcamp?.mealPlanPdf);
        return response;
      })
      .catch((e) => e);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div
          onClick={() =>
            setCurrentWeek((prev) => {
              if (prev > 0) {
                return prev - 1;
              } else {
                return prev;
              }
            })
          }
          className={clsx(styles.prevBtn, { [styles.prevBtnInactive]: false })}
        >
          <Arrow style={{ transform: "rotate(180deg)" }} />
        </div>
        <span className={styles.title}>
          {mealLength === 2
            ? currentWeek === 0
              ? "Week 1 + 3"
              : currentWeek === 1 && "Week 2 + 4"
            : mealLength === 4 && currentWeek === 0
            ? "Week 1"
            : currentWeek === 1
            ? "Week 2"
            : currentWeek === 2
            ? "Week 3"
            : currentWeek === 3 && "Week 4"}
        </span>
        <div
          onClick={() =>
            setCurrentWeek((prev) => {
              if (mealLength === 2) {
                if (prev === 0) {
                  return prev + 1;
                } else {
                  return prev;
                }
              } else if (mealLength === 4) {
                if (prev < 3) {
                  return prev + 1;
                } else {
                  return prev;
                }
              }
              return prev;
            })
          }
          className={clsx(styles.prevBtn, { [styles.prevBtnInactive]: false })}
        >
          <Arrow />
        </div>
      </div>
      {(isLoadingMealPlan || isLoadingBoostcamp) && (
        <div className={styles.loader}>
          <ClipLoader color={"#8C42E3"} size={30} />
        </div>
      )}
      <div className={styles.contentContainer}>
        <div className={styles.daysContainer}>
          {!isLoadingMealPlan &&
            !isLoadingBoostcamp &&
            currentMealPlan?.week?.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setActiveDay(index)}
                  className={clsx(styles.dayItem, {
                    [styles.dayItemActive]: activeDay === index,
                  })}
                >
                  <span>{item?.title}</span>
                </div>
              );
            })}
          {!isLoadingMealPlan &&
            !isLoadingBoostcamp &&
            boostcamp?.mealPlanPdf && (
              <div onClick={onDownLoad} className={styles.mealPlanBtn}>
                Download Meal Plan
              </div>
            )}
        </div>
        <div className={styles.recipeContainer}>
          {!isLoadingBoostcamp &&
            !isLoadingMealPlan &&
            currentMealPlan?.week[activeDay]?.meal?.map((item, index) => {
              return (
                <div key={item?.title} className={styles.recipeItemWrapper}>
                  <span className={styles.title}>{item?.title}</span>
                  <div className={styles.recipeItemContainer}>
                    {item?.recipes?.map((item, index) => {
                      return (
                        <RecipeItem
                          isSelect={true}
                          key={item?.id}
                          item={item}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          {!isLoadingBoostcamp && !isLoadingMealPlan && (
            <div className={styles.recipeItemWrapper}>
              <span className={styles.title}>{currentMealPlan?.title}</span>
              <div className={styles.descriptionWrapper}>
                <p>{currentMealPlan?.description}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MealPlan;
