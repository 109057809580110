import { SET_WEEK } from "redux/actionTypes/mealTypes";

const DEFAULT_WEEK_MEAL = [
  {
    order: 0,
    title: "BREAK your FAST",
    recipes: [],
    info: "",
  },
  {
    order: 1,
    title: "LUNCH",
    recipes: [],
    info: "",
  },
  {
    order: 2,
    title: "DINNER",
    recipes: [],
    info: "",
  },
  {
    order: 3,
    title: "MEAL ADD ONS Up to 2 PER DAY",
    recipes: [],
    info: "",
  },
];

const initialState = {
  week: [
    {
      order: 0,
      title: "Monday",
      meal: DEFAULT_WEEK_MEAL,
    },
    {
      order: 1,
      title: "Tuesday",
      meal: DEFAULT_WEEK_MEAL,
    },
    {
      order: 2,
      title: "Wednesday",
      meal: DEFAULT_WEEK_MEAL,
    },
    {
      order: 3,
      title: "Thursday",
      meal: DEFAULT_WEEK_MEAL,
    },
    {
      order: 4,
      title: "Friday",
      meal: DEFAULT_WEEK_MEAL,
    },
    {
      order: 5,
      title: "Saturday",
      meal: DEFAULT_WEEK_MEAL,
    },
    {
      order: 6,
      title: "Sunday",
      meal: DEFAULT_WEEK_MEAL,
    },
  ],
};

export const mealReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WEEK:
      return { ...state, week: [...action.payload] };
    default:
      return state;
  }
};
