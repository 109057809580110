//react
import React from "react";

//components
import { Input } from "components";

//styles
import styles from "./styles.module.scss";
import { Delete } from "assets";

const TasksItem = ({ item, setDaysInfo, indexOfDays, index, daysInfo }) => {
  const onChangeTitle = (e) => {
    setDaysInfo((prev) => {
      const copy = [...prev];
      copy[indexOfDays].tasks[index].task = e.target.value;
      return copy;
    });
  };

  const onChangePoint = (e) => {
    setDaysInfo((prev) => {
      const copy = [...prev];
      copy[indexOfDays].tasks[index].points = e.target.value;
      return copy;
    });
  };

  const onDeleteTasks = () => {
    const copy = [...daysInfo];
    const copyTasks = [...copy[indexOfDays].tasks];
    copyTasks.splice(index, 1);
    copy[indexOfDays].tasks = [...copyTasks];
    setDaysInfo(copy);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleInputWrapper}>
        <Input
          value={item?.task}
          onChange={onChangeTitle}
          placeholder={`Task ${index + 1}`}
        />
      </div>
      <div className={styles.pointInputWrapper}>
        <Input
          value={item?.points}
          onChange={onChangePoint}
          type={"number"}
          placeholder={"points"}
        />
      </div>
      <div onClick={onDeleteTasks} className={styles.delete}>
        <Delete />
      </div>
    </div>
  );
};

export default TasksItem;
