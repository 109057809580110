import { Arrow, ArrowDown, PlusIcon, Delete } from "assets";
import {
  AddRecipeModal,
  Button,
  DayOfWeekItem,
  Input,
  InputSelect,
  RecipeItem,
  RichTextEditor,
  TextAreaEditor,
} from "components";
import React, { useEffect, useState, useRef } from "react";
import { useCallback } from "react";
import styles from "./styles.module.scss";
import { Switch } from "components";
import { fetchRequest } from "utils";
import TopModal from "modals/TopModal";
import { useNavigate } from "react-router-dom";
import { useToggleModal } from "hooks";
import clsx from "clsx";
import { useOutsideClick } from "hooks";
import { Spinner } from "react-activity";
import { DeleteModal } from "modals";

const DEFAULT_WEEK_MEAL = [
  {
    order: 0,
    title: "30 mins before every meal",
    recipes: [],
    info: "",
  },
  {
    order: 1,
    title: "BREAK your FAST",
    recipes: [],
    info: "",
  },
  {
    order: 2,
    title: "LUNCH",
    recipes: [],
    info: "",
  },
  {
    order: 3,
    title: "DINNER",
    recipes: [],
    info: "",
  },
  {
    order: 4,
    title: "MEAL ADD ONS Up to 2 PER DAY",
    recipes: [],
    info: "",
  },
];

const DEFAULT_BEV_MEAL = {
  title: "BEVERAGES",
  enabled: false,
  desc: "",
};
const DEFAULT_ADD_ON_MEAL = {
  title: "MEAL ADD ONS",
  enabled: false,
  desc: "",
};
const DEFAULT_ADD_ON_MEAL2 = {
  title: "MEAL ADD ONS",
  enabled: false,
  desc: "",
};

const DEFAULT_PREP_LIST = {
  type: "first",
  title: "Week 1 + 3 PREP LIST",
  description: "",
};
const DEFAULT_PREP_LIST_2 = {
  type: "second",
  title: "Week 2 + 4 PREP LIST",
  description: "",
};

const ShoppingListSelector = ({ value, setValue }) => {
  const [isOpenList, setIsOpenList] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [pagesCount, setPagesCount] = useState(0);
  const [shopingLists, setShopingLists] = useState([]);
  const ref = useRef(null);
  const limit = 10;
  useOutsideClick(ref, () => setIsOpenList(false));

  const onGetLists = async (page, limit, q = "") => {
    try {
      page === 1 && setIsLoading(true);
      page > 1 && setIsLoadingPagination(true);
      let url = `shopping-lists?page=${page}&limit=${limit}`;
      if (q) {
        url = url + `&q=${q}`;
      }
      const res = await fetchRequest(url, "GET");
      if (res?.success) {
        setPagesCount(Math.ceil(res?.data?.count / limit));
        if (page === 1) {
          setShopingLists(res?.data?.rows);
        } else {
          setShopingLists((prev) => [...prev, ...res?.data?.rows]);
        }

        setPage(page);
      }
      page === 1 && setIsLoading(false);
      page > 1 && setIsLoadingPagination(false);
    } catch (error) {
      setIsLoading(false);
      setIsLoadingPagination(false);
    }
  };

  useEffect(() => {
    onGetLists(page, limit);
  }, []);

  const handleScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1;
    if (bottom && !isLoadingPagination && pagesCount !== page) {
      onGetLists(page + 1, limit);
      // incrementPage();
    }
  };

  return (
    <div ref={ref} className={clsx(styles.input)}>
      <h4>Shopping List</h4>
      <div
        onClick={() => setIsOpenList((prev) => !prev)}
        className={clsx(styles.input__main, {})}
      >
        <span
          style={{ color: value?.title ? "#000" : "#afafaf" }}
          className={styles.value}
        >
          {value?.title ? value?.title : "Shopping List"}
        </span>
        <Arrow
          className={clsx(styles.arrow, { [styles.openArrow]: isOpenList })}
        />
      </div>
      {isOpenList && (
        <div onScroll={handleScroll} className={styles.dataList}>
          {isLoading && (
            <div className={styles.listLoading}>
              <Spinner size={20} color={"#8C42E3"} />
            </div>
          )}
          {!isLoading &&
            shopingLists?.map((item) => {
              return (
                <div
                  onClick={() => {
                    setValue(item);
                    setIsOpenList(false);
                  }}
                  key={item?.id}
                  className={styles.dataItem}
                >
                  {item?.title}
                </div>
              );
            })}
          {isLoadingPagination && (
            <div className={styles.listLoadingPagination}>
              <Spinner size={10} color={"#8C42E3"} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const CreateMealPlan = () => {
  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [shopingList, setShopingList] = useState();
  const [isOpenAddRecipeModal, setIsOpenAddRecipeModal] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(0);
  const [selectedMeal, setSelectedMeal] = useState(0);
  const [isAdd30mins, setIsAdd30mins] = useState(true);
  const [isMealPerDay, setIsMealPerDay] = useState(true);
  const [isLoadingCreateMealPlan, setIsLoadingCreateMealPlan] = useState(false);
  const [id, setId] = useState("");
  const [week, setWeek] = useState([
    {
      order: 0,
      title: "Monday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 1,
      title: "Tuesday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 2,
      title: "Wednesday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 3,
      title: "Thursday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 4,
      title: "Friday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 5,
      title: "Saturday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
    {
      order: 6,
      title: "Sunday",
      meal: DEFAULT_WEEK_MEAL,
      beverages: DEFAULT_BEV_MEAL,
      addOn: DEFAULT_ADD_ON_MEAL,
      addOnSecond: DEFAULT_ADD_ON_MEAL2,
    },
  ]);
  const [prepList, setPrepList] = useState(DEFAULT_PREP_LIST);
  // const [prepListSecond, setPrepListSecond] = useState(DEFAULT_PREP_LIST_2);

  const getStatusDay = (indexOfDay) => {
    let completedEat = week[indexOfDay]?.meal?.map((item, index) => {
      if (item?.recipes?.length > 0) {
        return true;
      } else {
        return false;
      }
    });
    const isComplete = completedEat?.some((item) => !item) ? false : true;

    if (isComplete) {
      return "complete";
    }
    return "default";
  };

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const savedModal = useToggleModal();
  const updatedShown = () => {
    savedModal.showModal();

    setTimeout(() => {
      savedModal.closeModal();
    }, 3000);
  };
  const navigation = useNavigate();

  const onChangeShortDescription = useCallback((e) => {
    setShortDescription(e.target.value);
  });

  const onSameForWholeWeek = (selectedWeek, mealIndex) => {
    const meal = { ...week[selectedWeek].meal[mealIndex] };
    const filledWeek = week.map((item, index) => {
      const copy = { ...item };
      copy.meal[mealIndex] = { ...meal };
      return copy;
    });
    setWeek(filledWeek);
  };
  const onSameForWholeWeekBeveragesAndAddOns = (selectedWeek, type) => {
    const copyByType = week[selectedWeek][type];
    const filledWeek = week.map((item, index) => {
      return {
        ...item,
        [type]: copyByType,
      };
    });
    setWeek(filledWeek);
  };

  const onChangeInfo = useCallback((e, index, selectedWeek) => {
    setWeek((prev) => {
      const copy = [...prev];
      copy[selectedWeek].meal[index].info = e.target.value;
      return copy;
    });
  }, []);
  const onChangeMealName = useCallback((e, index, selectedWeek) => {
    setWeek((prev) => {
      const copy = [...prev];
      copy[selectedWeek].meal[index].title = e.target.value;
      return copy;
    });
  }, []);

  const onChangeBeveragesAndAddOnsText = useCallback(
    (e, selectedWeek, type) => {
      setWeek((prev) => {
        return prev.map((el) =>
          el.order === selectedWeek
            ? {
                ...el,
                [type]: { ...el[type], desc: e },
              }
            : el
        );
      });
    },
    []
  );
  const onChangeBeveragesAndAddOnsTitle = useCallback(
    (e, selectedWeek, type) => {
      setWeek((prev) => {
        return prev.map((el) =>
          el.order === selectedWeek
            ? {
                ...el,
                [type]: { ...el[type], title: e.target.value },
              }
            : el
        );
      });
    },
    []
  );
  const onChangeBeveragesAndAddOnsEnabled = useCallback(
    (selectedWeek, type) => {
      setWeek((prev) => {
        return prev.map((el) =>
          el.order === selectedWeek
            ? {
                ...el,
                [type]: { ...el[type], enabled: !el[type].enabled },
              }
            : el
        );
      });
    },
    []
  );

  const onChagePrepListTitle = useCallback((e) => {
    setPrepList((prev) => ({ ...prev, title: e.target.value }));
  }, []);
  const onChagePrepListText = useCallback((e) => {
    setPrepList((prev) => ({ ...prev, description: e }));
  }, []);
  // const onChagePrepListSecondTitle = useCallback((e) => {
  //   setPrepListSecond((prev) => ({ ...prev, title: e.target.value }));
  // }, []);
  // const onChagePrepListSecondText = useCallback((e) => {
  //   setPrepListSecond((prev) => ({ ...prev, description: e }));
  // }, []);

  const onCreateMealPlans = async () => {
    try {
      if (!name) {
        setTitleError(true);
        return;
      } else {
        setTitleError(false);
      }
      if (!shortDescription) {
        setDescriptionError(true);
        return;
      } else {
        setDescriptionError(false);
      }
      setIsLoadingCreateMealPlan(true);

      const body = {
        title: name,
        description: shortDescription,
        shoppingList: shopingList?.id,
        week: week?.map((item, index) => ({
          ...item,
          meal: item?.meal?.map((el) => ({
            order: isAdd30mins ? el?.order : el?.order - 1,
            recipes: el?.recipes?.map((r) => r?.id),
            title: el?.title,
          })),
        })),
        prepLists: [prepList],
      };
      // console.log("body", body);
      let url = id ? `meal-plans/${id}` : "meal-plans";
      let method = id ? "PUT" : "POST";
      const res = await fetchRequest(url, method, body);

      if (res?.success) {
        setId(res?.data?.id);
        updatedShown();
        // navigation("/mealplans/manage");
      } else {
        alert(res?.message || "Something went wrong");
      }
      setIsLoadingCreateMealPlan(false);
    } catch (e) {
      console.log("e", e);
      setIsLoadingCreateMealPlan(false);
    }
  };

  const deleteModal = useToggleModal();
  const [deleteItemIndex, setDeleteItemIndex] = useState(null);
  const onDeleteMeal = useCallback(() => {
    if (deleteItemIndex == null) return;
    const filterWeek = week?.map((w, index) => ({
      ...w,
      meal:
        index == selectedWeek
          ? w.meal.filter((m, meal_index) => meal_index != deleteItemIndex)
          : w.meal,
    }));
    setWeek(filterWeek);
    deleteModal.closeModal();
    setDeleteItemIndex(null);
    // console.log({ week, filterWeek });
  }, [deleteItemIndex, week, selectedWeek]);

  useEffect(() => {
    console.log(
      "!!!!",
      week,
      selectedWeek,
      week[selectedWeek].beverages.enabled
    );
  }, [week, selectedWeek]);

  return (
    <>
      <AddRecipeModal
        isOpen={isOpenAddRecipeModal}
        setIsOpen={setIsOpenAddRecipeModal}
        selectedWeek={selectedWeek}
        selectedMeal={selectedMeal}
        setWeek={setWeek}
        week={week}
      />
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Create New Meal Plan</h1>
        <div className={styles.headerInputWrapper}>
          <Input
            value={name}
            onChange={onChangeName}
            label={"Meal Plan Name"}
            placeholder={"Meal Plan Name"}
            errorBorder={titleError}
          />
          <div style={{ height: 20 }} />
          <RichTextEditor
            label={"Short Description"}
            initialValue={shortDescription}
            setValue={setShortDescription}
          />
          <div style={{ height: 40 }} />
          {/* <TextAreaEditor
            value={shortDescription}
            onChange={onChangeShortDescription}
            label={"Short Description"}
            placeholder={"Short Description"}
            classes={styles.textarea}
            borderError={descriptionError}
          /> */}
          <ShoppingListSelector value={shopingList} setValue={setShopingList} />
          {/* <InputSelect
            value={shopingList}
            onChange={setShopingList}
            // data={shopingsList}
            label={"Shopping List"}
          /> */}
        </div>
        <div className={styles.daysContainer}>
          {week.map((item, index) => (
            <DayOfWeekItem
              key={index}
              type={index === selectedWeek ? "active" : getStatusDay(index)}
              onClick={() => setSelectedWeek(index)}
              title={item?.title}
            />
          ))}
        </div>
        {/* <div className={styles.selectContainer}>
          <p>30 mins before every meal</p>
          <Switch
            isChecked={isAdd30mins}
            setIsChecked={() => {
              if (isAdd30mins) {
                const copy = [...week];
                const array = copy.map((item, index) => ({
                  ...item,
                  meal: item?.meal?.slice(1),
                }));
                setWeek(array);
              } else {
                const copy = [...week];
                const array = copy.map((item, index) => ({
                  ...item,
                  meal: [
                    {
                      order: 0,
                      title: "30 mins before every meal",
                      recipes: [],
                      info: "",
                    },
                    ...item?.meal,
                  ],
                }));
                setWeek(array);
              }
              setIsAdd30mins((prev) => !prev);
            }}
          />
        </div>
        <div className={styles.selectContainer}>
          <p>MEAL ADD ONS Up to 2 PER DAY</p>
          <Switch
            isChecked={isMealPerDay}
            setIsChecked={() => {
              if (isMealPerDay) {
                const copy = [...week];
                const array = copy.map((item, index) => ({
                  ...item,
                  meal: item?.meal?.slice(0, -1),
                }));
                setWeek(array);
              } else {
                const copy = [...week];
                const array = copy.map((item, index) => ({
                  ...item,
                  meal: [
                    ...item?.meal,
                    {
                      order: 4,
                      title: "MEAL ADD ONS Up to 2 PER DAY",
                      recipes: [],
                      info: "",
                    },
                  ],
                }));
                setWeek(array);
              }
              setIsMealPerDay((prev) => !prev);
            }}
          />
        </div> */}
        <div className={styles.addMealBtnWrapper}>
          <div
            onClick={() => {
              const copy = [...week];
              const array = copy.map((item, index) => ({
                ...item,
                meal: [
                  {
                    order: 0,
                    title: "Meal Title",
                    recipes: [],
                    info: "",
                  },
                  ...item?.meal,
                ],
              }));
              setWeek(array);
            }}
            className={styles.addMealBtn}
          >
            <PlusIcon />
            <span>Add Meal</span>
          </div>
        </div>
        {week[selectedWeek]?.meal?.map((item, index) => {
          return (
            <div key={index} className={styles.launchItem}>
              <div className={styles.header}>
                <Input
                  value={item?.title}
                  onChange={(e) => onChangeMealName(e, index, selectedWeek)}
                  placeholder={"Meal Name"}
                  errorBorder={""}
                  classesInner={styles.headerTitleInput}
                />
                <div
                  onClick={() => {
                    setDeleteItemIndex(index);
                    deleteModal.showModal();
                  }}
                  className={styles.mealDeleteBtn}
                >
                  <Delete />
                  <span style={{ color: "#EE3758" }}>Delete</span>
                </div>
                {/* <span className={styles.headerTitle}>{item?.title}</span> */}
                <div
                  onClick={() => onSameForWholeWeek(selectedWeek, index)}
                  className={styles.sameForWeekBtn}
                >
                  <span>Same for whole week</span>
                  <ArrowDown className={styles.arrow} />
                </div>
              </div>
              <div className={styles.recipesList}>
                {item?.recipes?.map((item, i) => {
                  return (
                    <RecipeItem
                      withDelete={true}
                      deleteHandler={() => {
                        setWeek((prev) => {
                          const copy = [...prev];
                          copy[selectedWeek].meal[index].recipes = copy[
                            selectedWeek
                          ].meal[index].recipes.filter(
                            (e, index1) => index1 !== i
                          );
                          return copy;
                        });
                      }}
                      item={item}
                      key={item?.id}
                    />
                  );
                })}
              </div>
              <div className={styles.contentContainer}>
                <div className={styles.addBtnWrapper}>
                  <div
                    onClick={() => {
                      setSelectedMeal(index);
                      setIsOpenAddRecipeModal(true);
                    }}
                    className={styles.addRecipesBtn}
                  >
                    <PlusIcon />
                    <span>Add Recipes</span>
                  </div>
                </div>

                {/* <div className={styles.additionalText}>
                  <Input
                    key={`${selectedWeek}_${index}`}
                    value={item?.info}
                    onChange={(e) => onChangeInfo(e, index, selectedWeek)}
                    placeholder="Additional text info"
                  />
                </div> */}
              </div>
            </div>
          );
        })}
        <div className={styles.addMealBtnWrapper}>
          <div
            onClick={() => {
              const copy = [...week];
              const array = copy.map((item, index) => ({
                ...item,
                meal: [
                  ...item?.meal,
                  {
                    order: item?.meal?.length,
                    title: "Meal Title",
                    recipes: [],
                    info: "",
                  },
                ],
              }));
              setWeek(array);
            }}
            className={styles.addMealBtn}
          >
            <PlusIcon />
            <span>Add Meal</span>
          </div>
        </div>

        <div className={styles.selectContainer}>
          <Input
            value={week[selectedWeek]?.beverages?.title}
            onChange={(e) =>
              onChangeBeveragesAndAddOnsTitle(e, selectedWeek, "beverages")
            }
            placeholder={"BEVERAGES"}
            errorBorder={""}
            classesInner={styles.selectInput}
          />
          <Switch
            isChecked={week[selectedWeek]?.beverages?.enabled}
            setIsChecked={() => {
              onChangeBeveragesAndAddOnsEnabled(selectedWeek, "beverages");
            }}
          />
          <div
            onClick={() =>
              onSameForWholeWeekBeveragesAndAddOns(selectedWeek, "beverages")
            }
            className={styles.sameForWeekBtn}
            style={{
              marginLeft: "auto",
            }}
          >
            <span>Same for whole week</span>
            <ArrowDown className={styles.arrow} />
          </div>
        </div>
        {week[selectedWeek]?.beverages?.enabled && (
          <div className={styles.mealRichTextWrapper}>
            <RichTextEditor
              key={`richText-beverages-${selectedWeek}`}
              initialValue={week[selectedWeek]?.beverages?.desc}
              setValue={(e) =>
                onChangeBeveragesAndAddOnsText(e, selectedWeek, "beverages")
              }
            />
          </div>
        )}
        <div className={styles.selectContainer}>
          <Input
            value={week[selectedWeek]?.addOn?.title}
            onChange={(e) =>
              onChangeBeveragesAndAddOnsTitle(e, selectedWeek, "addOn")
            }
            placeholder={"MEAL ADD ONS"}
            errorBorder={""}
            classesInner={styles.selectInput}
          />
          <Switch
            isChecked={week[selectedWeek]?.addOn?.enabled}
            setIsChecked={() => {
              onChangeBeveragesAndAddOnsEnabled(selectedWeek, "addOn");
            }}
          />
          <div
            onClick={() =>
              onSameForWholeWeekBeveragesAndAddOns(selectedWeek, "addOn")
            }
            className={styles.sameForWeekBtn}
            style={{
              marginLeft: "auto",
            }}
          >
            <span>Same for whole week</span>
            <ArrowDown className={styles.arrow} />
          </div>
        </div>
        {week[selectedWeek]?.addOn?.enabled && (
          <div className={styles.mealRichTextWrapper}>
            <RichTextEditor
              key={`richText-addOn-${selectedWeek}`}
              initialValue={week[selectedWeek]?.addOn?.desc}
              setValue={(e) =>
                onChangeBeveragesAndAddOnsText(e, selectedWeek, "addOn")
              }
            />
          </div>
        )}
        <div className={styles.selectContainer}>
          <Input
            value={week[selectedWeek]?.addOnSecond?.title}
            onChange={(e) =>
              onChangeBeveragesAndAddOnsTitle(e, selectedWeek, "addOnSecond")
            }
            placeholder={"MEAL ADD ONS"}
            errorBorder={""}
            classesInner={styles.selectInput}
          />
          <Switch
            isChecked={week[selectedWeek]?.addOnSecond?.enabled}
            setIsChecked={() => {
              onChangeBeveragesAndAddOnsEnabled(selectedWeek, "addOnSecond");
            }}
          />
          <div
            onClick={() =>
              onSameForWholeWeekBeveragesAndAddOns(selectedWeek, "addOnSecond")
            }
            className={styles.sameForWeekBtn}
            style={{
              marginLeft: "auto",
            }}
          >
            <span>Same for whole week</span>
            <ArrowDown className={styles.arrow} />
          </div>
        </div>
        {week[selectedWeek]?.addOnSecond?.enabled && (
          <div className={styles.mealRichTextWrapper}>
            <RichTextEditor
              key={`richText-addOnSecond-${selectedWeek}`}
              initialValue={week[selectedWeek]?.addOnSecond?.desc}
              setValue={(e) =>
                onChangeBeveragesAndAddOnsText(e, selectedWeek, "addOnSecond")
              }
            />
          </div>
        )}

        <div className={styles.selectContainer}>
          <Input
            value={prepList.title}
            onChange={(e) => onChagePrepListTitle(e)}
            placeholder={"Week 1 + 3 PREP LIST"}
            errorBorder={""}
            classesInner={styles.selectInput}
          />
        </div>
        <div className={styles.mealRichTextWrapper}>
          <RichTextEditor
            key={`richText-prepList1`}
            initialValue={prepList.description}
            setValue={(e) => onChagePrepListText(e)}
          />
        </div>
        {/* <div className={styles.selectContainer}>
          <Input
            value={prepListSecond.title}
            onChange={(e) => onChagePrepListSecondTitle(e)}
            placeholder={"Week 2 + 4 PREP LIST"}
            errorBorder={""}
            classesInner={styles.selectInput}
          />
        </div>
        <div className={styles.mealRichTextWrapper}>
          <RichTextEditor
            key={`richText-PrepList2`}
            initialValue={prepListSecond.description}
            setValue={(e) => onChagePrepListSecondText(e)}
          />
        </div> */}

        <div className={styles.footer}>
          <Button title={"Cancel"} classes={styles.cancelBtn} />
          <Button
            onClick={() => onCreateMealPlans()}
            loading={isLoadingCreateMealPlan}
            title={"Save"}
            classes={styles.saveBtn}
          />
          {/* <Button
            title={"Save & Create Another One"}
            classes={styles.createBtn}
          /> */}
        </div>
      </div>
      {savedModal.isShow && <TopModal title="Sucessfully saved" />}
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={"Are you sure you want to delete?"}
          onClick={onDeleteMeal}
          // loading={isLoadingDelete}
        />
      )}
    </>
  );
};

export default CreateMealPlan;
