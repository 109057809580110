import React, { useRef, useState } from "react";
import clsx from "clsx";
//styles
import styles from "./styles.module.scss";

import { Arrow } from "assets";
import { useOutsideClick } from "hooks";

const InputSelect = ({
  label,
  placeholder = "",
  type = "text",
  classes,
  classesInner,
  error,
  id,
  name,
  onChange,
  value,
  errorBorder = false,
  disabled = false,
  data = [],
  search = null,
  onListToggle = null,
}) => {
  const [isOpenList, setIsOpenList] = useState(false);

  const ref = useRef(null);
  useOutsideClick(ref, () => setIsOpenList(false));

  return (
    <div className={clsx(styles.input, classesInner)} ref={ref}>
      {label && <h4>{label}</h4>}
      <div
        onClick={() => {
          setIsOpenList((prev) => !prev);
          !!onListToggle && onListToggle();
        }}
        className={clsx(styles.input__main, classes, {
          [styles.error]: error,
          [styles.borderError]: errorBorder,
        })}
      >
        <span
          style={{ color: value ? "#000" : "#afafaf" }}
          className={styles.value}
        >
          {value ? value : placeholder}
        </span>
        <Arrow
          className={clsx(styles.arrow, { [styles.openArrow]: isOpenList })}
        />
      </div>
      {isOpenList && (
        <div
          className={styles.dataListWrapper}
          style={{ top: label ? "90px" : "62px" }}
        >
          {!!search && (
            <div className={styles.searchInputWrapper}>
              <input
                value={search?.value}
                onChange={search?.onChange}
                className={styles.searchInput}
                placeholder={search?.placeholder || "Search..."}
              />
            </div>
          )}
          <div className={styles.dataList}>
            {data.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  onChange(item);
                  setIsOpenList(false);
                }}
                className={styles.dataItem}
              >
                {item?.text || item}
              </div>
            ))}
          </div>
        </div>
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default InputSelect;
