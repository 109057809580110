export const convertOztoLitr = (value) => {
  return +(value * 0.02957).toFixed(1);
};

export const convertLitrToOz = (value) => {
  return +(value * 33.81402).toFixed(1);
};

export const convertIbsToKg = (value) => {
  return +(value * 0.45359237).toFixed(1);
};

export const convertKgToIbs = (value) => {
  return +(value * 2.2046226218).toFixed(1);
};

export const convertInToCm = (value) => {
  return +(value * 2.54).toFixed(1);
};

export const convertCmtoIn = (value) => {
  return +(value * 0.3937007874).toFixed(1);
};
