import { fetchRequest } from "utils";

export const uploadFile = async (file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);

    fetchRequest("upload", "POST", formData, true)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const deleteFile = async (file) => {
  return new Promise((resolve, reject) => {
    const body = { url: file };
    fetchRequest("upload", "DELETE", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
