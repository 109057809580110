import React from "react";
import styles from "./styles.module.scss";

const CreateBoostCampHeader = (props) => {
  let stepNumber = props.numbersOfStep;

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.headerTitle}>
        <h1>Create Boost Camp</h1>

        {props?.totalPoints && (
          <div className={styles.totalPoints}>
            Total points: <span>{props?.totalPoints || 0}</span>
          </div>
        )}
      </div>

      <div className={styles.headerSteps}>
        <div className={styles.text}>
          <div className={styles.boldText}>Step {stepNumber}</div>
          <div className={styles.grayText}>
            <div className={styles.slash}>/</div>
            <div style={{ marginTop: "2px" }}>2</div>
          </div>
        </div>

        <div className={styles.lines}>
          <div className={styles.activeLine}></div>
          <div
            className={
              stepNumber === 1 ? styles.inActiveLine : styles.activeLine
            }
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CreateBoostCampHeader;
