//react
import React, { useState } from "react";

//assets
import { NotificationBoostcamp, Delete } from "assets";

//styles
import styles from "./styles.module.scss";
import moment from "moment";
import { useToggleModal } from "hooks";
import { DeleteModal } from "modals";

const NotificationItem = ({
  item,
  onDelete = false,
  isLoadingDelete = false,
}) => {
  const deleteModal = useToggleModal();

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <NotificationBoostcamp />
          <div className={styles.title}>Boost camp</div>
          <div className={styles.date}>
            · {moment(item?.updatedAt).format("MM.DD.YYYY")}
          </div>
          <div style={{ marginLeft: "auto" }} className={styles.title}>
            {item?.category}
          </div>
        </div>
        <div
          className={styles.bodyTitle}
          dangerouslySetInnerHTML={{
            __html: item?.title || "",
          }}
        />
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{
            __html: item?.text || "",
          }}
        />
      </div>
      {onDelete && (
        <div onClick={deleteModal.showModal} className={styles.deleteBtn}>
          <Delete />
        </div>
      )}
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={"Are you sure you want to delete?"}
          onClick={() => {
            onDelete();
          }}
          loading={isLoadingDelete}
        />
      )}
    </div>
  );
};

export default NotificationItem;
