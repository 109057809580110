import React, { useState, useEffect, useCallback } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { Arrow, Delete, ImgPlaceholder, PlusIcon } from 'assets';
import { Spinner } from 'react-activity';
import { uploadFile, deleteFile } from 'services/file.service';
import {
  Button,
  Input,
  InputSelect,
  RecipeIngridientsList,
  RichTextEditor,
  SearchIngredients,
  TagSelector,
} from 'components';
import { cookHours, cookMinutes } from 'consts';
import { fetchRequest } from 'utils';
import { useNavigate, useParams } from 'react-router-dom';
import { RecipePreview } from 'components';
import { format } from 'date-fns';

const EditRecipe = () => {
  const [preview, setPreview] = useState();
  const [pictureError, setPictureError] = useState(false);
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [isLoadingDeleteFile, setIsLoadingDeleteFile] = useState(false);
  const [cookTimeHours, setCookTimeHours] = useState('0 h');
  const [cookTimeMinutes, setCookTimeMinutes] = useState('5 m');
  const [ingredients, setIngredients] = useState([]);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [isOpenSearchIngredient, setIsOpenSearchIngredient] = useState(false);
  const [allIngredients, setAllIngredients] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [allTagsLoader, setAllTagsLoader] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [allUnits, setAllUnits] = useState([]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [previewError, setPreviewError] = useState(false);
  const [tagsError, setTagsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();

  const params = useParams();

  const onGetRecipe = async () => {
    try {
      setIsLoading(true);
      const res = await fetchRequest(`recipes/${params?.id}`, 'GET');
      if (res.success) {
        setTitle(res?.data?.title);
        setCookTimeHours(`${res?.data?.cookTime?.hours || 0} h`);
        setCookTimeMinutes(`${res?.data?.cookTime?.minutes || 0} m`);
        setPreview(res?.data?.image);
        setDescription(res?.data?.preparation);
        setSelectedTags(res?.data?.tags);
        setIngredients(res?.data?.ingredients);
        setDate(res?.data?.date || new Date());
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onGetAllTags = async () => {
    try {
      setAllTagsLoader(true);
      const res = await fetchRequest('tags', 'GET');
      if (res?.success) {
        setAllTags(res?.data);
      }
      setAllTagsLoader(false);
    } catch (error) {
      setAllTagsLoader(false);
    }
  };

  const onGetAllUnits = async (q = '') => {
    try {
      let url = q ? `units?q=${q}` : 'units';
      const res = await fetchRequest(url, 'GET');
      if (res?.success) {
        setAllUnits(res?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    onGetAllTags();
    onGetAllUnits();
  }, []);

  const onDeleteIngredient = (index) => {
    const copy = [...ingredients];
    copy.splice(index, 1);
    setIngredients(copy);
  };

  const onChangeTitle = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const onChangeIngredientAmount = useCallback((e, index, i) => {
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].units[i].amount = e.target.value;
      return copy;
    });
  }, []);

  const onChangeIngredientUnit = useCallback((item, index, i) => {
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].units[i].unit = item;
      return copy;
    });
  }, []);

  const onAddNewUnit = useCallback((index) => {
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].units?.push({
        amount: 1,
        unit: copy[index]?.units[copy[index]?.units?.length - 1]?.unit,
      });
      return copy;
    });
  });

  const onDeleteNewUnit = useCallback((index, i) => {
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].units?.splice(i, 1);
      return copy;
    });
  });

  const onSelectFile = async (e) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setIsLoadingUploadFile(true);
      const res = await uploadFile(e.target.files[0]);
      setPreview(res?.data?.url);
      setIsLoadingUploadFile(false);
    } catch (error) {
      setIsLoadingUploadFile(false);

      console.log(error);
    }
  };

  const onDeleteFile = async (e) => {
    try {
      setIsLoadingDeleteFile(true);
      const res = await deleteFile(preview);
      setPreview(undefined);
      setIsLoadingDeleteFile(false);
    } catch (e) {
      setIsLoadingDeleteFile(false);

      console.log(e);
    }
  };

  const onCreateRecipes = async () => {
    try {
      if (!title) {
        setTitleError(true);
        return;
      } else {
        setTitleError(false);
      }
      if (!preview) {
        setPreviewError(true);
        return;
      } else {
        setPictureError(false);
      }
      if (selectedTags?.length === 0) {
        setTagsError(true);
        return;
      } else {
        setTagsError(false);
      }
      setIsSaveLoading(true);

      const body = {
        title,
        cookTime: {
          hours: cookTimeHours.slice(0, 2).trim(),
          minutes: cookTimeMinutes.slice(0, 2).trim(),
        },
        image: preview,
        ingredients: ingredients.map((item) => {
          const ingr = {
            units: item?.units?.map((el) => ({
              unit: el?.unit?.id,
              amount: el?.amount,
            })),
            ingredient: item.ingredient.id,
          };

          if (!!item?.hyperLink) ingr.hyperLink = item.hyperLink;
          return ingr;
        }),
        date,
        preparation: description,
        tags: selectedTags.map((item) => item.id),
      };
      console.log('body', body);
      const res = await fetchRequest(`recipes/${params?.id}`, 'PUT', body);
      if (res?.success) {
        navigate('/recipes/manage');
      }
      setIsSaveLoading(false);
    } catch (error) {
      setIsSaveLoading(false);
    }
  };

  useEffect(() => {
    onGetRecipe();
  }, []);

  const onSelectIngredient = (item) => {
    setIngredients((prev) => {
      return [
        ...prev,
        {
          units: [
            {
              amount: '1',
              unit: allUnits?.length > 0 ? allUnits[0] : { text: '', id: '' },
            },
          ],
          ingredient: item,
        },
      ];
    });
  };

  const onChangeDate = (e) => {
    setDate(e.target.value);
  };

  const onChangeIngredientLink = useCallback((e, index) => {
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].hyperLink = e.target.value;
      return copy;
    });
  }, []);

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Edit New Recipe</h1>
        <div className={styles.loaderWrapper}>
          <Spinner size={30} color={'#8c42e3'} />
        </div>
      </div>
    );
  }

  return (
    <>
      <RecipePreview
        isOpen={isOpenPreview}
        setIsOpen={setIsOpenPreview}
        preview={preview || ''}
        title={title}
        ingredients={ingredients}
        tags={selectedTags}
        description={description}
        cookTimeHours={cookTimeHours}
        cookTimeMinutes={cookTimeMinutes}
        date={date}
      />
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Edit New Recipe</h1>
        <div className={styles.header}>
          <div className={styles.infoBlock}>
            <div className={styles.titleBlock}>
              <div className={styles.titleBlockLabel}>Recipe Title</div>
              <div className={styles.titleBlockLabelCook}>Date</div>
            </div>
            <div className={styles.titleInputBlock}>
              <Input
                value={title}
                onChange={onChangeTitle}
                placeholder={'Title'}
                classesInner={styles.titleInputBlockTitle}
                errorBorder={titleError}
              />
              <div className={styles.titleInputBlockCook}>
                <Input
                  value={format(new Date(date), 'yyyy-MM-dd')}
                  placeholder={'Date'}
                  onChange={onChangeDate}
                  type={'date'}
                />
              </div>
            </div>
            <div className={styles.ingredientsBlock}>
              <span className={styles.title}>Ingredients</span>
              {ingredients?.length > 0 && (
                <div className={styles.titleBlock}>
                  <div className={styles.ingredientTitle}>Ingredient</div>
                  <div className={styles.amountTitle}>Amount</div>
                  <div className={styles.unitTitle}>Unit</div>
                  <div style={{ width: '40px' }} />
                </div>
              )}

              <RecipeIngridientsList
                ingredients={ingredients}
                setIngredients={setIngredients}
                onChangeIngredientAmount={onChangeIngredientAmount}
                onDeleteIngredient={onDeleteIngredient}
                onDeleteNewUnit={onDeleteNewUnit}
                onAddNewUnit={onAddNewUnit}
                onChangeIngredientUnit={onChangeIngredientUnit}
                onGetAllUnits={onGetAllUnits}
                allUnits={allUnits}
                onChangeIngredientLink={onChangeIngredientLink}
              />
              <div style={{ position: 'relative', marginBottom: '20px' }}>
                <div
                  onClick={() => setIsOpenSearchIngredient((prev) => !prev)}
                  className={styles.addIngredient}
                >
                  <PlusIcon />
                  <span>Add Ingredient</span>
                </div>
                {isOpenSearchIngredient && (
                  <SearchIngredients
                    setIsOpen={setIsOpenSearchIngredient}
                    onSelect={onSelectIngredient}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.imageBlock}>
            {preview ? (
              <img
                src={preview}
                className={clsx(styles.recipePhoto, {
                  [styles.recipePhotoError]: previewError,
                })}
                alt={'photo'}
              />
            ) : (
              <div
                className={clsx(styles.recipePhoto, {
                  [styles.recipePhotoError]: previewError,
                })}
              >
                <ImgPlaceholder />
              </div>
            )}
            <div className={styles.flex}>
              <label
                htmlFor={'photoInput'}
                className={styles.recipeUploadImgWrapper}
              >
                {isLoadingUploadFile ? (
                  <span>
                    <Spinner size={12} />
                  </span>
                ) : (
                  'Upload New'
                )}
                <input
                  id={'photoInput'}
                  type={'file'}
                  accept='image/*'
                  onChange={onSelectFile}
                  onClick={(e) => (e.target.value = null)}
                  className={styles.recipeUploadImgBtn}
                />
              </label>
              <div onClick={onDeleteFile} className={styles.recipeDeleteImgBtn}>
                {isLoadingDeleteFile ? (
                  <span>
                    <Spinner size={12} />
                  </span>
                ) : (
                  'Delete photo'
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.preparationBlock}>
          <div className={styles.title}>Preparation</div>
          <RichTextEditor
            initialValue={description}
            setValue={setDescription}
            // label={"Short description"}
          />
        </div>
        <div className={styles.tagsBlock}>
          <div className={styles.title}>Tags</div>

          <div
            className={clsx(styles.tagsContainer, {
              [styles.tagsContainerError]: tagsError,
            })}
          >
            {allTagsLoader && (
              <div className={styles.tagsLoader}>
                <Spinner color={'#8C42E3'} size={25} />
              </div>
            )}
            {!allTagsLoader &&
              allTags.map((item, index) => (
                <TagSelector
                  item={item}
                  isSelect={selectedTags.some((el) => el?.id === item?.id)}
                  onSelect={setSelectedTags}
                  key={index}
                />
              ))}
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            onClick={() => navigate('/recipes/manage')}
            title={'Cancel'}
            emptyStyle
            classes={styles.cancelBtn}
          />
          <Button
            title={'Save'}
            loading={isSaveLoading}
            onClick={onCreateRecipes}
            classes={styles.saveBtn}
          />
          <Button
            onClick={() => setIsOpenPreview(true)}
            title={'Preview'}
            classes={styles.previewBtn}
          />
          {/* <Button
            title={"Save & Create Another One"}
            classes={styles.saveOrCreateBtn}
            rightIcon={() => <Arrow className={styles.arrow} />}
          /> */}
        </div>
      </div>
    </>
  );
};

export default EditRecipe;
