//react
import React, { useCallback, useEffect, useState, useRef } from "react";

//layouts
import { Manage } from "layouts";
import { motion } from "framer-motion";
//components
import {
  Header,
  Input,
  TextAreaEditor,
  Button,
  NotificationItem,
  TextAreaResizableEditor,
} from "components";
import Select from "react-select";
//styles
import styles from "./styles.module.scss";
import { fetchRequest } from "utils";
import { Spinner } from "react-activity";
import { AddLinkIcon, ArrowUp } from "assets";
import clsx from "clsx";
import { useOutsideClick } from "hooks";

const Notifications = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [category, setCategory] = useState({
    label: "All users",
    value: "all",
  });
  const [categoriesOptions, setCategoriesOptions] = useState([
    { label: "All users", value: "all" },
    { label: "Finished the first boost camp", value: "passFirst" },
    { label: "Finished two boost camps", value: "passSecond" },
  ]);
  const [linkName, setLinkName] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [notifications, setNotification] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [isOpenLinkMenu, setIsOpenLinkMenu] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const limit = 30;

  //errorsState
  const [titleError, setTitleError] = useState(false);
  const [bodyError, setBodyError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);

  const [isLoadingSendNotification, setIsLoadingSendNotification] =
    useState(false);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [boostcampLoading, setBoostcampLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const linkMenuRef = useRef(null);

  const addLink = async () => {
    let link = `<a href="${linkUrl}">${linkName}</a>`;
    setLinkName("");
    setLinkUrl("");
    setIsOpenLinkMenu(false);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(link);
    } else {
      return document.execCommand("copy", true, link);
    }
  };
  useOutsideClick(linkMenuRef, () => {
    setIsOpenLinkMenu(false);
    setIsOpenPreview(false);
  });

  const onChangeTitle = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const onChangeBody = useCallback((e) => {
    setBody(e.target.value);
  }, []);

  const onChangeCategory = useCallback((e) => {
    setCategory(e);
  }, []);

  const onSendNotification = async () => {
    try {
      if (!title) {
        setTitleError(true);
        return;
      } else {
        setTitleError(false);
      }
      if (!body) {
        setBodyError(true);
        return;
      } else {
        setBodyError(false);
      }
      if (!category) {
        setCategoryError(true);
        return;
      } else {
        setCategoryError(false);
      }
      setIsLoadingSendNotification(true);

      const data = {
        title,
        text: body,
        category: category.value,
      };
      const res = await fetchRequest("notifications", "POST", data);
      if (res?.status === 201) {
        onGetNotifications(1);
        setTitle("");
        setBody("");
      }

      setIsLoadingSendNotification(false);
    } catch (error) {
      setIsLoadingSendNotification(false);
      console.log(error);
    }
  };

  const onGetNotifications = async (page) => {
    try {
      setIsLoading(true);
      page > 1 && setIsLoadingPagination(true);
      const res = await fetchRequest(
        `notifications?page=${page}&limit=${limit}&sort=-createdAt`,
        "GET"
      );
      if (res?.status === 200) {
        if (page === 1) {
          setPage(1);
          setLastPage(Math.ceil(res.data.count / limit));

          setNotification((prev) => [...res.data.rows]);
        } else {
          setLastPage(Math.ceil(res.data.count / limit));

          setNotification((prev) => [...prev, ...res.data.rows]);
        }
      }
      setIsLoading(false);
      setIsLoadingPagination(false);
    } catch (error) {
      setIsLoading(false);
      setIsLoadingPagination(false);

      console.log(error);
    }
  };

  const onGetBoostCamps = async (page) => {
    try {
      setBoostcampLoading(true);
      const response = await fetchRequest(
        `boost-camps/?page=${1}&limit=${30}&sort=+createdAt`,
        "GET"
      );

      if (response?.status === 200) {
        if (response?.data?.rows?.length >= 2) {
          setCategoriesOptions((prev) => {
            const copy = [...prev];
            copy[1].label = `Finished the "${response?.data?.rows[0].title}" boostcamp`;
            copy[2].label = `Finished the "${response?.data?.rows[1].title}" boostcamp`;
            return copy;
          });
        }
      }
      setBoostcampLoading(false);
    } catch (error) {
      setBoostcampLoading(false);
      console.log(error);
    }
  };

  const onDeleteNotification = async (id) => {
    try {
      setIsLoadingDelete(true);
      const res = await fetchRequest(`notifications/${id}`, "DELETE");
      onGetNotifications(1);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const incrementPage = () => {
    if (notifications && page < lastPage) {
      onGetNotifications(page + 1);

      setPage(page + 1);
    }
  };

  const handleScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1;
    if (bottom) {
      incrementPage();
    }
  };

  useEffect(() => {
    onGetNotifications(1);
    onGetBoostCamps();
  }, []);

  if (boostcampLoading) {
    return (
      <Manage>
        <Header title={"Notifications"} />
        <div className={styles.loaderWrap}>
          <Spinner color={"#8C42E3"} size={40} />
        </div>
      </Manage>
    );
  }

  return (
    <Manage>
      <Header title={"Notifications"} />
      <div className={styles.contentContainer}>
        <div className={styles.leftContentContainer}>
          <div className={styles.leftWrapper}>
            <TextAreaResizableEditor
              value={title}
              onChange={onChangeTitle}
              placeholder={"Before Bed Routines"}
              label={"Notification title"}
              errorBorder={titleError}
            />
            <div ref={linkMenuRef} className={styles.addLinkWrapper}>
              <div
                onClick={() => {
                  setIsOpenPreview((prev) => !prev);
                  setIsOpenLinkMenu(false);
                }}
                className={styles.addLinkBtn}
                style={{ marginRight: "10px" }}
              >
                Preview
              </div>
              {isOpenPreview && (
                <motion.div
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                  className={styles.previewWrapper}
                >
                  <NotificationItem
                    item={{
                      title: title,
                      text: body,
                      category: category?.value,
                      updatedAt: new Date(),
                    }}
                  />
                </motion.div>
              )}
              <div
                onClick={() => {
                  setIsOpenLinkMenu(true);
                  setIsOpenPreview(false);
                }}
                className={styles.addLinkBtn}
              >
                <AddLinkIcon style={{ color: "#8C42E3" }} />
                Add Link
              </div>
              {isOpenLinkMenu && (
                <motion.div
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                  className={styles.addLinkInputWrapper}
                >
                  <input
                    value={linkName}
                    onChange={(e) => setLinkName(e.target.value)}
                    placeholder={"Link name"}
                    className={styles.linkInput}
                  />
                  <input
                    value={linkUrl}
                    placeholder={"Link url"}
                    onChange={(e) => setLinkUrl(e.target.value)}
                    className={styles.linkInput}
                  />
                  <div onClick={addLink} className={styles.save}>
                    Copy
                  </div>
                </motion.div>
              )}
              {isCopied && (
                <motion.div
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                  className={styles.copiedWrapper}
                >
                  Copied
                </motion.div>
              )}
            </div>
            <TextAreaEditor
              label={"Notifications text"}
              placeholder={"Sleep meditation  song for your relax"}
              value={body}
              onChange={onChangeBody}
              borderError={bodyError}
            />

            {/* <Select
              options={categoriesOptions}
              onChange={(values) => onChangeCategory(values)}
              placeholder={"Category"}
              styles={customStyles}
            /> */}

            <div
              onClick={() => setIsDropDownOpen((prev) => !prev)}
              className={clsx(styles.dropDown, {
                [styles.dropDownActive]: isDropDownOpen,
              })}
            >
              <p className={styles.dropDownTitle}>{category?.label}</p>
              <div
                className={clsx(styles.arrowWrapper, {
                  [styles.arrowWrapperActive]: isDropDownOpen,
                })}
              >
                <ArrowUp />
              </div>
              {isDropDownOpen && (
                <div className={styles.dropDownList}>
                  {categoriesOptions.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => setCategory(item)}
                      className={styles.dropDownItem}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* <Input
              value={category}
              onChange={onChangeCategory}
              placeholder={"Passed the first boost camp"}
              label={"Show to: category"}
              errorBorder={categoryError}
              disabled={true}
            /> */}
            <div className={styles.sendBtnWrapper}>
              <Button
                onClick={onSendNotification}
                loading={isLoadingSendNotification}
                title={"Send notification"}
              />
            </div>
          </div>
        </div>
        <div onScroll={handleScroll} className={styles.rightContentContainer}>
          {notifications.map((item, index) => (
            <NotificationItem
              key={index}
              item={item}
              onDelete={() => onDeleteNotification(item?.id)}
              isLoadingDelete={isLoadingDelete}
            />
          ))}
          {isLoadingPagination && (
            <div className={styles.loaderWrapper}>
              <Spinner color={"#8C42E3"} size={15} />
            </div>
          )}
        </div>
      </div>
    </Manage>
  );
};

export default Notifications;
