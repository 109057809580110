import styles from "./styles.module.scss";

import { FireIcon, MinusIcon, PlusIcon } from "assets";

const DiscountPoints = ({ points = 0, maxPoints = 0 }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.iconWrapper}>
          <FireIcon />
        </div>
        <h5>Discount Points</h5>
      </div>
      <div className={styles.info}>
        {/* <MinusIcon
          onClick={() => {}}
          className={styles.minus}
          width={16}
          height={16}
        /> */}
        <div className={styles.line}>
          <div
            className={styles.lineInner}
            style={{ width: `${Math.floor((points / maxPoints) * 100)}%` }}
          />
          <div className={styles.lineTitle}>
            {points} / {maxPoints}
          </div>
        </div>
        {/* <PlusIcon
          onClick={() => {}}
          className={styles.plus}
          width={16}
          height={16}
        /> */}
      </div>
    </div>
  );
};

export default DiscountPoints;
