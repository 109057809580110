//react
import React from "react";

//styles
import styles from "./styles.module.scss";
import clsx from "clsx";

const TextAreaEditor = ({
  label,
  value,
  onChange,
  classes,
  classesInner,
  error,
  placeholder = "Type your message",
  borderError = false,
}) => {
  return (
    <div className={clsx(styles.input, classesInner)}>
      {label && <h4>{label}</h4>}
      <textarea
        className={clsx(styles.input__main, classes, {
          [styles.error]: error,
          [styles.borderError]: borderError,
        })}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
    </div>
  );
};

export default TextAreaEditor;
