import React from "react";
import clsx from "clsx";
// libs
import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";
//styles
import styles from "./styles.module.scss";

const Button = ({
  title,
  onClick,
  disabled,
  classes,
  emptyStyle,
  darkStyle,
  type,
  loading,
  icon = false,
  rightIcon = false,
}) => {
  return (
    <button
      className={clsx(
        styles.button,
        {
          [styles.disabled]: disabled,
          [styles.emptyStyle]: emptyStyle,
          [styles.darkStyle]: darkStyle,
        },
        classes
      )}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
    >
      {icon && <div className={styles.iconWrapper}>{icon()}</div>}
      {loading ? <Spinner size={15} /> : title}
      {rightIcon && <div className={styles.iconWrapper}>{rightIcon()}</div>}
    </button>
  );
};

export default Button;
