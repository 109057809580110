import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { ChecklistIcon } from "assets";
import { Button } from "components";
import styles from "./styles.module.scss";
import "rc-slider/assets/index.css";
import clsx from "clsx";
import TrackersInput from "../TrackersInput";
import { convertIbsToKg, convertKgToIbs } from "utils";

const WeightTracker = ({ isOpen, setIsOpen, currentDay, setCurrentDay }) => {
  const [activeType, setActiveType] = useState(0);
  const [value, setValue] = useState("0");
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onChangeWeight = async () => {
    try {
      setIsLoadingSubmit(true);
      if (currentDay?.trackers) {
        const body = {
          ...currentDay,
          trackers: {
            ...currentDay?.trackers,
            weight: {
              kg:
                activeType === 1
                  ? +Number(value).toFixed(1)
                  : convertIbsToKg(+value),
              ibs:
                activeType === 0
                  ? +Number(value).toFixed(1)
                  : convertKgToIbs(+value),
            },
          },
        };
        setCurrentDay(body);
        onClose();
      }
      setIsLoadingSubmit(false);
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (currentDay) {
      setValue(`${currentDay?.trackers?.weight?.kg || 0}`);
      setActiveType(1);
    }
  }, [currentDay]);

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          borderRadius: "30px",
          padding: "25px",
          outline: "none",
          overflow: "hidden",
        },
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen={isOpen}
    >
      <div className={styles.contentBox}>
        <div className={styles.header}>
          <div className={styles.iconWrapper}>
            <ChecklistIcon />
          </div>
          <h5>
            What’s your weight
            <br /> today?
          </h5>
        </div>
        <div className={styles.sliderWrapper}>
          <div className={styles.sliderValueWrapper}>
            {/* <span>{options[activeIndex]}</span> */}
            <div className={styles.inputWrapper}>
              {/* <Input
                placeholder="10"
                type={"number"}
                value={value}
                onChange={onChangeValue}
              /> */}
              <TrackersInput value={value} setValue={setValue} />
            </div>
            <div className={styles.btnContainer}>
              <div
                onClick={() => setActiveType(0)}
                className={clsx(styles.typeBtn, {
                  [styles.typeBtnActive]: activeType === 0,
                })}
              >
                lbs
              </div>
              <div
                onClick={() => setActiveType(1)}
                className={clsx(styles.typeBtn, {
                  [styles.typeBtnActive]: activeType === 1,
                })}
              >
                kg
              </div>
            </div>
          </div>
        </div>

        <Button
          title={"SUBMIT"}
          styleType={"filled"}
          size={"small"}
          backgroundColor={"#60C055"}
          loading={isLoadingSubmit}
          onClick={onChangeWeight}
        />
      </div>
    </Modal>
  );
};

export default WeightTracker;
