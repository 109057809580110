import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Manage } from 'layouts';
import {
  Button,
  Checkbox,
  IngredientItem,
  Input,
  InputSelect,
  PageItem,
} from 'components';
import {
  PlusSquareIcon,
  SearchIcon,
  ArrowDown,
  CrossIcon,
  DotsIcon,
  PencilSmallIcon,
  Delete,
  CheckBigIcon,
} from 'assets';
import styles from './styles.module.scss';
import Modal from 'react-modal';
import { fetchRequest } from 'utils';
import { Spinner } from 'react-activity';
import { motion } from 'framer-motion';
import { useOutsideClick, useToggleModal } from 'hooks';
import { DeleteModal } from 'modals';

const CategoryItem = ({ title, onGetTags, id }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const deleteModal = useToggleModal();
  const ref = useRef(null);

  useOutsideClick(ref, () => setIsOpenMenu(false));

  const onDeleteTag = async () => {
    try {
      setIsLoadingDelete(true);
      const res = await fetchRequest(`tags/${id}`, 'DELETE');
      if (res?.status === 204) {
        onGetTags();
      }
      setIsLoadingDelete(false);
    } catch (error) {
      setIsLoadingDelete(false);
    }
  };

  const onEditTag = async () => {
    try {
      setIsLoadingUpdate(true);
      const body = {
        text: editTitle,
      };
      const res = await fetchRequest(`tags/${id}`, 'PUT', body);

      if (res?.success) {
        onGetTags();
      }
      setIsLoadingUpdate(false);
    } catch (error) {
      setIsLoadingUpdate(false);
    }
  };

  const editTitleEditHandler = useCallback((e) => {
    setEditTitle(e.target.value);
  }, []);

  if (isEditOpen) {
    return (
      <div className={styles.categoryEditItem}>
        <div
          onClick={() => {
            setIsEditOpen(false);
            setEditTitle(title);
          }}
          className={styles.categoryEditItemCancel}
        >
          <CrossIcon />
        </div>

        <input
          value={editTitle}
          onChange={editTitleEditHandler}
          className={styles.editInput}
        />
        <div onClick={onEditTag} className={styles.categoryEditItemSave}>
          {isLoadingUpdate ? (
            <Spinner color={'#8C42E3'} size={9} style={{ marginRight: 3 }} />
          ) : (
            <CheckBigIcon width={20} height={20} />
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      ref={ref}
      onClick={() => setIsOpenMenu((prev) => !prev)}
      className={styles.categoryItem}
    >
      <span className={styles.categoryTitle}>{title}</span>
      <DotsIcon width={18} height={18} color={'#8C42E3'} />
      {isOpenMenu && (
        <motion.div
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}
          className={styles.menuWrapper}
        >
          <div
            onClick={() => setIsEditOpen(true)}
            className={styles.menuButton}
          >
            <PencilSmallIcon width={19} height={19} />
            <span style={{ marginLeft: '7px' }}>Edit</span>
          </div>
          <div onClick={deleteModal.showModal} className={styles.menuButton}>
            <Delete />
            <span style={{ color: '#EE3758' }}>Delete</span>
          </div>
        </motion.div>
      )}
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={'Are you sure you want to delete?'}
          onClick={onDeleteTag}
          loading={isLoadingDelete}
        />
      )}
    </div>
  );
};

const CreateIngredient = ({ isOpen, setIsOpen, onGetTags }) => {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const onCreateTag = async () => {
    try {
      setIsLoading(true);
      const body = {
        text: name,
      };
      const res = await fetchRequest('tags', 'POST', body);
      if (res?.success) {
        onGetTags();
        setName('');
        setIsOpen(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className={styles.modal}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalInner}>
        <p className={styles.title}>New tag</p>
        <div onClick={() => setIsOpen(false)} className={styles.closeBtn}>
          <CrossIcon />
        </div>
        <Input
          value={name}
          onChange={onChangeName}
          placeholder={'Tag name'}
          classesInner={styles.category}
        />

        <Button
          title={'Create'}
          onClick={onCreateTag}
          disabled={!name}
          loading={isLoading}
        />
      </div>
    </Modal>
  );
};

const Tags = () => {
  const [search, setSearch] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isOpenNewModal, setIsOpenNewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const onChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const onGetTags = async (q = '') => {
    try {
      setIsLoading(true);
      let url = q ? `tags?q=${q}` : 'tags';
      const res = await fetchRequest(url, 'GET');
      if (res?.success) {
        setTags(res?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      onGetTags(search);
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    onGetTags();
  }, []);

  return (
    <div className={styles.wrapper}>
      <CreateIngredient
        isOpen={isOpenNewModal}
        setIsOpen={setIsOpenNewModal}
        onGetTags={onGetTags}
      />
      <div className={styles.header}>
        <h1 className={styles.title}>Tags</h1>
        <div className={styles.createMealBtnWrapper}>
          <Button
            onClick={() => setIsOpenNewModal(true)}
            title={'New Tag'}
            icon={() => <PlusSquareIcon />}
          />
        </div>
      </div>
      <Manage>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            value={search}
            onChange={onChangeSearch}
            className={styles.searchInput}
            placeholder={'Search by name...'}
          />
        </div>
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <Spinner color={'#8C42E3'} size={40} />
          </div>
        )}
        {!isLoading && tags.length > 0 && (
          <div className={styles.tagsList}>
            {tags.map((item, index) => (
              <CategoryItem
                title={item.text}
                id={item.id}
                key={item.id}
                onGetTags={onGetTags}
              />
            ))}
          </div>
        )}
      </Manage>
    </div>
  );
};

export default Tags;
