//react
import React, { useState } from "react";

//styles
import styles from "./styles.module.scss";

//libraries
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
} from "recharts";

function ControlsBtn({ isActive = false, onClick = () => {}, title = "" }) {
  return (
    <div
      onClick={onClick}
      className={isActive ? styles.activeControlsBtn : styles.contorlsBtn}
    >
      <div
        className={
          isActive ? styles.activeControlsBtnText : styles.controlsBtnText
        }
      >
        {title}
      </div>
    </div>
  );
}

function Chart({ data }) {
  const [activeType, setActiveType] = useState(0);
  const [focusBar, setFocusBar] = useState(null);
  const controls = [
    { title: "Week" },
    { title: "Month" },
    { title: "Year" },
    { title: "All Time" },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.chartsControls}>
        {controls.map((item, index) => (
          <ControlsBtn
            isActive={index === activeType}
            onClick={() => setActiveType(index)}
            title={item.title}
            key={index}
          />
        ))}
      </div>
      <ResponsiveContainer width="100%" height={240}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 30,
            bottom: 5,
          }}
          barSize={20}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusBar(state.activeTooltipIndex);
            } else {
              setFocusBar(null);
            }
          }}
        >
          <XAxis
            dataKey="name"
            scale="point"
            padding={{ left: 10, right: 10 }}
            axisLine={false}
            tickLine={false}
            tickMargin={13}
            allowDuplicatedCategory={true}
          />
          <YAxis axisLine={false} tickLine={false} tickMargin={15} />
          <Tooltip />

          <Bar
            dataKey="pv"
            fill="#5444EB"
            background={false}
            barSize={26}
            radius={4}
            allowDuplicatedCategory={true}
            isAnimationActive={true}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={focusBar === index ? "#7B61FF" : "#D6D2F8"}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Chart;
