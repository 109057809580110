//react
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Spinner } from "react-activity";
import Modal from "react-modal";

//components
import { Button, Checkbox, Input, InputSelect } from "components";

//assets
import { CrossIcon } from "assets";

//styles
import styles from "./styles.module.scss";

//utils
import { fetchRequest } from "utils";

//hooks
import { useOutsideClick } from "hooks";
import clsx from "clsx";

const limit = 20;

const CreateUser = ({
  isOpen,
  setIsOpen,
  beta = false,
  vip = false,
  onGetUsers,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [boostcamps, setBoostCamps] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBoostcamps, setSelectedBoostcamps] = useState([]);

  // const [isVisibleOptions, setIsVisibleOptions] = useState(false);
  // const [activeBoostcamp, setActiveBoostcamp] = useState(null);
  // const selectRef = useRef(null);
  // useOutsideClick(selectRef, () => {
  //   setIsVisibleOptions(false);
  // });

  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const onCreateUser = async () => {
    try {
      setIsLoadingCreate(true);
      const body = {
        email: email.trim(),
        password: password.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
      };
      if (beta) body.betaTest = true;
      const res = await fetchRequest("users/test", "POST", body);
      if (res?.success) {
        setIsOpen(false);
        onGetUsers && onGetUsers(1, limit, "");
      }
      setIsLoadingCreate(false);
    } catch (error) {
      setIsLoadingCreate(false);
    }
  };

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);
  const onChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);
  const onChangeFirstName = useCallback((e) => {
    setFirstName(e.target.value);
  }, []);
  const onChangeLastName = useCallback((e) => {
    setLastName(e.target.value);
  }, []);

  const onGetBoostCamps = async (page) => {
    try {
      setIsLoading(true);
      const response = await fetchRequest(
        `boost-camps/?page=${page}&limit=${limit}&sort=+createdAt`,
        "GET"
      );
      if (response?.status === 200) {
        setBoostCamps(response?.data?.rows);
        setLastPage(Math.ceil(response?.data?.count / limit));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    if (vip) {
      onGetBoostCamps(1);
    }
  }, [vip]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className={styles.modal}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalInner}>
        <p className={styles.title}>
          New {beta ? "Beta Tester" : vip ? "VIP User" : "Test User"}
        </p>
        <div onClick={() => setIsOpen(false)} className={styles.closeBtn}>
          <CrossIcon />
        </div>

        <Input
          value={email}
          onChange={onChangeEmail}
          label={"Email"}
          placeholder={"Email"}
          classesInner={styles.name}
        />
        <Input
          value={password}
          onChange={onChangePassword}
          type="password"
          label={"Password"}
          placeholder={"Password"}
          classesInner={styles.name}
        />
        <Input
          value={firstName}
          onChange={onChangeFirstName}
          label={"First Name"}
          placeholder={"First Name"}
          classesInner={styles.name}
        />
        <Input
          value={lastName}
          onChange={onChangeLastName}
          label={"Last Name"}
          placeholder={"Last Name"}
          classesInner={styles.name}
        />
        {vip && (
          <>
            <h4>Boostcamps</h4>
            <div className={styles.boostcampsList}>
              {isLoading ? (
                <div className={styles.loaderMini}>
                  <Spinner color={"#8C42E3"} size={15} />
                </div>
              ) : (
                boostcamps?.map((item, index) => {
                  const i = selectedBoostcamps.findIndex(
                    (el) => el.id === item.id
                  );
                  return (
                    <div
                      key={`boostcamp-${item.id}-${index}`}
                      className={clsx(styles.boostcampsItem, {
                        [styles.boostcampsItemActive]: i !== -1,
                      })}
                    >
                      <Checkbox
                        isChecked={i !== -1}
                        setIsChecked={() => {
                          if (i !== -1) {
                            const copy = [...selectedBoostcamps];
                            copy.splice(i, 1);
                            setSelectedBoostcamps(copy);
                          } else {
                            setSelectedBoostcamps((prev) => [...prev, item]);
                          }
                        }}
                      />
                      <span>{item.title}</span>
                    </div>
                  );
                })
              )}
            </div>
            {/* <InputSelect
              value={""}
              onChange={() => {}}
              label={"Boostcamps"}
              placeholder={"Boostcamps"}
              data={["1", "2"]}
              classesInner={styles.category}
            /> */}
          </>
        )}
        <Button
          title={"Create"}
          onClick={onCreateUser}
          disabled={
            email?.length < 2 ||
            password?.length < 2 ||
            firstName?.length < 2 ||
            lastName?.length < 2
          }
          loading={isLoadingCreate}
        />
      </div>
    </Modal>
  );
};

export default CreateUser;
