import React, { useState, useEffect, useCallback, useRef } from "react";
import { fetchRequest } from "utils";
import styles from "./styles.module.scss";
import { SearchIcon } from "assets";
import { Spinner } from "react-activity";
import { useOutsideClick } from "hooks";

const limit = 10;

const SearchIngredients = ({ setIsOpen, onSelect }) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [unit, setUnit] = useState([]);

  const ref = useRef(null);
  useOutsideClick(ref, () => setIsOpen(false));

  const onChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const onGetUnit = async () => {
    try {
      const res = await fetchRequest("units", "GET");
      if (res?.success) {
        setUnit(res?.data);
      }
    } catch (error) {}
  };

  const onGetIngredients = async (page, limit, q = "") => {
    try {
      page === 1 && setIsLoading(true);
      page > 1 && setIsLoadingPagination(true);
      let url = `ingredients?page=${page}&limit=${limit}`;
      if (q) {
        url = url + `&q=${q}`;
      }
      const res = await fetchRequest(url, "GET");
      if (res?.success) {
        setPagesCount(Math.ceil(res?.data?.count / limit));
        if (page === 1) {
          setIngredients(res?.data?.rows);
        } else {
          setIngredients((prev) => [...prev, ...res?.data?.rows]);
        }

        setPage(page);
      }
      page === 1 && setIsLoading(false);
      page > 1 && setIsLoadingPagination(false);
    } catch (error) {
      setIsLoading(false);
      setIsLoadingPagination(false);
    }
  };

  const handleScroll = (e) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1;
    if (bottom && !isLoadingPagination && pagesCount !== page) {
      onGetIngredients(page + 1, limit, search);
      // incrementPage();
    }
  };

  useEffect(() => {
    let timeOut = setTimeout(() => {
      if (search) {
        onGetIngredients(1, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeOut);
  }, [search]);

  useEffect(() => {
    if (!search) {
      onGetIngredients(page, limit, search);
    }
  }, [search]);
  useEffect(() => {
    onGetUnit();
  }, []);

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.searchInputWrapper}>
        <SearchIcon width={20} height={20} />
        <input
          value={search}
          onChange={onChangeSearch}
          className={styles.searchInput}
          placeholder={"Find ingredient"}
        />
      </div>
      <div onScroll={handleScroll} className={styles.list}>
        {isLoading && (
          <div className={styles.listLoading}>
            <Spinner size={20} color={"#8C42E3"} />
          </div>
        )}
        {!isLoading &&
          ingredients.map((item, index) => (
            <div
              onClick={() => {
                onSelect(item);
                setIsOpen(false);
              }}
              className={styles.listItem}
              key={item?.id}
            >
              {item?.name}
            </div>
          ))}
        {isLoadingPagination && (
          <div className={styles.listLoadingPagination}>
            <Spinner size={10} color={"#8C42E3"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchIngredients;
