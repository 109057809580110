import React from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import styles from "./styles.module.scss";
import { uploadFile } from "../../services/file.service";
import clsx from "clsx";

const DEFAULT_MODULES = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],

    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["link", "image", "video"],
    [{ color: [] }, { background: [] }],

    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const WITH_DISABLED_MODULES = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],

    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["link"],
    [{ color: [] }, { background: [] }],

    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const DEFAULT_FORMATS = [
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "list",
  "indent",
  "size",
  "header",
  "link",
  "image",
  "video",
  "color",
  "background",
  "clean",
];

const WITH_DISABLED_FORMATS = [
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "list",
  "indent",
  "size",
  "header",
  "link",
  "color",
  "background",
  "clean",
];

const RichTextEditor = ({
  label = "",
  initialValue = ``,
  setValue,
  disableMedia = false,
  error = false,
}) => {
  const theme = "snow";
  const { quill, quillRef } = useQuill({
    theme,
    modules: !disableMedia ? DEFAULT_MODULES : WITH_DISABLED_MODULES,
    formats: !disableMedia ? DEFAULT_FORMATS : WITH_DISABLED_FORMATS,
  });

  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
  };

  const saveToServer = async (file) => {
    try {
      const { data, error } = await uploadFile(file);
      if (data?.url) {
        insertToEditor(data?.url);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  React.useEffect(() => {
    if (quill) {
      const delta = quill.clipboard.convert(initialValue);
      quill.setContents(delta);
      quill.on("text-change", (delta, oldDelta, source) => {
        setValue(quill.root.innerHTML);
      });
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
    }
  }, [quill]);

  return (
    <div className={clsx(styles.wrapper, error && "ql-error")}>
      {label && <h4>{label}</h4>}
      <div ref={quillRef} />
    </div>
  );
};

export default RichTextEditor;
