import { Delete, PlusIcon, DeleteSmall } from "assets";
import {
  Input,
  InputSelect,
  TextAreaEditor,
  Button,
  SearchIngredients,
  AlertError,
} from "components";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-activity";
import { fetchRequest } from "utils";
import styles from "./styles.module.scss";
import TopModal from "modals/TopModal";
import { useToggleModal } from "hooks";
import { useNavigate } from "react-router-dom";

const CreateShoppingList = () => {
  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [categories, setCategory] = useState([
    {
      text: "",
      id: "",
      ingredients: [],
      isOpenSearch: false,
    },
  ]);
  const [allCategories, setAllCategories] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [allUnits, setAllUnits] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isSaveAnotherLoading, setSaveAnotherLoading] = useState(false);
  const savedModal = useToggleModal();
  const navigate = useNavigate();
  const updatedShown = () => {
    savedModal.showModal();

    setTimeout(() => {
      savedModal.closeModal();
    }, 3000);
  };
  useEffect(() => {
    if (
      name?.length > 2 &&
      shortDescription?.length > 3 &&
      categories?.length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [categories, name, shortDescription]);

  // const [ingredients, setIngredients] = useState([]);

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const onChangeShortDescription = useCallback((e) => {
    setShortDescription(e.target.value);
  }, []);

  const onFetchingCategories = async () => {
    try {
      setIsLoadingCategories(true);
      const res = await fetchRequest("categories", "GET");
      if (res?.success) {
        setCategory([{ ...res?.data[0], ingredients: [] }]);
        setAllCategories(res?.data);
      }
      setIsLoadingCategories(false);
    } catch (e) {
      setIsLoadingCategories(false);
    }
  };
  const onChangeIngredientAmount = useCallback(
    (e, index, indexCategory, indexUnit) => {
      setCategory((prev) => {
        const copy = [...prev];
        copy[indexCategory].ingredients[index].units[indexUnit].amount =
          e.target.value;
        return copy;
      });
    },
    []
  );

  const onChangeIngredientUnit = useCallback(
    (item, index, indexCategory, indexUnit) => {
      setCategory((prev) => {
        const copy = [...prev];
        copy[indexCategory].ingredients[index].units[indexUnit].unit = item;
        return copy;
      });
    },
    []
  );

  const onAddNewUnit = useCallback((index, indexCategory) => {
    setCategory((prev) => {
      const copy = [...prev];
      copy[indexCategory].ingredients[index].units?.push({
        amount: 1,
        unit: copy[indexCategory]?.ingredients[index].units[
          copy[indexCategory]?.ingredients[index]?.units?.length - 1
        ]?.unit,
      });
      return copy;
    });
  });

  const onDeleteNewUnit = useCallback((index, indexCategory, i) => {
    setCategory((prev) => {
      const copy = [...prev];
      copy[indexCategory].ingredients[index].units?.splice(i, 1);
      return copy;
    });
  });

  const onDeleteIngredient = (index, indexCategory) => {
    const copy = [...categories];
    copy[indexCategory]?.ingredients?.splice(index, 1);
    setCategory(copy);
  };

  const onGetAllUnits = async () => {
    try {
      const res = await fetchRequest("units", "GET");
      if (res?.success) {
        setAllUnits(res?.data);
      }
    } catch (error) {}
  };

  const onSelectIngredient = (item, indexCategory) => {
    setCategory((prev) => {
      const copy = [...prev];
      copy[indexCategory].ingredients = [
        ...copy[indexCategory].ingredients,
        {
          units: [
            {
              amount: "1",
              unit: allUnits?.length > 0 ? allUnits[0] : { text: "", id: "" },
            },
          ],
          ingredient: item,
        },
      ];
      return copy;
    });
  };

  const onAddCategory = () => {
    setCategory((prev) => {
      return [
        ...prev,
        {
          text: "",
          id: "",
          ingredients: [],
          isOpenSearch: false,
        },
      ];
    });
  };

  const onDeleteCategory = (indexCategory) => {
    setCategory((prev) => {
      const copy = [...prev];
      copy.splice(indexCategory, 1);
      return copy;
    });
  };

  const onCreateShopingList = async (withAnother = false) => {
    try {
      if (withAnother) {
        setSaveAnotherLoading(true);
      } else {
        setIsSaveLoading(true);
      }

      const body = {
        title: name,
        description: shortDescription,
        categories: categories.map((category) => ({
          category: category?.id,
          ingredients: category?.ingredients?.map((ingredient) => {
            return {
              ingredient: ingredient?.ingredient?.id,
              units: ingredient?.units?.map((el) => ({
                unit: el?.unit?.id,
                amount: el?.amount,
              })),
            };
          }),
        })),
      };
      const res = await fetchRequest("shopping-lists", "POST", body);
      if (res?.success) {
        if (!withAnother) {
          navigate("/shopping-list/manage");
        } else {
          updatedShown();
          setName("");
          setShortDescription("");
          setCategory([{ ...allCategories[0], ingredients: [] }]);
        }
      } else {
        alert(res?.message || "Something went wrong");
      }
      setIsSaveLoading(false);
      setSaveAnotherLoading(false);
    } catch (e) {
      setSaveAnotherLoading(false);
      setIsSaveLoading(false);
    }
  };

  useEffect(() => {
    onFetchingCategories();
    onGetAllUnits();
  }, []);

  if (isLoadingCategories) {
    return (
      <div className={styles.wrapper}>
        <h1>Create New Shopping List</h1>
        <div className={styles.loaderWrapper}>
          <Spinner size={40} color={"#8C42E3"} />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={styles.wrapper}>
        <h1>Create New Shopping List</h1>
        <div className={styles.headerInputWrapper}>
          <Input
            value={name}
            onChange={onChangeName}
            label={"Shopping List Name"}
            placeholder={"Name"}
            classes={styles.input}
          />
          <TextAreaEditor
            value={shortDescription}
            onChange={onChangeShortDescription}
            label={"Short Description"}
            placeholder={"Short Description"}
            classes={styles.shortDescription}
          />
        </div>
        <div className={styles.divider} />
        {categories?.map((category, indexCategory) => {
          return (
            <div key={indexCategory} className={styles.categoryBlock}>
              <div
                onClick={() => onDeleteCategory(indexCategory)}
                className={styles.deleteCategoryBtn}
              >
                <DeleteSmall />
                <span>Remove category</span>
              </div>
              <div className={styles.inputWrapper}>
                <InputSelect
                  value={category.text}
                  onChange={(category) =>
                    setCategory((prev) => {
                      const copy = [...prev];
                      copy[indexCategory].text = category.text;
                      copy[indexCategory].id = category.id;
                      return copy;
                    })
                  }
                  data={allCategories}
                  label={"Category"}
                  classes={styles.input}
                />
                {category?.ingredients?.length > 0 && (
                  <div className={styles.addIngredientHeader}>
                    <span
                      style={{ flex: 1 }}
                      className={styles.addIngredientHeaderTitle}
                    >
                      Ingredient
                    </span>
                    <span
                      style={{ flex: 0.5 }}
                      className={styles.addIngredientHeaderTitle}
                    >
                      Amount
                    </span>
                    <span
                      style={{ flex: 0.5 }}
                      className={styles.addIngredientHeaderTitle}
                    >
                      Unit
                    </span>
                    <div className={styles.deleteIngredientBtn}></div>
                  </div>
                )}
                {category?.ingredients.map((item, index) => {
                  return (
                    <div key={index} className={styles.ingredientsWrapper}>
                      <Input
                        value={item?.ingredient?.name}
                        classesInner={styles.nameInput}
                        placeholder={"Chose ingredient"}
                        onChange={() => {}}
                        disabled={true}
                      />
                      <div className={styles.unitContainer}>
                        {item?.units?.map((el, indexUnit) => {
                          return (
                            <div key={indexUnit} className={styles.unitWrapper}>
                              <Input
                                value={el.amount}
                                onChange={(e) =>
                                  onChangeIngredientAmount(
                                    e,
                                    index,
                                    indexCategory,
                                    indexUnit
                                  )
                                }
                                classesInner={styles.amount}
                              />
                              <InputSelect
                                value={el.unit.text}
                                onChange={(el) => {
                                  onChangeIngredientUnit(
                                    el,
                                    index,
                                    indexCategory,
                                    indexUnit
                                  );
                                }}
                                classesInner={styles.unit}
                                data={allUnits}
                              />
                              <div
                                onClick={() => {
                                  if (indexUnit === 0) {
                                    onDeleteIngredient(index, indexCategory);
                                  } else {
                                    onDeleteNewUnit(
                                      index,
                                      indexCategory,
                                      indexUnit
                                    );
                                  }
                                }}
                                className={styles.deleteBtn}
                              >
                                <Delete />
                              </div>
                            </div>
                          );
                        })}
                        <div
                          onClick={() => onAddNewUnit(index, indexCategory)}
                          className={styles.addUnit}
                        >
                          <PlusIcon width={12} height={12} />
                          <span>Add Unit</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className={styles.addIngredientWrapper}>
                  <div
                    onClick={() =>
                      setCategory((prev) => {
                        const copy = [...prev];
                        copy[indexCategory].isOpenSearch =
                          !copy[indexCategory].isOpenSearch;
                        return copy;
                      })
                    }
                    className={styles.addIngredientBtn}
                  >
                    <PlusIcon />
                    <span>Add Ingredient</span>
                  </div>
                  {category?.isOpenSearch && (
                    <SearchIngredients
                      setIsOpen={(isOpen) =>
                        setCategory((prev) => {
                          const copy = [...prev];
                          copy[indexCategory].isOpenSearch = isOpen;
                          return copy;
                        })
                      }
                      onSelect={(item) => {
                        onSelectIngredient(item, indexCategory);
                      }}
                    />
                  )}
                </div>
              </div>
              {indexCategory !== categories?.length - 1 && (
                <div className={styles.divider} />
              )}
            </div>
          );
        })}

        <div className={styles.divider} />
        <div onClick={onAddCategory} className={styles.addCategoryBtn}>
          <PlusIcon />
          <span>Add Category</span>
        </div>
        <div className={styles.divider} />
        <div className={styles.footer}>
          <Button title={"Cancel"} classes={styles.cancelBtn} />
          <Button
            onClick={() => onCreateShopingList(false)}
            disabled={isDisabled}
            loading={isSaveLoading}
            title={"Save"}
            classes={styles.saveBtn}
          />
          <Button
            onClick={() => onCreateShopingList(true)}
            disabled={isDisabled}
            loading={isSaveAnotherLoading}
            title={"Save & Create Another One"}
            classes={styles.createBtn}
          />
        </div>
      </div>
      {savedModal.isShow && <TopModal title="Sucessfully saved" />}
    </>
  );
};

export default CreateShoppingList;
