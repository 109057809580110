import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Button } from "components";
import styles from "./styles.module.scss";
import "rc-slider/assets/index.css";
import clsx from "clsx";

import TrackersInput from "../TrackersInput";

// import { setTrackers } from "../../services/progress.service";
import Fasting from "../../../assets/fasting.png";

const FastingTracker = ({ isOpen, setIsOpen, currentDay, setCurrentDay }) => {
  const [activeType, setActiveType] = useState(0);
  const [value, setValue] = useState("0");
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onChangeFasting = async () => {
    try {
      setIsLoadingSubmit(true);
      if (currentDay?.trackers) {
        const body = {
          ...currentDay,
          trackers: {
            ...currentDay?.trackers,
            eat: +value,
          },
        };
        setCurrentDay(body);
        onClose();
      }
      setIsLoadingSubmit(false);
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (currentDay) {
      setValue(`${currentDay?.trackers?.eat || 0}`);
    }
  }, [currentDay]);

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          borderRadius: "30px",
          padding: "25px",
          outline: "none",
          overflow: "hidden",
        },
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen={isOpen}
    >
      <div className={styles.contentBox}>
        <div className={styles.header}>
          <div className={styles.iconWrapper}>
            <img src={Fasting} className={styles.fastingIcon} />
          </div>
          <h5>
            How long did
            <br /> you fast for?
          </h5>
        </div>
        <div className={styles.sliderWrapper}>
          <div className={styles.sliderValueWrapper}>
            {/* <span>{options[activeIndex]}</span> */}
            <div className={styles.inputWrapper}>
              <TrackersInput value={value} setValue={setValue} />
            </div>
            <div className={styles.btnContainer}>
              <div
                onClick={() => setActiveType(0)}
                className={clsx(styles.typeBtn, {
                  [styles.typeBtnActive]: activeType === 0,
                })}
              >
                hrs
              </div>
            </div>
          </div>
        </div>

        <Button
          title={"SUBMIT"}
          styleType={"filled"}
          size={"small"}
          backgroundColor={"#F05122"}
          loading={isLoadingSubmit}
          onClick={onChangeFasting}
        />
      </div>
    </Modal>
  );
};

export default FastingTracker;
