import clsx from "clsx";
import { Checkbox } from "components";
import React from "react";
import styles from "./styles.module.scss";

const TagSelector = ({ isSelect = false, item, onSelect }) => {
  return (
    <div
      onClick={() => {
        if (isSelect) {
          onSelect((prev) => {
            const copy = [...prev];
            const index = copy.findIndex((el) => el?.id === item?.id);
            if (index !== -1) {
              copy.splice(index, 1);
            }
            return copy;
          });
        } else {
          onSelect((prev) => [...prev, item]);
        }
      }}
      className={clsx(styles.wrapper, { [styles.selectedWrapper]: isSelect })}
    >
      <Checkbox isChecked={isSelect} setIsChecked={() => {}} iconSize={16} />
      <span>{item?.text}</span>
    </div>
  );
};

export default TagSelector;
