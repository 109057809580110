import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { SleepIcon } from "assets";
import { Button } from "components";
import styles from "./styles.module.scss";
import Slider from "rc-slider";
import "./index.css";

const SleepTracker = ({ isOpen, setIsOpen, currentDay, setCurrentDay }) => {
  const [sleepOptions, setSleepOptions] = useState([
    { label: "≤2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "≥9", value: 9 },
  ]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentValue, setCurrentValue] = useState(2);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onChangeSleep = async () => {
    try {
      setIsLoadingSubmit(true);
      if (currentDay?.trackers) {
        const body = {
          ...currentDay,
          trackers: {
            ...currentDay?.trackers,
            sleep: currentValue,
          },
        };
        setCurrentDay(body);
        onClose();
      }
      setIsLoadingSubmit(false);
    } catch (error) {
      setIsLoadingSubmit(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentDay) {
      setCurrentValue(currentDay?.trackers?.sleep || 2);
      const index = sleepOptions.findIndex(
        (item) => item.value === currentDay?.trackers?.sleep
      );

      setActiveIndex(index > 0 ? index : 0);
    }
  }, [currentDay]);

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          borderRadius: "30px",
          padding: "25px",
          outline: "none",
          overflow: "hidden",
        },
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen={isOpen}
    >
      <div className={styles.contentBox}>
        <div className={styles.header}>
          <div className={styles.iconWrapper}>
            <SleepIcon />
          </div>
          <h5>
            How much time did <br /> you sleep today?
          </h5>
        </div>
        <div className={styles.sliderWrapper}>
          <h2>{sleepOptions[activeIndex]?.label}</h2>
          <p className={styles.recommended}>
            Recommended time sleep: <b>8 hours</b>
          </p>
          <Slider
            min={0}
            max={7}
            defaultValue={activeIndex}
            onChange={(value) => {
              setActiveIndex(value);
              setCurrentValue(sleepOptions[value]?.value);
            }}
            trackStyle={{ height: "15px", backgroundColor: "#8C42E3" }}
            railStyle={{ height: "15px" }}
            handleStyle={{
              height: "26px",
              width: "26px",
              backgroundColor: "#fff",
              borderColor: "#8C42E3",
              borderWidth: "8px",
              opacity: 1,
            }}
          />
          <div className={styles.sliderOptionsWrapper}>
            {sleepOptions?.map((item, index) => {
              return (
                <span className={styles.sliderOption} key={index}>
                  {item?.label}
                </span>
              );
            })}
          </div>
        </div>

        <Button
          title={"SUBMIT"}
          styleType={"filled"}
          size={"small"}
          onClick={onChangeSleep}
          loading={isLoadingSubmit}
        />
      </div>
    </Modal>
  );
};

export default SleepTracker;
