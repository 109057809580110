import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";
import moment from "moment";
import { ArrowRight } from "assets";
import avatar from "../../assets/avatar.png";

const UsersItem = ({ item }) => {
  const navigate = useNavigate();

  // const userPhoto = useMemo(
  //   () => (item?.photos?.main ? item.photos.main : undefined),
  //   [item]
  // );

  const navigateToUser = () => {
    navigate(`/users/${item.id}`);
  };

  return (
    <div className={styles.wrapper} onClick={navigateToUser}>
      <div className={styles.user}>
        <img
          src={item?.picture || avatar}
          alt="User Avatar"
          className={styles.avatar}
        />
        <span>
          {item?.firstName} {item?.lastName}
        </span>
      </div>
      <div className={styles.email}>{item?.email}</div>
      <div className={styles.boost}>{item?.boostCamp}</div>
      <div className={styles.date}>{moment(item?.createdAt).format("L")}</div>
      <div className={styles.arrow}>
        <ArrowRight width={24} height={24} className={styles.btn} />
      </div>
    </div>
  );
};

export default UsersItem;
