import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-modal";

import styles from "./styles.module.scss";
import { fetchRequest } from "utils";
import { Checkbox, Pagination } from "components";
import { Spinner } from "react-activity";
import { CrossIcon } from "assets";

const BoostcampItem = ({
  boost,
  vipBoostcamps,
  isUpdating,
  onUpdate,
  userProgress,
}) => {
  const findedBoost = useMemo(
    () => vipBoostcamps?.find((b) => b?.boostCamp === boost?.id),
    [boost, vipBoostcamps]
  );
  const buyedBoost = useMemo(
    () => userProgress?.rows?.find((b) => b?.id === boost?.id),
    [boost, userProgress]
  );

  const isChecked = useMemo(
    () => (findedBoost && findedBoost?.free) || buyedBoost,
    [findedBoost, buyedBoost]
  );

  return (
    <div className={styles.boostcampItem}>
      <Checkbox
        isChecked={isChecked}
        setIsChecked={() => onUpdate(isChecked, boost?.id)}
        iconSize={18}
        loading={isUpdating}
        disabled={buyedBoost}
      />
      <span>{boost?.title}</span>
    </div>
  );
};

export const UserScreenModal = ({
  isOpen,
  setIsOpen,
  user,
  isLoadingUser,
  userProgress,
  isLoadingProgress,
}) => {
  const [boostcamps, setBoostCamps] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [vipBoostcamps, setVipBoostcamps] = useState([]);

  const limit = 6;

  const onGetBoostCamps = async (page) => {
    try {
      setIsLoading(true);
      const response = await fetchRequest(
        `boost-camps/?page=${page}&limit=${limit}&sort=+createdAt`,
        "GET"
      );
      if (response?.status === 200) {
        setBoostCamps(response?.data?.rows);
        setLastPage(Math.ceil(response?.data?.count / limit));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  const incrementPage = () => {
    if (boostcamps && page < lastPage) {
      onGetBoostCamps(page + 1);
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      onGetBoostCamps(page - 1);
      setPage(page - 1);
    }
  };

  useEffect(() => {
    setPage(1);
    onGetBoostCamps(1);
  }, []);

  useEffect(() => {
    if (user?.vip?.boostCamps?.length > 0) {
      setVipBoostcamps(user?.vip?.boostCamps);
    }
    console.log(user?.vip);
  }, [user]);

  // const onUpdate = (res) => {
  //   console.log("onUpdate res", res);
  // };

  const onUpdate = async (isChecked, id) => {
    try {
      setIsUpdating(true);
      const hasBoost =
        vipBoostcamps?.findIndex((b) => b?.boostCamp === id) > -1;
      let newVipBoostcamps = vipBoostcamps;
      if (hasBoost) {
        newVipBoostcamps = vipBoostcamps?.map((b) =>
          b?.boostCamp === id ? { ...b, free: !isChecked } : b
        );
      } else {
        newVipBoostcamps?.push({ boostCamp: id, free: !isChecked });
      }
      const response = await fetchRequest(`users/${user?.id}/vip`, "PUT", {
        vip: {
          status: true,
          boostCamps: newVipBoostcamps,
        },
      });

      if (response?.success) {
        // onUpdate && onUpdate(response?.data);
        console.log("onUpdate res", response?.data?.vip?.boostCamps);
        setVipBoostcamps(response?.data?.vip?.boostCamps || []);
      }
    } catch (e) {
    } finally {
      setIsUpdating(false);
    }
  };

  console.log("vipBoostcamps", vipBoostcamps);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className={styles.modal}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalInner}>
        <div onClick={() => setIsOpen(false)} className={styles.closeBtn}>
          <CrossIcon />
        </div>
        <p className={styles.title}>VIP Boostcamps</p>
        <div className={styles.contentContainer}>
          {isLoading ? (
            <div className={styles.loaderWrapper}>
              <Spinner color="#8C42E3" size={30} />
            </div>
          ) : (
            <>
              {boostcamps?.map((boost, index) => (
                <BoostcampItem
                  boost={boost}
                  vipBoostcamps={vipBoostcamps}
                  userProgress={userProgress}
                  key={`boost-${boost?.id}-${index}`}
                  isUpdating={isUpdating || isLoadingUser || isLoadingProgress}
                  setIsUpdating={setIsUpdating}
                  onUpdate={onUpdate}
                />
              ))}
            </>
          )}
        </div>
        {!isLoading && (
          <div className={styles.paginationWrapper}>
            <Pagination
              incrementPage={incrementPage}
              decrementPage={decrementPage}
              page={page}
              lastPage={lastPage}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
