//react
import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";

//components
import {
  Input,
  QuestionItem,
  Button,
  ReviewItem,
  RecipesItem,
  RichTextEditor,
} from "components";
import BoostCampPriceItem from "../../CreateBoostCamps/BoostCampPriceItem";
import { CustomAlert } from "components";
import TopModal from "modals/TopModal";

import { AttachMealPlanBtn } from "components";

//navigation
import { useNavigate, useParams } from "react-router-dom";

//assets
import { ImgPlaceholder, Add, PlusIcon, HouseIcon } from "assets";

//hooks
import { useToggleModal } from "hooks";

//styles
import styles from "./styles.module.scss";
import { fetchRequest } from "utils";
import { deleteFile, uploadFile } from "services/file.service";
import { Spinner } from "react-activity";
import clsx from "clsx";

const EditBoostCampsMain = ({
  title,
  setTitle,
  numberOfDay,
  setNumberOfDay,
  preview,
  setPreview,
  shortDescription,
  setShortDescription,
  fullOverview,
  setFullOverview,
  questions,
  setQuestions,
  days,
  setDays,
  priceItems,
  setPriceItems,
  recipes,
  setRecipes,
  totalPoints,
  setTotalPoints,
  mealPlanPdf,
  setMealPlanPdf,
  mealPlans,
  setMealPlans,
  reviews,
  setReviews,
  printRecipePrice,
  setPrintRecipePrice,
  printRecipeTitle,
  setPrintRecipeTitle,
  printRecipeText,
  setPrintRecipeText,
  isLoadingBoostcampMeal,
}) => {
  const params = useParams();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date());
  const [price, setPrice] = useState("");
  const [discountEnable, setDiscountEnable] = useState(false);
  const [discount, setDiscount] = useState("");
  const [discountOptions, setDiscountOptions] = useState("%");
  const [photo, setPhoto] = useState("");
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingTest, setIsLoadingTest] = useState(false);

  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [isLoadingDeleteFile, setIsLoadingDeleteFile] = useState(false);

  //errorState
  const [titleError, setTitleError] = useState(false);
  const [numberOfDayError, setNumberOfDayError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [pictureError, setPictureError] = useState(false);
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [fullOverviewError, setFullOverviewError] = useState(false);
  const [mealPdfError, setMealPdfError] = useState(false);
  const [shoppingPdfError, setShoppingPdfError] = useState(false);
  const [printRecipeErrors, setPrintRecipeErrors] = useState({
    price: false,
    title: false,
    text: false,
  });

  const [serverError, setServerError] = useState(false);

  const savedModal = useToggleModal();
  const cancelModal = useToggleModal();

  useEffect(() => {
    let total = 0;

    days.forEach((day) => day?.tasks?.forEach((el) => (total += +el?.points)));
    setTotalPoints(total);
  }, [days]);

  const updatedShown = () => {
    savedModal.showModal();

    setTimeout(() => {
      savedModal.closeModal();
    }, 3000);
  };

  const createBodyObjForUpdateBoostcamp = () => {
    const recipesCopy = [...recipes];
    const reviewsCopy = reviews.map((item) => ({
      name: item.name,
      city: item.city,
      text: item.text,
      rating: item.rating.value,
      avatar: item.avatar,
    }));

    const mapedPriceItems = priceItems.map((el) => ({
      ...el,
      discount: {
        count: el?.discount?.count,
        type: el?.discount?.type,
      },
    }));

    const body = {
      title,
      daysNumber: numberOfDay,
      prices: mapedPriceItems,
      description: shortDescription,
      picture: preview,
      overview: fullOverview,
      faq: questions,
      days,
      reviews: reviewsCopy,
      mealPlan: mealPlans?.map((item) => item?.meal?.id),
      shoppingListPdf: recipes[0]?.fileUrl,
      mealPlanPdf: mealPlanPdf[0]?.fileUrl,
      printRecipe: {
        price: printRecipePrice,
        title: printRecipeTitle,
        text: printRecipeText,
      },
    };

    return body;
  };

  const showErrors = ({
    titleErr,
    numberOfDayErr,
    previewErr,
    printRecipeErr,
    shortDescriptionErr,
    mealPdfErr,
    shopingPdfErr,
    fullOverviewErr,
  }) => {
    setTitleError(titleErr);
    setNumberOfDayError(numberOfDayErr);
    setPictureError(previewErr);
    setPrintRecipeErrors(printRecipeErr);
    setShortDescriptionError(shortDescriptionErr);
    setMealPdfError(mealPdfErr);
    setShoppingPdfError(shopingPdfErr);
    setFullOverviewError(fullOverviewErr);

    if (titleErr) toast.error("Title field must be at least 3 letters long");
    if (numberOfDayErr) toast.error("Number of days field is required");
    if (previewErr) toast.error("Preview is required");
    if (printRecipeErr?.price)
      toast.error("Price for the Print Recipe field is required");
    if (printRecipeErr?.title)
      toast.error("Price for the Print Recipe is required");
    if (printRecipeErr?.text)
      toast.error("Print recipe description field is required");
    if (shortDescriptionErr) toast.error("Short description is required");
    if (mealPdfErr) toast.error("Meal Plan PDF is required");
    if (shopingPdfErr) toast.error("Shopping list is required");
    if (fullOverviewErr) toast.error("Full overview field is required");

    const printRecipeErrs =
      printRecipeErr?.price || printRecipeErr?.title || printRecipeErr?.text;

    const hasAnyError =
      titleErr ||
      numberOfDayErr ||
      previewErr ||
      printRecipeErrs ||
      shortDescriptionErr ||
      mealPdfErr ||
      shopingPdfErr ||
      fullOverviewErr;

    console.log(hasAnyError, {
      titleErr,
      numberOfDayErr,
      previewErr,
      printRecipeErr,
      shortDescriptionErr,
      mealPdfErr,
      shopingPdfErr,
      fullOverviewErr,
      printRecipeErrs,
    });

    return hasAnyError;
  };

  const saveBoostcamp = async () => {
    try {
      //ERROR handle start
      const hasTitleError = title.length < 3;
      const hasNumberOfDayError = !numberOfDay.length;
      const hasPreviewError = !preview;
      const hasShortDescriptionError = !shortDescription;
      const hasMealPdfError = !mealPlanPdf[0]?.fileUrl;
      const hasShoppingPdfError = !recipes[0]?.fileUrl;
      const hasFullOverviewError = !fullOverview;

      const hasErrors = showErrors({
        titleErr: hasTitleError,
        numberOfDayErr: hasNumberOfDayError,
        previewErr: hasPreviewError,
        printRecipeErr: {
          price: !printRecipePrice,
          title: !printRecipeTitle,
          text: !printRecipeText,
        },
        shortDescriptionErr: hasShortDescriptionError,
        mealPdfErr: hasMealPdfError,
        shopingPdfErr: hasShoppingPdfError,
        fullOverviewErr: hasFullOverviewError,
      });
      if (hasErrors) return;
      //ERROR handle end
      setIsLoadingSave(true);

      const body = createBodyObjForUpdateBoostcamp();

      const res = await fetchRequest(`boost-camps/${params?.id}`, "PUT", body);

      if (res?.status === 202) {
        updatedShown();
      } else {
        setServerError(res);
      }
      setIsLoadingSave(false);
    } catch (error) {
      setIsLoadingSave(false);
      console.log(error);
      setServerError(error);
    }
  };

  const onTestBoostCamps = async () => {
    try {
      if (title.length < 3) {
        setTitleError(true);
        return;
      } else {
        setTitleError(false);
      }
      if (!numberOfDay) {
        setNumberOfDayError(true);
        return;
      } else {
        setNumberOfDayError(false);
      }
      if (!preview) {
        setPictureError(true);
        return;
      } else {
        setPictureError(false);
      }
      setIsLoadingTest(true);
      const body = createBodyObjForUpdateBoostcamp();
      const res = await fetchRequest(`boost-camps/${params?.id}`, "PUT", body);
      if (res?.status === 202) {
        navigate(`/preview/${params?.id}`);
      }

      setIsLoadingTest(false);
    } catch (error) {
      setIsLoadingTest(false);
      console.log(error);
    }
  };

  const navigateToNextStep = () => {
    // navigate("fillDaysBoostCamps", {
    //   replace: false,
    //   state: {
    //     title,
    //     daysNumber: numberOfDay,
    //     price,
    //     description: shortDescription,
    //     overview: fullOverview,
    //     faq: questions,
    //   },
    // });

    saveBoostcamp();
  };

  const onChangefullOverview = (e) => {
    setFullOverview(e);
  };

  const onChangeShortDescription = (e) => {
    setShortDescription(e);
  };

  const onChangeTitle = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const onChangeNumberOfDay = useCallback((e) => {
    setNumberOfDay(e.target.value);
  });

  // const onChangeStartDate = useCallback((e) => {
  //   setStartDate(e.target.value);
  // });

  // const onChangePrice = useCallback((e) => {
  //   setPrice(e.target.value);
  // });

  // const onChangeDiscount = useCallback((e) => {
  //   setDiscount(e.target.value);
  // });

  // const onChangeDiscountOptions = useCallback((e) => {
  //   setDiscountOptions(e.target.value);
  // });

  const onChangePrintRecipePrice = useCallback((e) => {
    setPrintRecipePrice(e.target.value);
  });
  const onChangePrintRecipeTitle = useCallback((e) => {
    setPrintRecipeTitle(e.target.value);
  });
  const onChangePrintRecipeText = (e) => {
    setPrintRecipeText(e);
  };

  const onDeleteImg = () => {};

  const onDeleteAnswer = (index) => {
    const questionsCopy = [...questions];
    questionsCopy.splice(index, 1);
    setQuestions(questionsCopy);
  };

  const onAddAnswer = () => {
    setQuestions((prev) => [...prev, { answer: "", question: "" }]);
  };

  const handleQuestionsChange = useCallback(
    (e, index) => {
      const questionsCopy = [...questions];
      questionsCopy[index].question = e.target.value;
      setQuestions(questionsCopy);
    },
    [questions]
  );

  const handleAnswerChange = useCallback(
    (e, index) => {
      const questionsCopy = [...questions];
      questionsCopy[index].answer = e.target.value;
      setQuestions(questionsCopy);
    },
    [questions]
  );

  const handleAddNewDate = useCallback(() => {
    const priceItemsCopy = [...priceItems];
    priceItemsCopy.push({
      title: `Monday ${priceItemsCopy.length + 1}`,
      price: "",
      discount: { count: 0, type: "percentage" },
      link: "",
    });
    setPriceItems(priceItemsCopy);
  }, [priceItems]);

  const onSelectFile = async (e) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setIsLoadingUploadFile(true);
      const res = await uploadFile(e.target.files[0]);
      setPreview(res?.data?.url);
      setIsLoadingUploadFile(false);
    } catch (error) {
      setIsLoadingUploadFile(false);

      console.log(error);
    }
  };

  const onAddReview = useCallback(() => {
    const reviewsCopy = [...reviews];
    reviewsCopy.push({ name: "", city: "", text: "", rating: 5, avatar: "" });
    setReviews(reviewsCopy);
  });
  const onDeleteFile = async (e) => {
    try {
      setIsLoadingDeleteFile(true);
      const res = await deleteFile(preview);
      setPreview(undefined);
      setIsLoadingDeleteFile(false);
    } catch (e) {
      setIsLoadingDeleteFile(false);

      console.log(e);
    }
  };

  return (
    <>
      <div className={styles.bootcampsSettingsWrapper}>
        <div className={styles.bootcampsSettingsLeftContent}>
          <Input
            value={title}
            onChange={onChangeTitle}
            label={"Title"}
            errorBorder={titleError}
          />
          <div className={styles.bootcampsSettingsNumbersOfDayWrapper}>
            <div className={styles.bootcampsSettingsNumbersOfDayInp}>
              <Input
                type={"number"}
                value={numberOfDay}
                onChange={onChangeNumberOfDay}
                label={"Number of days"}
                errorBorder={numberOfDayError}
              />
            </div>
          </div>
          <div className={styles.bootcampsSettingsPriceWrapper}>
            <div className={styles.bootcampsSettingsPriceHeader}>
              <div style={{ flex: 2 }} className={styles.inputTitle}>
                Date
              </div>
              <div style={{ flex: 1.1 }} className={styles.inputTitle}>
                Price
              </div>
              <div
                style={{
                  flex: 1.1,
                }}
                className={styles.inputTitle}
              >
                Discount
              </div>
              <div style={{ flex: 1.85 }} className={styles.inputTitle}>
                Discount type
              </div>
            </div>
            {priceItems.map((item, index) => (
              <BoostCampPriceItem
                key={index}
                item={item}
                index={index}
                priceItems={priceItems}
                setPriceItems={setPriceItems}
              />
            ))}
            <div
              style={{ marginTop: "10px" }}
              onClick={handleAddNewDate}
              className={styles.faqAddQuestionBtn}
            >
              <PlusIcon />
              <div className={styles.faqAddQuestionBtnTitle}>Add new Date</div>
            </div>
          </div>
          <div className={styles.bootcampsSettingsNumbersOfDayWrapper}>
            <div style={{ flex: 1, maxWidth: 350 }}>
              <Input
                type={"number"}
                value={printRecipePrice}
                onChange={onChangePrintRecipePrice}
                label={"Price for the Print Recipe feature ($)"}
                errorBorder={printRecipeErrors.price}
              />
            </div>
          </div>
          <div className={styles.bootcampsSettingsNumbersOfDayWrapper}>
            <div style={{ flex: 1 }}>
              <Input
                value={printRecipeTitle}
                onChange={onChangePrintRecipeTitle}
                label={
                  "Title of the Print Recipe feature (displaying next to checkbox)"
                }
                errorBorder={printRecipeErrors.title}
              />
            </div>
          </div>
          <div className={styles.boostcampsSettingsDescriptionWrapper}>
            <RichTextEditor
              initialValue={printRecipeText}
              setValue={onChangePrintRecipeText}
              label={"Print recipe description"}
              disableMedia
              error={printRecipeErrors.text}
            />
          </div>
          <div className={styles.boostcampsSettingsDescriptionWrapper}>
            <RichTextEditor
              label={"Short description"}
              initialValue={shortDescription}
              setValue={onChangeShortDescription}
              error={shortDescriptionError}
            />
          </div>
        </div>
        <div className={styles.bootcampsSettingsRightContent}>
          {preview ? (
            <img
              src={preview}
              className={clsx(styles.bootcampsSettingsPhoto, {
                [styles.bootcampsSettingsPhotoError]: pictureError,
              })}
              alt={"photo"}
            />
          ) : (
            <div
              className={clsx(styles.bootcampsSettingsPhoto, {
                [styles.bootcampsSettingsPhotoError]: pictureError,
              })}
            >
              <ImgPlaceholder />
            </div>
          )}
          <div className={styles.flex}>
            <label
              htmlFor={"photoInput"}
              className={styles.bootcampsSettingsUploadImgWrapper}
            >
              {isLoadingUploadFile ? (
                <span>
                  <Spinner size={12} />
                </span>
              ) : (
                "Upload New"
              )}
              <input
                id={"photoInput"}
                type={"file"}
                accept="image/*"
                onChange={onSelectFile}
                className={styles.bootcampsSettingsUploadImgBtn}
              />
            </label>
            <div
              onClick={onDeleteFile}
              className={styles.bootcampsSettingsDeleteImgBtn}
            >
              {isLoadingDeleteFile ? (
                <span>
                  <Spinner size={12} />
                </span>
              ) : (
                "Delete photo"
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.faqSectionWrapper}>
        <div className={styles.faqSectionTitleWrapper}>
          <div className={styles.faqTitle}>Meal Plan</div>
          <div className={styles.mealPlansBtnWrapper}>
            <div
              onClick={() => {
                setMealPlans([
                  { title: "Week 1 + Week 3", meal: null },
                  { title: "Week 2 + Week 4", meal: null },
                ]);
              }}
              className={clsx(styles.mealPlanBtn, {
                [styles.mealPlanBtnInactive]: mealPlans?.length === 4,
              })}
            >
              2 week
            </div>
            <div
              onClick={() => {
                setMealPlans([
                  { title: "Week 1", meal: null },
                  { title: "Week 2", meal: null },
                  { title: "Week 3", meal: null },
                  { title: "Week 4", meal: null },
                ]);
              }}
              className={clsx(styles.mealPlanBtn, {
                [styles.mealPlanBtnInactive]: mealPlans?.length === 2,
              })}
            >
              4 week
            </div>
          </div>
        </div>
        {isLoadingBoostcampMeal ? (
          <div className={styles.loaderContainerMini}>
            <Spinner color={"#8C42E3"} size={40} />
          </div>
        ) : (
          <div className={styles.attachMealPlanBtnWrapper}>
            {mealPlans.map((item, index) => {
              return (
                <AttachMealPlanBtn
                  meal={item?.meal}
                  title={item?.title}
                  index={index}
                  key={index}
                  setMealPlans={setMealPlans}
                  error={serverError?.field === `mealPlan[${index}]`}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className={styles.faqSectionWrapper}>
        <div className={styles.faqTitle}>Meal Plan PDF</div>
        <div className={styles.boostcampsSettingsDescriptionWrapper}>
          {mealPlanPdf.map((item, index) => (
            <RecipesItem
              key={index}
              item={item}
              index={index}
              setRecipes={setMealPlanPdf}
              id={`${index}_meal1`}
              error={mealPdfError}
            />
          ))}
        </div>
      </div>
      <div className={styles.faqSectionWrapper}>
        <div className={styles.faqTitle}>Shopping Lists</div>
        <div className={styles.boostcampsSettingsDescriptionWrapper}>
          {recipes.map((item, index) => (
            <RecipesItem
              key={index}
              item={item}
              index={index}
              setRecipes={setRecipes}
              id={`${index}_recipe1`}
              error={shoppingPdfError}
            />
          ))}
        </div>
      </div>
      <div className={styles.faqSectionWrapper}>
        <div className={styles.faqTitle}>Full overview</div>
        <div className={styles.boostcampsSettingsDescriptionWrapper}>
          {/*<TextAreaEditor*/}
          {/*  value={fullOverview}*/}
          {/*  onChange={onChangefullOverview}*/}
          {/*  classes={styles.fullOverview}*/}
          {/*  borderError={fullOverviewError}*/}
          {/*/>*/}
          <RichTextEditor
            initialValue={fullOverview}
            setValue={onChangefullOverview}
            error={fullOverviewError}
          />
        </div>
      </div>
      <div className={styles.faqSectionWrapper}>
        <div className={styles.faqTitle}>FAQ</div>
        {questions.map((item, index) => (
          <QuestionItem
            data={item}
            key={index}
            index={index}
            onDelete={() => onDeleteAnswer(index)}
            onQuestionsChange={handleQuestionsChange}
            onAnswerChange={handleAnswerChange}
          />
        ))}
        <div onClick={onAddAnswer} className={styles.faqAddQuestionBtn}>
          <Add />
          <div className={styles.faqAddQuestionBtnTitle}>Add Question</div>
        </div>
      </div>
      <div className={styles.reviewsSectionWrapper}>
        <div className={styles.reviewsSectionTitle}>Reviews</div>
        <div className={styles.reviewsSectionSubTitle}>Upload reviews</div>
        {reviews.map((item, index) => (
          <ReviewItem
            key={index}
            item={item}
            index={index}
            setReviews={setReviews}
          />
        ))}
        <div
          style={{ marginTop: "30px" }}
          onClick={onAddReview}
          className={styles.faqAddQuestionBtn}
        >
          <PlusIcon />
          <div className={styles.faqAddQuestionBtnTitle}>Add Review</div>
        </div>
      </div>
      <div className={styles.saveSectionWrapper}>
        {" "}
        <div style={{ marginRight: "10px" }}>
          <Button
            onClick={() => onTestBoostCamps()}
            loading={isLoadingTest}
            icon={() => <HouseIcon />}
            title={"Preview"}
            classes={styles.greenBtn}
          />
        </div>
        <div style={{ marginRight: "10px" }}>
          <Button
            loading={isLoadingSave}
            onClick={saveBoostcamp}
            title={"Save"}
          />
        </div>
        <div>
          <Button
            onClick={cancelModal.showModal}
            title={"Cancel"}
            emptyStyle={true}
          />
        </div>
      </div>{" "}
      {savedModal.isShow && <TopModal title="Sucessfully saved" />}
      {cancelModal.isShow && (
        <CustomAlert
          showModal={cancelModal.showModal}
          closeModal={cancelModal.closeModal}
          title={"Are you sure you want to exit?"}
          text={""}
          titleBtn={"Yes"}
          titleSecondBtn={"No"}
          isOpen={cancelModal.isShow}
          onClick={() => navigate("/boostcamps/manage")}
        />
      )}
    </>
  );
};

export default EditBoostCampsMain;
