import {
  ArrowDown,
  PlusSquareIcon,
  SearchIcon,
  Filter,
  CheckBigIcon,
  SmallCheck,
} from 'assets';
import {
  Button,
  Checkbox,
  Header,
  MealPlanItem,
  PageItem,
  RecipeItem,
} from 'components';
import { Manage } from 'layouts';
import React, { useState, useEffect, useRef } from 'react';
import { useCallback } from 'react';
import { Spinner } from 'react-activity';
import { fetchRequest } from 'utils';
import styles from './styles.module.scss';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { useOutsideClick } from 'hooks';
import { useNavigate } from 'react-router-dom';

const limit = 20;

const ManageRecipes = () => {
  const [search, setSearch] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [recipes, setRecipes] = useState([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoadingAllTags, setIsLoadingAllTags] = useState(false);
  const [withTags, setWithTags] = useState(false);
  const [isAllTagsSelected, setIsAllTagsSelected] = useState(true);
  const navigate = useNavigate();
  const filterRef = useRef(null);

  useOutsideClick(filterRef, () => setIsOpenFilter(false));

  const onGetTags = async () => {
    try {
      setIsLoadingAllTags(true);
      const res = await fetchRequest('tags', 'GET');
      if (res?.success) {
        setAllTags(res?.data);
        // setSelectedTags(res?.data);
      }

      setIsLoadingAllTags(false);
    } catch (error) {
      setIsLoadingAllTags(false);
    }
  };

  const onGetRecipes = async (page, limit, q = '', tags = []) => {
    try {
      setIsLoading(true);

      let url = `recipes?page=${page}&limit=${limit}&sort=-createdAt`;
      if (q) {
        url = url + `&q=${q}`;
      }
      if (tags?.length > 0) {
        url = url + `&tags=${tags?.map((item) => item?.id).join('|')}`;
      }
      const res = await fetchRequest(url, 'GET');
      if (res?.success) {
        setPagesCount(Math.ceil(res?.data?.count / limit));
        setRecipes(res?.data?.rows);
        setPage(page);
      }
      // if (!q) {
      //   setSearch("");
      // }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetTags();
  }, []);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetRecipes(1, limit, search, selectedTags);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!search) {
      onGetRecipes(page, limit, '', selectedTags);
    }
  }, [search, selectedTags]);

  const onChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>Recipes</h1>
        <div className={styles.createMealBtnWrapper}>
          <Button
            onClick={() => navigate('/recipes/create')}
            title={'Create New Recipe'}
            icon={() => <PlusSquareIcon />}
          />
        </div>
      </div>
      <Manage>
        <div className={styles.searchContainer}>
          <div className={styles.searchInputWrapper}>
            <SearchIcon />
            <input
              value={search}
              onChange={onChangeSearch}
              className={styles.searchInput}
              placeholder={'Search by name, tag or ingredient...'}
            />
          </div>
          <div ref={filterRef}>
            <div
              onClick={() => setIsOpenFilter((prev) => !prev)}
              className={clsx(styles.filterBtn, {
                [styles.filterBtnActive]: isOpenFilter,
              })}
            >
              <Filter />
            </div>
            {isOpenFilter && (
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 260,
                  damping: 20,
                }}
                className={styles.filterWrapper}
              >
                <div className={styles.title}>Filter by tags</div>

                {isLoadingAllTags ? (
                  <div className={styles.loaderWrapper}>
                    <Spinner size={20} color={'#8C42E3'} />
                  </div>
                ) : (
                  <>
                    <div className={styles.selectAllItem}>
                      <div
                        onClick={() => {
                          if (selectedTags?.length === allTags?.length) {
                            setSelectedTags([]);
                          } else {
                            setSelectedTags(allTags);
                          }
                        }}
                        className={clsx(styles.selectedAllCheckbox, {
                          [styles.selecetAllCheckboxActive]:
                            selectedTags?.length > 0,
                          [styles.selectedAllCheckboxFullActive]:
                            allTags?.length === selectedTags?.length,
                        })}
                      >
                        {allTags?.length === selectedTags?.length && (
                          <SmallCheck style={{ color: '#fff' }} />
                        )}
                      </div>
                      {/* <Checkbox setIsChecked={() => {}} /> */}
                      <span>Select/Unselect All</span>
                    </div>
                    <div className={styles.tagsList}>
                      {allTags?.map((item, index) => {
                        const i = selectedTags.findIndex(
                          (el) => el.id === item.id
                        );
                        return (
                          <div
                            key={index}
                            className={clsx(styles.selectItem, {
                              [styles.selectItemActive]: i !== -1,
                            })}
                          >
                            <Checkbox
                              isChecked={i !== -1}
                              setIsChecked={() => {
                                if (i !== -1) {
                                  const copy = [...selectedTags];
                                  copy.splice(i, 1);
                                  setSelectedTags(copy);
                                } else {
                                  setSelectedTags((prev) => [...prev, item]);
                                }
                              }}
                            />
                            <span>{item?.text}</span>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      onClick={() => setIsOpenFilter(false)}
                      className={styles.showRecipeBtn}
                    >
                      <span>Show recipes</span>
                    </div>
                    <div
                      onClick={() => setSelectedTags([])}
                      className={styles.resetBtn}
                    >
                      Reset all filters
                    </div>
                  </>
                )}
              </motion.div>
            )}
          </div>
        </div>

        <div className={styles.listHeader}>
          <div className={styles.listHeaderCheckedWrapper}>
            {/* <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} /> */}
            <span className={styles.listHeaderCheckedWrapperTitle}>
              Recipe name
            </span>
            {/* <ArrowDown className={styles.listHeaderCheckedWrapperArrow} /> */}
          </div>
          <span className={styles.listHeaderIngredientTitle}>Ingredients</span>
          <span className={styles.listHeaderCookTitle}>Date</span>
          <span className={styles.listHeaderTagsTitle}>Tags</span>
        </div>
        <div className={styles.list}>
          {isLoading && (
            <div className={styles.loader}>
              <Spinner size={30} color={'#8C42E3'} />
            </div>
          )}
          {!isLoading &&
            recipes.map((item, index) => (
              <RecipeItem item={item} key={index} onGetRecipes={onGetRecipes} />
            ))}
        </div>
        <div className={styles.paginationContainer}>
          {Array(pagesCount === 0 ? 1 : pagesCount)
            .fill('_')
            .map((item, index) => (
              <PageItem
                onPress={() => onGetRecipes(index + 1, limit, search)}
                key={index}
                page={index + 1}
                isActive={index + 1 === page}
              />
            ))}
          {pagesCount > 1 && page !== pagesCount && (
            <div
              onClick={() => onGetRecipes(page + 1, limit, search)}
              className={styles.nextBtn}
            >
              Next
            </div>
          )}
        </div>
      </Manage>
    </div>
  );
};

export default ManageRecipes;
