//react
import React, { useEffect, useState } from "react";

//components
import {
  TextAreaEditor,
  Input,
  TasksItem,
  Button,
  RichTextEditor,
} from "components";
import TopModal from "modals/TopModal";

//hooks
import { useToggleModal } from "hooks";

//assets
import { ImgPlaceholder, Upload, PlusIcon } from "assets";

//styles
import styles from "./styles.module.scss";
import ReminderItem from "components/ReminderItem";
import { deleteFile, uploadFile } from "services/file.service";
import { Spinner } from "react-activity";
import { fetchRequest } from "utils";
import { useCallback } from "react";
import clsx from "clsx";

export const DayItem = ({
  item,
  setDaysInfo,
  daysInfo,
  index,
  id,
  maxDays,
}) => {
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [isLoadingDeleteFile, setIsLoadingDeleteFile] = useState(false);
  const [isLoadingSaveDay, setIsLoadingSaveDay] = useState(false);

  const savedModal = useToggleModal();

  //errorsState
  const [titleError, setTitleError] = useState(false);
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [fullDescriptionError, setFullDescriptionError] = useState(false);
  const [pictureError, setPictureError] = useState(false);
  const [isSavedDay, setIsSavedDay] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    let total = 0;
    daysInfo[index]?.tasks?.forEach((el) => (total += +el?.points));
    setTotalPoints(total);
  }, [daysInfo]);

  const updatedShown = () => {
    savedModal.showModal();

    setTimeout(() => {
      savedModal.closeModal();
    }, 3000);
  };

  const onSelectFile = async (e) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      setIsLoadingUploadFile(true);

      const res = await uploadFile(e.target.files[0]);
      setDaysInfo((prev) => {
        const copy = [...prev];
        copy[index].picture = res?.data?.url;
        return copy;
      });
      setIsLoadingUploadFile(false);
    } catch (error) {
      console.log(error);
      setIsLoadingUploadFile(false);
    }
  };

  const onSaveDay = async () => {
    try {
      if (item?.title.length < 3) {
        setTitleError(true);
        return;
      } else {
        setTitleError(false);
      }
      if (item?.shortDescription.length < 3) {
        setShortDescriptionError(true);

        return;
      } else {
        setShortDescriptionError(false);
      }
      if (!item?.picture) {
        setPictureError(true);
        return;
      } else {
        setPictureError(false);
      }
      if (item?.fullDescription.length < 3) {
        setFullDescriptionError(true);
        return;
      } else {
        setFullDescriptionError(false);
      }
      setIsLoadingSaveDay(true);

      const body = {
        days: [{ ...item, order: index }],
      };

      if (daysInfo?.length < maxDays && daysInfo?.length <= index + 1) {
        body?.days.push({
          title: " ",
          shortDescription: " ",
          fullDescription: " ",
          order: index + 1,
          tasks: [
            {
              task: "",
              points: 0,
            },
          ],
          remainders: [],
        });
      }

      const res = await fetchRequest(`boost-camps/${id}`, "PUT", body);
      if (res?.status === 202) {
        setDaysInfo(res?.data?.days);
        setIsSavedDay(true);
        updatedShown();
      }
      setIsLoadingSaveDay(false);
    } catch (error) {
      setIsLoadingSaveDay(false);
      console.log(error);
    }
  };

  const onDeleteImg = async () => {
    try {
      setIsLoadingDeleteFile(true);
      if (!item?.picture) {
        return;
      }
      await deleteFile(item?.picture);
      setDaysInfo((prev) => {
        const copy = [...prev];
        copy[index].picture = undefined;
        return copy;
      });
      setIsLoadingDeleteFile(false);
    } catch (error) {
      setIsLoadingDeleteFile(false);
      console.log(error);
    }
  };

  const onChangeShortDescription = (e) => {
    setDaysInfo((prev) => {
      const copy = [...prev];
      copy[index].shortDescription = e.target.value;
      return copy;
    });
  };

  const onChangeFullDescription = (e) => {
    setDaysInfo((prev) => {
      const copy = [...prev];
      copy[index].fullDescription = e;
      return copy;
    });
  };

  const onChangeTitle = (e) => {
    setDaysInfo((prev) => {
      const copy = [...prev];
      copy[index].title = e.target.value;
      return copy;
    });
  };

  const addTasks = () => {
    const copy = [...daysInfo];
    copy[index].tasks.push({ task: "", points: "" });

    setDaysInfo(copy);
  };

  const addReminders = () => {
    const copy = [...daysInfo];
    copy[index].remainders.push("");

    setDaysInfo(copy);
  };

  const clearDay = () => {
    setDaysInfo((prev) => {
      const copy = [...prev];
      copy[index] = {
        title: "",
        shortDescription: "",
        fullDescription: "",
        photo: "",
        picture: "",
        tasks: [{ task: "", points: "" }],
        remainders: [""],
      };
      return copy;
    });
  };

  // useEffect(() => {
  //   if (!item.photo) {
  //     setDaysInfo((prev) => {
  //       const copy = [...prev];
  //       copy[index].preview = undefined;
  //       return copy;
  //     });
  //     return;
  //   }

  //   const objectUrl = URL.createObjectURL(item.photo);

  //   setDaysInfo((prev) => {
  //     const copy = [...prev];
  //     copy[index].preview = objectUrl;
  //     return copy;
  //   });

  //   return () => URL.revokeObjectURL(objectUrl);
  // }, [item.photo]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <p className={styles.headerTitle}>Day {index + 1}</p>
          <div onClick={clearDay} className={styles.headerClearDay}>
            Clear day content
          </div>
        </div>
        <div className={styles.bootcampsSettingsWrapper}>
          <div className={styles.bootcampsSettingsLeftContent}>
            <Input
              value={item.title}
              onChange={onChangeTitle}
              label={"Title"}
              placeholder={"Tilte"}
              errorBorder={titleError}
            />

            <div className={styles.boostcampsSettingsDescriptionWrapper}>
              <TextAreaEditor
                label={"Short description"}
                value={item?.shortDescription}
                onChange={onChangeShortDescription}
                borderError={shortDescriptionError}
              />
              {/*<RichTextEditor label={'Short description'} initialValue={item?.shortDescription}  setValue={onChangeShortDescription}/>*/}
            </div>
          </div>

          <div className={styles.bootcampsSettingsRightContent}>
            {item?.picture ? (
              <img
                src={item?.picture}
                className={clsx(styles.bootcampsSettingsPhoto, {
                  [styles.bootcampsSettingsPhotoError]: pictureError,
                })}
                alt={"photo"}
              />
            ) : (
              <div
                className={clsx(styles.bootcampsSettingsPhoto, {
                  [styles.bootcampsSettingsPhotoError]: pictureError,
                })}
              >
                <ImgPlaceholder />
              </div>
            )}
            <div className={styles.flex}>
              <label
                htmlFor={`photoInput${index}`}
                className={styles.bootcampsSettingsUploadImgWrapper}
              >
                {isLoadingUploadFile ? (
                  <span>
                    <Spinner size={12} />
                  </span>
                ) : (
                  "Upload New"
                )}
                <input
                  id={`photoInput${index}`}
                  type={"file"}
                  accept="image/*"
                  onChange={onSelectFile}
                  className={styles.bootcampsSettingsUploadImgBtn}
                />
              </label>
              <div
                onClick={onDeleteImg}
                className={styles.bootcampsSettingsDeleteImgBtn}
              >
                {isLoadingDeleteFile ? (
                  <span>
                    <Spinner size={12} />
                  </span>
                ) : (
                  "Delete photo"
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.bootcampsSettingsWrapper}>
          <div className={styles.bootcampsSettingsLeftContent}>
            <div className={styles.sectionTitle}>Full description</div>
            <div className={styles.boostcampsSettingsDescriptionWrapper}>
              {/*<TextAreaEditor*/}
              {/*  classes={styles.fullDescriptionInput}*/}
              {/*  value={item?.fullDescription}*/}
              {/*  onChange={onChangeFullDescription}*/}
              {/*  borderError={fullDescriptionError}*/}
              {/*/>*/}
              <RichTextEditor
                initialValue={item?.fullDescription}
                setValue={onChangeFullDescription}
              />
            </div>
            <div className={styles.saveDayBtnWrapper}>
              <Button
                loading={isLoadingSaveDay}
                onClick={onSaveDay}
                title={isSavedDay ? "Day saved" : `Save the Day ${index + 1}`}
              />
            </div>
          </div>
          <div className={styles.bootcampsSettingsRightContent}>
            <div className={styles.taskHeaderWrapper}>
              <div className={styles.sectionTitle}>Tasks</div>
              <div
                style={{ marginRight: "90px" }}
                className={styles.sectionTitle}
              >
                Points <span style={{ color: "#8c42e3" }}>{totalPoints}</span>
              </div>
            </div>

            <div className={styles.tasksWrapper}>
              {item.tasks.map((e, i) => (
                <TasksItem
                  item={e}
                  daysInfo={daysInfo}
                  setDaysInfo={setDaysInfo}
                  indexOfDays={index}
                  index={i}
                  key={i}
                />
              ))}
              {item?.tasks?.length < 10 && (
                <div onClick={addTasks} className={styles.faqAddQuestionBtn}>
                  <PlusIcon />
                  <div className={styles.faqAddQuestionBtnTitle}>Add Task</div>
                </div>
              )}
            </div>
            <div className={styles.sectionTitle} style={{ marginTop: "20px" }}>
              Daily Reminders
            </div>
            <div className={styles.tasksWrapper}>
              {item.remainders.map((e, i) => (
                <ReminderItem
                  item={e}
                  daysInfo={daysInfo}
                  setDaysInfo={setDaysInfo}
                  indexOfDays={index}
                  index={i}
                  key={i}
                />
              ))}
              {item.remainders.length < 10 && (
                <div
                  onClick={addReminders}
                  className={styles.faqAddQuestionBtn}
                >
                  <PlusIcon />
                  <div className={styles.faqAddQuestionBtnTitle}>
                    Add Reminder
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {savedModal.isShow && <TopModal title="Successfully saved" />}
    </>
  );
};
