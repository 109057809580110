//react
import React from "react";

//components
import { Input } from "components";

//assets
import { Delete } from "assets";

//styles
import styles from "./styles.module.scss";

const QuestionItem = ({
  data,
  onDelete,
  index,
  onQuestionsChange,
  onAnswerChange,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Question {index + 1}</div>
      <div className={styles.flex}>
        <div className={styles.answerBlock}>
          <Input
            value={data.question}
            onChange={(e) => onQuestionsChange(e, index)}
            placeholder={"Question"}
            type={"text"}
            className={styles.question}
          />
          <Input
            value={data.answer}
            onChange={(e) => onAnswerChange(e, index)}
            placeholder={"Answer"}
            type={"text"}
            className={styles.question}
          />
        </div>
        <div onClick={onDelete} className={styles.deleteBtn}>
          <Delete />
        </div>
      </div>
    </div>
  );
};

export default QuestionItem;
