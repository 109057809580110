import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { fetchRequest } from "utils";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-activity";
import { FaqItem, ReviewItemPreview, Counter } from "components";
import { motion } from "framer-motion";
import { useRef } from "react";
import { format } from "date-fns";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import {
  AmericanexpressIcon,
  Arrow,
  ArrowUp,
  DinnerIcon,
  DiscoverIcon,
  JcbIcon,
  LockIcon,
  MastercardIcon,
  StripeIcon,
  UnionIcon,
  UnitedStatesIcon,
  VisaIcon,
  AustraliaIcon,
} from "../../../assets";
import DaysInformation from "../../../components/DaysInformation";
dayjs.extend(dayOfYear);

const PreviewBoostcamp = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [boostcamp, setBoostcamp] = useState(null);
  const [mondaysList, setMondaysList] = useState([]);
  const [isOpenMondayList, setIsOpenModaysList] = useState(false);
  const [selectedMonday, setSelectedMonday] = useState();
  const [price, setPrice] = useState();
  const [fullPrice, setFullPrice] = useState();
  const [countryItems, setCountryItems] = useState([
    { icon: <UnitedStatesIcon />, title: "USD" },
    { icon: <AustraliaIcon />, title: "AUD" },
  ]);
  const [activeCountry, setActiveCountry] = useState(0);
  const [isCountryListOpen, setIsCountryListOpen] = useState(false);
  const [currency, setCurrency] = useState("AUD");

  useEffect(() => {
    setCurrency(activeCountry === 0 ? "USD" : "AUD");
  }, [activeCountry]);

  const params = useParams();

  const mondaysRef = useRef();
  const currencyRef = useRef();

  const onGetBoostCamp = async () => {
    try {
      setIsLoading(true);
      const res = await fetchRequest(`boost-camps/${params?.id}`, "GET");

      if (res.status === 200) {
        setBoostcamp(res.data);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  function getNextDayOfTheWeek(
    dayName,
    excludeToday = true,
    refDate = new Date()
  ) {
    const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].indexOf(
      dayName.slice(0, 3).toLowerCase()
    );
    if (dayOfWeek < 0) return refDate;
    refDate.setHours(0, 0, 0, 0);
    refDate.setDate(
      refDate.getDate() +
        +!!excludeToday +
        ((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
    );
    return refDate;
  }

  const getNext8Monday = (isFirst) => {
    let arr = [];
    let refDate = new Date();
    for (let i = 0; i < 4; i++) {
      refDate = getNextDayOfTheWeek("Monday", i > 0 ? true : false, refDate);
      arr.push(refDate && new Date(refDate));
    }
    const copy = arr.map((item, index) => ({
      time: item,
      price: calculatePriceHandler(item),
    }));
    const copyFiltered = copy.filter((item) => !!item.price);

    console.log(isFirst, copyFiltered);
    if ((isFirst || !selectedMonday) && copyFiltered?.length > 0) {
      setSelectedMonday(JSON.stringify(copy[0].time));
    }
    setMondaysList(copyFiltered);
  };

  const calculatePriceHandler = (startDate) => {
    const firstDate = new Date(startDate);
    const today = new Date();
    let weeksBetweenDates = 0;
    if (dayjs(firstDate).dayOfYear() !== dayjs(today).dayOfYear()) {
      weeksBetweenDates = Math.ceil(
        (dayjs(firstDate).dayOfYear() - dayjs(today).dayOfYear()) / 7
      );

      if (weeksBetweenDates === 0 && [1, 2, 3].includes(dayjs(today).day())) {
        weeksBetweenDates = 1;
      }
      if (weeksBetweenDates > 0 && ![1, 2, 3].includes(dayjs(today).day())) {
        weeksBetweenDates -= 1;
      }
    }

    const currentPrice = boostcamp?.prices[weeksBetweenDates];
    let price =
      currentPrice?.discount?.discountPrice || currentPrice?.price || 0;

    let fullPrice = currentPrice?.price || 0;
    // if (currency === "USD") {
    //   price = +(price * 0.627818).toFixed(1);
    //   fullPrice = +(fullPrice * 0.627818).toFixed(1);
    // }
    return price;
  };

  const calculatePrice = (startDate) => {
    const firstDate = new Date(startDate);
    const today = new Date();
    let weeksBetweenDates = 0;
    if (dayjs(firstDate).dayOfYear() !== dayjs(today).dayOfYear()) {
      weeksBetweenDates = Math.ceil(
        (dayjs(firstDate).dayOfYear() - dayjs(today).dayOfYear()) / 7
      );

      if (weeksBetweenDates === 0 && [1, 2, 3].includes(dayjs(today).day())) {
        weeksBetweenDates = 1;
      }
      if (weeksBetweenDates > 0 && ![1, 2, 3].includes(dayjs(today).day())) {
        weeksBetweenDates -= 1;
      }
    }

    const currentPrice = boostcamp?.prices[weeksBetweenDates];
    let price =
      currentPrice?.discount?.discountPrice || currentPrice?.price || 0;

    let fullPrice = currentPrice?.price || 0;
    // if (currency === "USD") {
    //   price = +(price * 0.627818).toFixed(1);
    //   fullPrice = +(fullPrice * 0.627818).toFixed(1);
    // }
    setPrice(+price);
    // dispatch(setSelectedPrice(+price));
    setFullPrice(+fullPrice);
  };

  useEffect(() => {
    onGetBoostCamp();
  }, []);

  useEffect(() => {
    console.log("boostcamp && selectedMonday", boostcamp, selectedMonday);
    boostcamp && selectedMonday && calculatePrice(JSON.parse(selectedMonday));
  }, [boostcamp, selectedMonday, currency]);

  useEffect(() => {
    getNext8Monday(true);
  }, []);

  useEffect(() => {
    getNext8Monday(false);
  }, [boostcamp, currency]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <div className={styles.swrapper}>
        <div className={styles.header}>
          <div onClick={goBack} className={styles.goBack}>
            <Arrow /> back{" "}
          </div>
          <div
            onClick={() => setActiveTab(0)}
            className={clsx(styles.btn, {
              [styles.activeBtn]: activeTab === 0,
            })}
          >
            MAIN PAGE PREVIEW
          </div>
          <div
            onClick={() => setActiveTab(1)}
            className={clsx(styles.btn, {
              [styles.activeBtn]: activeTab === 1,
            })}
          >
            DAYS PREVIEW
          </div>
        </div>
        <div className={styles.loaderWrapper}>
          <Spinner color={"#8c42e3"} size={25} />
        </div>
      </div>
    );
  }

  if (activeTab === 1) {
    return (
      <div className={styles.swrapper}>
        <div className={styles.header}>
          <div onClick={goBack} className={styles.goBack}>
            <Arrow /> back{" "}
          </div>
          <div
            onClick={() => setActiveTab(0)}
            className={clsx(styles.btn, {
              [styles.activeBtn]: activeTab === 0,
            })}
          >
            MAIN PAGE PREVIEW
          </div>
          <div
            onClick={() => setActiveTab(1)}
            className={clsx(styles.btn, {
              [styles.activeBtn]: activeTab === 1,
            })}
          >
            DAYS PREVIEW
          </div>
        </div>
        <DaysInformation boostcamp={boostcamp} />
      </div>
    );
  }

  if (activeTab === 0) {
    return (
      <div className={styles.swrapper}>
        <div className={styles.header}>
          <div onClick={goBack} className={styles.goBack}>
            <Arrow /> back{" "}
          </div>
          <div
            onClick={() => setActiveTab(0)}
            className={clsx(styles.btn, {
              [styles.activeBtn]: activeTab === 0,
            })}
          >
            MAIN PAGE PREVIEW
          </div>
          <div
            onClick={() => setActiveTab(1)}
            className={clsx(styles.btn, {
              [styles.activeBtn]: activeTab === 1,
            })}
          >
            DAYS PREVIEW
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.contentContainer}>
            <div className={styles.leftContainer}>
              <div className={clsx(styles.blockItem, styles.gradientBlock)}>
                <h3 className={styles.blockTitle}>Full Overview</h3>
                <div className={"html-wrapper"}>
                  <div
                    className={styles.shortOverview}
                    dangerouslySetInnerHTML={{
                      __html: boostcamp?.overview || "",
                    }}
                  />
                </div>
              </div>
              <div className={styles.blockItem}>
                <h3
                  style={{ marginBottom: "20px" }}
                  className={styles.blockTitle}
                >
                  Frequently Asked Questions
                </h3>
                {!!boostcamp?.faq?.length &&
                  boostcamp?.faq?.map((item, index) => (
                    <FaqItem
                      key={index}
                      question={item?.question}
                      answer={item?.answer}
                    />
                  ))}
              </div>
              {!!boostcamp?.reviews?.length && (
                <div className={styles.blockItem}>
                  <h3
                    style={{ marginBottom: "20px" }}
                    className={styles.blockTitle}
                  >
                    Reviews
                  </h3>

                  {boostcamp?.reviews?.map((item, index) => (
                    <ReviewItemPreview
                      avatar={item?.avatar}
                      city={item?.city}
                      name={item?.name}
                      rating={item?.rating}
                      text={item?.text}
                      key={index}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className={styles.rightContainer}>
              <div className={styles.priceBlockWrapper}>
                <img src={boostcamp?.picture} className={styles.img} />

                <div className={styles.priceContainer}>
                  <span className={styles.currentPrice}>${price}</span>
                  {price != fullPrice && (
                    <span className={styles.discoutPrice}>${fullPrice}</span>
                  )}
                  <div
                    ref={currencyRef}
                    // onClick={() => setIsCountryListOpen((prev) => !prev)}
                    className={styles.selectCountry}
                  >
                    {countryItems[activeCountry].icon}
                    <span>{countryItems[activeCountry].title}</span>
                    {/* <ArrowUp
                      style={{
                        transform: isCountryListOpen
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                        transition: "all 0.2s linear",
                      }}
                    />
                    {isCountryListOpen && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        transition={{ duration: 0.2 }}
                        className={styles.countryList}
                      >
                        {countryItems.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => setActiveCountry(index)}
                              className={styles.selectCountry}
                            >
                              {countryItems[index].icon}
                              <span>{countryItems[index].title}</span>
                            </div>
                          );
                        })}
                      </motion.div>
                    )} */}
                  </div>
                </div>

                {price != fullPrice && (
                  <div className={styles.timerWrapper}>
                    <p>
                      Save ${(fullPrice - price).toFixed(1)}!!! Limited offer
                      only:
                    </p>
                    <div className={styles.counterWrapper}>
                      <Counter />
                    </div>
                  </div>
                )}
                <p className={styles.selectedLabel}>Choose a Start Date</p>
                <div
                  ref={mondaysRef}
                  className={clsx(styles.selectMondayBtn, {
                    [styles.selectMondayBtnOpen]: isOpenMondayList,
                  })}
                  onClick={() => setIsOpenModaysList((prev) => !prev)}
                >
                  <p className={styles.mondayTitle}>
                    {selectedMonday &&
                      format(
                        new Date(JSON.parse(selectedMonday)),
                        "EEEE, MMMM, d, yyyy"
                      )}
                  </p>
                  <span className={styles.mondayPrice}>${price}</span>

                  <ArrowUp style={{ marginLeft: "10px" }} />
                  {isOpenMondayList && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      transition={{ duration: 0.2 }}
                      className={styles.modayListWrapper}
                    >
                      {mondaysList?.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              setSelectedMonday(JSON.stringify(item.time));

                              setIsOpenModaysList(false);
                            }}
                            className={clsx(styles.selectMondayBtn, {
                              [styles.selectMondayBtnActive]:
                                item.time === selectedMonday,
                            })}
                            key={index}
                          >
                            <p className={clsx(styles.mondayTitle)}>
                              {format(
                                new Date(item.time),
                                "EEEE, MMMM, d, yyyy"
                              )}
                            </p>
                            <span className={styles.mondayPrice}>
                              ${item?.price}
                            </span>
                            {/* {item.time === selectedMonday && <CheckBigIcon />} */}
                          </div>
                        );
                      })}
                    </motion.div>
                  )}
                </div>
                <div className={styles.signBtn}>Buy</div>
                <div className={styles.stripeBlock}>
                  <LockIcon />
                  <p>
                    Guaranteed safe and secure
                    <br /> checkout
                  </p>
                  <StripeIcon />
                </div>
                <div className={styles.paymentMehods}>
                  <VisaIcon />
                  <MastercardIcon />
                  <AmericanexpressIcon />
                  <JcbIcon />
                  <DinnerIcon />
                  <UnionIcon />
                  <DiscoverIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PreviewBoostcamp;
