//react
import React, { useRef } from "react";

//styles
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useAutosizeTextArea } from "hooks";

const TextAreaResizableEditor = ({
  label,
  value,
  onChange,
  classes,
  classesInner,
  error,
  placeholder = "Type your message",
  borderError = false,
}) => {
  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, value);

  return (
    <div className={clsx(styles.input, classesInner)}>
      {label && <h4>{label}</h4>}
      <textarea
        className={clsx(styles.input__main, classes, {
          [styles.error]: error,
          [styles.borderError]: borderError,
        })}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        rows={1}
        ref={textAreaRef}
      />
    </div>
  );
};

export default TextAreaResizableEditor;
