import { DotsIcon, FileSearch, Delete, PencilSmallIcon } from 'assets';
import { Checkbox } from 'components';
import React, { useState, useRef } from 'react';
import styles from './styles.module.scss';
import { useOutsideClick, useToggleModal } from 'hooks';
import { fetchRequest } from 'utils';
import { Spinner } from 'react-activity';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from 'modals';

const MealPlanItem = ({
  item,
  onGetMealPlans,
  isSelect = false,
  onClick = () => {},
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const deleteModal = useToggleModal();

  const ref = useRef(null);

  useOutsideClick(ref, () => setIsOpenMenu(false));

  const onDelete = async () => {
    try {
      setIsLoadingDelete(true);
      const res = await fetchRequest(`meal-plans/${item.id}`, 'DELETE');
      if (res?.status === 204) {
        onGetMealPlans(1, 25, '');
      }
      setIsLoadingDelete(false);
    } catch (error) {
      setIsLoadingDelete(false);
    }
  };
  const navigate = useNavigate();

  return (
    <div
      ref={ref}
      onClick={() => onClick(item)}
      style={isSelect ? { cursor: 'pointer' } : {}}
      className={styles.wrapper}
    >
      {/* <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} /> */}
      <div className={styles.descriptionWrapper}>
        <p className={styles.title}>{item?.title}</p>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: item?.description || '',
          }}
        />
        {/* <p className={styles.description}>{item?.description}</p> */}
      </div>
      <div className={styles.ingredientsContainer}>
        <div className={styles.ingredientsWrapper}>
          <FileSearch color={'#8c42e3'} />
          <span className={styles.ingredientsWrapperTitle}>
            {item?.ingredients} ingredients
          </span>
        </div>
        {!isSelect && (
          <DotsIcon
            onClick={() => setIsOpenMenu((prev) => !prev)}
            className={styles.dotsMenu}
          />
        )}
      </div>
      {isOpenMenu && (
        <motion.div
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}
          className={styles.menuWrapper}
        >
          <div
            onClick={() => navigate(`/mealplans/edit/${item?.id}`)}
            className={styles.menuButton}
          >
            <PencilSmallIcon width={19} height={19} />
            <span style={{ marginLeft: '7px' }}>Edit</span>
          </div>
          <div
            onClick={() => {
              setIsOpenMenu(false);
              deleteModal.showModal();
            }}
            className={styles.menuButton}
          >
            {isLoadingDelete ? (
              <div className={styles.deleteLoader}>
                <Spinner color={'#EE3758'} size={11} />
              </div>
            ) : (
              <>
                <Delete />
                <span style={{ color: '#EE3758' }}>Delete</span>
              </>
            )}
          </div>
        </motion.div>
      )}
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={'Are you sure you want to delete?'}
          onClick={onDelete}
          loading={isLoadingDelete}
        />
      )}
    </div>
  );
};

export default MealPlanItem;
