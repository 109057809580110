import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import Modal from "react-modal";
import { SearchIcon, SmallCheck } from "assets";
import { useCallback } from "react";
import { Spinner } from "react-activity";
import clsx from "clsx";
import { Checkbox, RecipeItem } from "components";
import { fetchRequest } from "utils";

const limit = 20;
const AddRecipeModal = ({
  isOpen,
  setIsOpen,
  selectedDay,
  selectedMeal,
  setWeek,
  week,
}) => {
  const [search, setSearch] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoadingAllTags, setIsLoadingAllTags] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagesCount, setPagesCount] = useState(1);
  const [recipes, setRecipes] = useState([]);
  const [page, setPage] = useState(1);
  const onChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const onSelect = (item) => {
    const copy = [...week];
    copy[selectedDay].meal[selectedMeal].recipes = [
      ...copy[selectedDay].meal[selectedMeal].recipes,
      item,
    ];
    setWeek(copy);
    setIsOpen(false);
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);
  const onGetTags = async () => {
    try {
      setIsLoadingAllTags(true);
      const res = await fetchRequest("tags", "GET");
      if (res?.success) {
        setAllTags(res?.data);
        // setSelectedTags(res?.data);
      }

      setIsLoadingAllTags(false);
    } catch (error) {
      setIsLoadingAllTags(false);
    }
  };

  const onGetRecipes = async (page, limit, q = "", tags = []) => {
    try {
      setIsLoading(true);

      let url = `recipes?page=${page}&limit=${limit}`;
      if (q) {
        url = url + `&q=${q}`;
      }
      if (tags?.length > 0) {
        url = url + `&tags=${tags?.map((item) => item?.id).join("|")}`;
      }
      const res = await fetchRequest(url, "GET");
      if (res?.success) {
        setPagesCount(Math.ceil(res?.data?.count / limit));
        setRecipes(res?.data?.rows);
        setPage(page);
      }
      // if (!q) {
      //   setSearch("");
      // }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetTags();
  }, []);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetRecipes(1, limit, search, selectedTags);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!search) {
      onGetRecipes(page, limit, "", selectedTags);
    }
  }, [search, selectedTags]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className={styles.modal}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalInner}>
        <p className={styles.title}>Add Recipe</p>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            value={search}
            onChange={onChangeSearch}
            className={styles.searchInput}
            placeholder={"Search by name..."}
          />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.filterContainer}>
            <div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
              className={styles.filterWrapper}
            >
              <div className={styles.title}>Filter by tags</div>

              {isLoadingAllTags ? (
                <div className={styles.loaderWrapper}>
                  <Spinner size={20} color={"#8C42E3"} />
                </div>
              ) : (
                <>
                  <div className={styles.selectAllItem}>
                    <div
                      onClick={() => {
                        if (selectedTags?.length === allTags?.length) {
                          setSelectedTags([]);
                        } else {
                          setSelectedTags(allTags);
                        }
                      }}
                      className={clsx(styles.selectedAllCheckbox, {
                        [styles.selecetAllCheckboxActive]:
                          selectedTags?.length > 0,
                        [styles.selectedAllCheckboxFullActive]:
                          allTags?.length === selectedTags?.length,
                      })}
                    >
                      {allTags?.length === selectedTags?.length && (
                        <SmallCheck style={{ color: "#fff" }} />
                      )}
                    </div>
                    {/* <Checkbox setIsChecked={() => {}} /> */}
                    <span>Select/Unselect All</span>
                  </div>
                  <div className={styles.tagsList}>
                    {allTags?.map((item, index) => {
                      const i = selectedTags.findIndex(
                        (el) => el.id === item.id
                      );
                      return (
                        <div
                          key={index}
                          className={clsx(styles.selectItem, {
                            [styles.selectItemActive]: i !== -1,
                          })}
                        >
                          <Checkbox
                            isChecked={i !== -1}
                            setIsChecked={() => {
                              if (i !== -1) {
                                const copy = [...selectedTags];
                                copy.splice(i, 1);
                                setSelectedTags(copy);
                              } else {
                                setSelectedTags((prev) => [...prev, item]);
                              }
                            }}
                          />
                          <span>{item?.text}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    // onClick={() => setIsOpenFilter(false)}
                    className={styles.showRecipeBtn}
                  >
                    <span>Show recipes</span>
                  </div>
                  <div
                    onClick={() => setSelectedTags([])}
                    className={styles.resetBtn}
                  >
                    Reset all filters
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.recipesContainer}>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <Spinner color="#8C42E3" size={30} />
              </div>
            )}
            {!isLoading &&
              recipes?.map((item, index) => (
                <RecipeItem
                  isSelect={true}
                  item={item}
                  key={item?.id}
                  onClick={(item) => onSelect(item)}
                />
              ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddRecipeModal;
