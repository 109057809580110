import React, { Suspense, lazy, useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./styles.module.scss";

import {
  ChecklistIcon,
  SleepIcon,
  WaterIcon,
  FastingIcon,
  ChevronArrow,
} from "assets";
import { Button } from "components";
import { useMemo } from "react";
import moment from "moment";
import { fetchRequest } from "utils";

const StatisticChartsLazy = lazy(() =>
  import("../../components/StatisticCharts")
);

const UserGraph = ({ user }) => {
  const [averageSleep, setAverageSleep] = useState(0);
  const [averageWater, setAverageWater] = useState({ l: 0, flOz: 0 });
  const [averageWeight, setAverageWeight] = useState({ kg: 0, ibs: 0 });
  const [averageFasting, setAverageFasting] = useState(0);

  const [trackingOption, setTrackingOption] = useState("day");
  const [activeTrackingType, setActiveTrackingType] = useState("sleep");
  const [activeTrackingBtn, setActiveTrackingBtn] = useState(0);

  const [statistics, setStatistics] = useState(null);
  const [isLoadingStats, setIsLoadingStats] = useState(null);

  const onGetUserStats = async (id, type) => {
    try {
      setIsLoadingStats(true);

      const res = await fetchRequest(
        `progress/stats/${id}?type=${type}`,
        "GET"
      );
      console.log({ res, type });
      setStatistics(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingStats(false);
    }
  };

  useEffect(() => {
    if (trackingOption !== "month" && user?.testMode) {
      setTrackingOption("month");
    } else if (user?.id) {
      onGetUserStats(user.id, trackingOption);
    }
  }, [trackingOption, user?.id]);

  const currentDate = useMemo(
    () =>
      trackingOption === "day"
        ? moment().format("D MMMM Y")
        : trackingOption === "week"
        ? `${moment().startOf("isoWeek").format("D MMMM")} - ${moment()
            .endOf("isoWeek")
            .format("D MMMM Y")}`
        : // : moment().format("MMMM Y") `${moment().format("MMMM Y")} - ${moment()
          // .format("MMMM Y")}`,
          "",
    [trackingOption]
  );

  useEffect(() => {
    if (statistics) {
      setAverageSleep(statistics?.averageStats?.sleep);
      setAverageWater(statistics?.averageStats?.water);
      setAverageWeight({
        kg: statistics?.averageStats?.kg,
        ibs: statistics?.averageStats?.ibs,
      });
      setAverageFasting(statistics?.averageStats?.eat);
    }
  }, [statistics]);

  return (
    <div className={styles.wrapper}>
      {!user?.testMode && (
        <div className={styles.header}>
          <div className={styles.optionsBtnContainer}>
            <Button
              onClick={() => setTrackingOption("day")}
              title={"Day"}
              emptyStyle={trackingOption !== "day"}
              classes={styles.optionsBtn}
            />
            <Button
              onClick={() => setTrackingOption("week")}
              title={"Week"}
              emptyStyle={trackingOption !== "week"}
              classes={styles.optionsBtn}
            />
            <Button
              onClick={() => setTrackingOption("month")}
              title={"Month"}
              emptyStyle={trackingOption !== "month"}
              classes={styles.optionsBtn}
            />
          </div>
          <div className={styles.dates}>
            {/* <div className={styles.datesBtn}>
            <ChevronArrow width={28} height={28} />
          </div> */}
            <p className={styles.datesTitle}>{currentDate}</p>
            {/* <div className={styles.datesBtn} style={{ rotate: "180deg" }}>
            <ChevronArrow width={28} height={28} />
          </div> */}
          </div>
        </div>
      )}

      <div className={styles.widgetsContainer}>
        <div
          onClick={() => setActiveTrackingType("sleep")}
          style={{
            borderColor:
              activeTrackingType === "sleep" ? "#8c42e326" : "transparent",
          }}
          className={clsx(styles.widgetWrapper, {
            [styles.activeWidgetWrapper]: activeTrackingType === "sleep",
          })}
        >
          <div
            style={{ backgroundColor: "#8c42e326" }}
            className={styles.iconWrapper}
          >
            <SleepIcon />
          </div>
          <p className={styles.title}>
            {(averageSleep || 0) > 8
              ? ">8"
              : (+averageSleep?.toFixed(1) || 0) < 3 &&
                (averageSleep?.toFixed(1) || 0) !== 0
              ? "<3"
              : averageSleep?.toFixed(1) || 0}{" "}
            h
          </p>
          <span className={styles.subtitle}>Average sleep time</span>
        </div>
        <div
          onClick={() => setActiveTrackingType("water")}
          style={{
            borderColor:
              activeTrackingType === "water"
                ? "rgba(121, 220, 213, 0.2)"
                : "transparent",
          }}
          className={clsx(styles.widgetWrapper, {
            [styles.activeWidgetWrapper]: activeTrackingType === "water",
          })}
        >
          <div
            style={{ backgroundColor: "rgba(121, 220, 213, 0.2)" }}
            className={styles.iconWrapper}
          >
            <WaterIcon />
          </div>
          <p className={styles.title}>
            {activeTrackingBtn === 0
              ? averageWater?.flOz?.toFixed(1)
              : (+averageWater?.l?.toFixed(1) || 0) > 3
              ? ">3"
              : (activeTrackingBtn === 0
                  ? averageWater?.flOz?.toFixed(1)
                  : averageWater?.l?.toFixed(1)) || 0}{" "}
            {activeTrackingBtn === 0 ? "Fl Oz" : "l"}
          </p>
          <span className={styles.subtitle}>Water consumption</span>
        </div>
        <div
          onClick={() => setActiveTrackingType("eat")}
          style={{
            borderColor:
              activeTrackingType === "eat"
                ? "rgba(240, 81, 34, 0.2)"
                : "transparent",
          }}
          className={clsx(styles.widgetWrapper, {
            [styles.activeWidgetWrapper]: activeTrackingType === "eat",
          })}
        >
          <div
            style={{ backgroundColor: "rgba(240, 81, 34, 0.2)" }}
            className={styles.iconWrapper}
          >
            <FastingIcon />
          </div>
          <p className={styles.title}> {averageFasting?.toFixed(1) || 0} h</p>
          <span className={styles.subtitle}>Fasting</span>
        </div>
        <div
          onClick={() => setActiveTrackingType("weight")}
          style={{
            borderColor:
              activeTrackingType === "weight"
                ? "rgba(96, 192, 85, 0.2)"
                : "transparent",
          }}
          className={clsx(styles.widgetWrapper, {
            [styles.activeWidgetWrapper]: activeTrackingType === "weight",
          })}
        >
          <div
            style={{ backgroundColor: "rgba(96, 192, 85, 0.2)" }}
            className={styles.iconWrapper}
          >
            <ChecklistIcon />
          </div>
          <p className={styles.title}>
            {(activeTrackingBtn === 0
              ? averageWeight?.ibs?.toFixed(1)
              : averageWeight?.kg?.toFixed(1)) || 0}{" "}
            {activeTrackingBtn === 0 ? "lbs" : "kg"}
          </p>
          <span className={styles.subtitle}>Weight loss since start</span>
        </div>
      </div>
      {/* <TrackingBtn /> */}
      {trackingOption !== "day" && activeTrackingType !== "body" && (
        <div className={styles.graphContainer}>
          <Suspense>
            <StatisticChartsLazy
              activeTrackingType={activeTrackingType}
              trackingOption={trackingOption}
              statistics={statistics}
              activeTrackingBtn={activeTrackingBtn}
              isTestUser={user?.testMode}
            />
          </Suspense>
        </div>
      )}
    </div>
  );
};

export default UserGraph;
