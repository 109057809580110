import { TokenLS } from "helpers";
import { toast } from "react-toastify";
const fetchRequest = async (url, method, body, formData = false) => {
  let token = TokenLS.getToken("accessToken") ?? null;

  let headers = {};

  try {
    if (formData === true) {
      headers = {};
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
      method: method.toUpperCase(),
      headers: headers,
      body: formData === true ? body : JSON.stringify(body),
      // body: JSON.stringify(body),
    });
    if (response.status === 204) {
      return { status: response.status };
    } else if (response.status !== 500) {
      const data = await response.json();
      if (data?.success === false) {
        toast.error(
          data?.field ? `${data?.message}` : "Something went wrong",
          {}
        );
      }

      return { ...data, status: response.status };
    } else if (response.status === 500) {
      alert("Sorry the server is not available right now please try later");
    }
  } catch (e) {
    toast.error(
      e?.response?.message ? `${e?.response?.message}` : "Something went wrong",
      {}
    );
    console.log(e?.response?.message);
  }
};

export default fetchRequest;
