import React, { FC } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useWindowDimensions } from "hooks";
import { CloseDayIcon, CompleteIcon } from "assets";

const DayItem = ({
  status = "closed",
  image = "",
  title = "",
  description = "",
  onClick,
}) => {
  const { width } = useWindowDimensions();
  const iconWidth = width <= 768 ? "30px" : "auto";
  return (
    <div
      onClick={onClick}
      className={
        status === "opened-completed"
          ? styles.activeCompletedWrapper
          : status === "current"
          ? styles.wrapper
          : status === "opened"
          ? styles.activeWrapper
          : status === "closed"
          ? styles.blurWrapper
          : status === "complete"
          ? styles.wrapper
          : ""
      }
    >
      <div className={status === "closed" ? styles.imgWrapper : ""}>
        <img
          src={image}
          alt={"preview"}
          className={clsx(styles.preview, {
            [styles.blur]: status === "closed",
          })}
        />
      </div>

      {(status === "complete" || status === "opened-completed") && (
        <div className={styles.completeDayWrapper}>
          <CompleteIcon style={{ width: "auto" }} />
        </div>
      )}

      {status === "closed" && (
        <div className={styles.closeDayWrapper}>
          <CloseDayIcon style={{ width: "auto" }} />
        </div>
      )}

      <div className={styles.contentContainer}>
        <h5
          style={{
            color:
              status === "complete" || status === "opened-completed"
                ? "#60c055"
                : "#131313",
          }}
        >
          {title}
        </h5>
        <p className={status === "closed" ? styles.blur : ""}>{description}</p>
      </div>
    </div>
  );
};

export default DayItem;
