import React, { useState, useCallback, useEffect } from "react";
import { Manage } from "layouts";
import {
  Button,
  Checkbox,
  IngredientItem,
  Input,
  InputSelect,
  PageItem,
} from "components";
import { PlusSquareIcon, SearchIcon, ArrowDown, CrossIcon } from "assets";
import styles from "./styles.module.scss";
import Modal from "react-modal";
import { fetchRequest } from "utils";
import { Spinner } from "react-activity";

const limit = 25;

const CreateIngredient = ({ isOpen, setIsOpen, onGetIngredients }) => {
  const [name, setName] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const onGetCategories = async () => {
    try {
      setIsLoadingCategories(true);
      const res = await fetchRequest("categories", "GET");
      if (res?.success) {
        setCategories(res?.data);
      }
      setIsLoadingCategories(false);
    } catch (error) {
      setIsLoadingCategories(false);
    }
  };

  const onCreateIngredient = async () => {
    try {
      setIsLoadingCreate(true);
      const body = {
        name: name.trim(),
        category: category.id,
      };
      const res = await fetchRequest("ingredients", "POST", body);
      if (res?.success) {
        onGetIngredients(1, limit, "");
        setName("");
        setCategory("");
        setIsOpen(false);
      }
      setIsLoadingCreate(false);
    } catch (error) {
      setIsLoadingCreate(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      onGetCategories();
    }
  }, [isOpen]);

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className={styles.modal}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalInner}>
        <p className={styles.title}>New Ingredient</p>
        <div onClick={() => setIsOpen(false)} className={styles.closeBtn}>
          <CrossIcon />
        </div>
        {isLoadingCategories && (
          <div className={styles.loader}>
            <Spinner color={"#8C42E3"} size={30} />
          </div>
        )}
        {!isLoadingCategories && (
          <>
            <Input
              value={name}
              onChange={onChangeName}
              label={"Ingredient Name"}
              placeholder={"Ingredient Name"}
              classesInner={styles.name}
            />
            <InputSelect
              value={category?.text}
              onChange={setCategory}
              label={"Category"}
              placeholder={"Category"}
              data={categories}
              classesInner={styles.category}
            />
            <Button
              title={"Create"}
              onClick={onCreateIngredient}
              disabled={!category?.id || name?.length < 2}
              loading={isLoadingCreate}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

const Ingredients = () => {
  const [search, setSearch] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isOpenNewModal, setIsOpenNewModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [ingredients, setIngredients] = useState([]);

  const onGetIngredients = async (page = 1, limit, q = "") => {
    try {
      setIsLoading(true);
      let url = `ingredients?page=${page}&limit=${limit}&sort=-createdAt`;
      if (q) {
        url = url + `&q=${q}`;
      }
      const res = await fetchRequest(url, "GET");
      if (res?.success) {
        setPagesCount(Math.ceil(res?.data?.count / limit));
        setIngredients(res?.data?.rows);
        setPage(page);
      }
      // if (!q) {
      //   setSearch("");
      // }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (search) {
        onGetIngredients(1, limit, search);
      }
    }, 400);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (!search) {
      onGetIngredients(page, limit, "");
    }
  }, [search]);

  const onChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  return (
    <div className={styles.wrapper}>
      <CreateIngredient
        isOpen={isOpenNewModal}
        setIsOpen={setIsOpenNewModal}
        onGetIngredients={onGetIngredients}
      />
      <div className={styles.header}>
        <h1 className={styles.title}>Ingredients</h1>
        <div className={styles.createMealBtnWrapper}>
          <Button
            onClick={() => setIsOpenNewModal(true)}
            title={"New Ingredient"}
            icon={() => <PlusSquareIcon />}
          />
        </div>
      </div>
      <Manage>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            value={search}
            onChange={onChangeSearch}
            className={styles.searchInput}
            placeholder={"Search by name, category..."}
          />
        </div>
        <div className={styles.listHeader}>
          <div className={styles.listHeaderCheckedWrapper}>
            {/* <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} /> */}
            <span className={styles.listHeaderCheckedWrapperTitle}>
              Ingredient Name
            </span>
          </div>
          <span className={styles.listHeaderShoppingTitle}>Category</span>
          <div className={styles.listHeaderDateWrapper}>
            <span className={styles.listHeaderDateTitle}>Date Created</span>
            {/* <ArrowDown className={styles.listHeaderArrow} /> */}
          </div>
        </div>
        <div className={styles.list}>
          {isLoading && (
            <div className={styles.listLoader}>
              <Spinner color={"#8C42E3"} size={40} />
            </div>
          )}
          {!isLoading &&
            ingredients.map((item, index) => (
              <IngredientItem
                item={item}
                key={item.id}
                onGetIngredients={onGetIngredients}
              />
            ))}
        </div>
        <div className={styles.paginationContainer}>
          {Array(pagesCount === 0 ? 1 : pagesCount)
            .fill("_")
            .map((item, index) => (
              <PageItem
                onPress={() => onGetIngredients(index + 1, limit, search)}
                key={index}
                page={index + 1}
                isActive={index + 1 === page}
              />
            ))}
          {pagesCount > 1 && page !== pagesCount && (
            <div
              onClick={() => onGetIngredients(page + 1, limit, search)}
              className={styles.nextBtn}
            >
              Next
            </div>
          )}
        </div>
      </Manage>
    </div>
  );
};

export default Ingredients;
