import React, { FC } from "react";
import { CheckBigIcon } from "../../assets";
import styles from "./styles.module.scss";

const DailyReminders = ({ remainders }) => {
  const renderReminders = (index, item) => {
    return (
      <div key={item} className={styles.reminderWrapper}>
        <CheckBigIcon />
        <p className={styles.reminder}>{item}</p>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>Daily reminders</h4>
      <div className={styles.remindersList}>
        {remainders?.map((item, index) => renderReminders(index, item))}
      </div>
      <h5>
        Love, <br />
        <span>Bridge XX</span>
      </h5>
    </div>
  );
};

export default DailyReminders;
