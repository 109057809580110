import { Delete, PlusIcon } from 'assets';
import { Input, InputSelect } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import styles from './styles.module.scss';

const RecipeIngridientsList = ({
  ingredients,
  setIngredients,
  onChangeIngredientAmount,
  onDeleteIngredient,
  onDeleteNewUnit,
  onAddNewUnit,
  onChangeIngredientUnit,
  onGetAllUnits,
  allUnits,
  onChangeIngredientLink,
}) => {
  const [searchUnits, setSearchUnits] = useState('');

  const onChangeSearchUnits = useCallback((e) => {
    setSearchUnits(e.target.value);
  }, []);

  useEffect(() => {
    let timeout = setTimeout(() => {
      onGetAllUnits(searchUnits);
    }, 400);

    return () => clearTimeout(timeout);
  }, [searchUnits]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newIngredients = reorder(
      ingredients,
      result.source.index,
      result.destination.index
    );

    console.log('reordered', newIngredients);

    setIngredients(newIngredients);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='list'>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={styles.ingredientsWrapper}
          >
            {ingredients.map((item, index) => (
              <Draggable
                draggableId={item?.ingredient?.id}
                index={index}
                key={item?.ingredient?.id}
                disableInteractiveElementBlocking={false}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={styles.indedientsItemBlock}
                    key={item?.ingredient?.id}
                  >
                    <div className={styles.ingredientContainer}>
                      <Input
                        value={item.ingredient.name}
                        onChange={() => {}}
                        placeholder={'Ingredient'}
                        classesInner={styles.ingredient}
                        disabled={true}
                      />
                      <Input
                        value={item?.hyperLink}
                        onChange={(e) => onChangeIngredientLink(e, index)}
                        placeholder={'Link to recipe'}
                        classesInner={styles.ingredient}
                        disabled={false}
                      />
                    </div>
                    <div className={styles.unitContainer}>
                      {item?.units?.map((el, i) => {
                        return (
                          <div key={i} className={styles.unitWrapper}>
                            <Input
                              value={el.amount}
                              onChange={(e) =>
                                onChangeIngredientAmount(e, index, i)
                              }
                              classesInner={styles.amount}
                            />
                            <InputSelect
                              value={el.unit.text}
                              onChange={(el) => {
                                onChangeIngredientUnit(el, index, i);
                                setSearchUnits('');
                              }}
                              classesInner={styles.unit}
                              data={allUnits}
                              search={{
                                placeholder: 'Search...',
                                value: searchUnits,
                                onChange: onChangeSearchUnits,
                              }}
                              onListToggle={() => setSearchUnits('')}
                            />
                            <div
                              onClick={() => {
                                if (i === 0) {
                                  onDeleteIngredient(index);
                                } else {
                                  onDeleteNewUnit(index, i);
                                }
                              }}
                              className={styles.deleteBtn}
                            >
                              <Delete />
                            </div>
                          </div>
                        );
                      })}
                      <div
                        onClick={() => onAddNewUnit(index)}
                        className={styles.addUnit}
                      >
                        <PlusIcon width={12} height={12} />
                        <span>Add Unit</span>
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default RecipeIngridientsList;
