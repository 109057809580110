//react
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Spinner } from "react-activity";
import styled from "@emotion/styled";

//components
import { Button, BoostCampsItem, Pagination } from "components";

//assets
import { Plus } from "assets";

//navigation
import { useNavigate } from "react-router-dom";

//styles
import styles from "./styles.module.scss";

//utils
import { fetchRequest } from "utils";
import { useDebounce } from "hooks";

const ManageBoostCamps = () => {
  const [sortBy, setSortBy] = useState("Start Date");
  const [boostcamps, setBoostCamps] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(100);
  const [rerender, setRerender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [reorderedBoostcamps, setReorderedBoostcamps] = useState([]);
  const debouncedReorderedBoostcamps = useDebounce(reorderedBoostcamps, 2000);

  const limit = 6;

  const onGetBoostCamps = async (page) => {
    try {
      setIsLoading(true);
      const response = await fetchRequest(
        `boost-camps/?page=${page}&limit=${limit}&sort=+createdAt`,
        "GET"
      );
      if (response?.status === 200) {
        setBoostCamps(response?.data?.rows);
        setLastPage(Math.ceil(response?.data?.count / limit));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  const incrementPage = () => {
    if (boostcamps && page < lastPage) {
      onGetBoostCamps(page + 1);

      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      onGetBoostCamps(page - 1);

      setPage(page - 1);
    }
  };

  useEffect(() => {
    setPage(1);
    onGetBoostCamps(1);
  }, [rerender]);

  const navigate = useNavigate();

  const reorderBoostcamps = async (boostcamps) => {
    try {
      // setIsLoading(true);
      const boostcampsIds = boostcamps.map((camp) => camp?.id);
      const body = {
        ids: boostcampsIds,
      };
      const response = await fetchRequest(`boost-camps/order`, "PUT", body);
      // if (response?.status === 200) {

      // }
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    if (
      debouncedReorderedBoostcamps &&
      debouncedReorderedBoostcamps?.length > 0
    )
      reorderBoostcamps(debouncedReorderedBoostcamps);
  }, [debouncedReorderedBoostcamps]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newBoostcamps = reorder(
      boostcamps,
      result.source.index,
      result.destination.index
    );

    setBoostCamps(newBoostcamps);
    setReorderedBoostcamps(newBoostcamps);
  }

  const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  `;

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <p className={styles.header__title}>Manage Boost Camps</p>
          <div>
            <Button
              onClick={() => navigate("/boostcamps/create")}
              title={"Add New Boost Camp"}
              icon={() => <Plus />}
            />
          </div>
        </div>
        <div className={styles.boostCampsList}>
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Spinner color={"#8C42E3"} size={40} />
            </div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="list"
                className={styles.boostCampsListDroppable}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {boostcamps.map((item, index) => (
                      <Draggable
                        draggableId={item?.id}
                        index={index}
                        key={item?.id}
                        // disableInteractiveElementBlocking={false}
                      >
                        {(provided) => (
                          <StyledDiv
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <BoostCampsItem
                              key={item?.id}
                              item={item}
                              setRerender={setRerender}
                              index={index}
                            />
                          </StyledDiv>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          <div className={styles.paginationWrapper}>
            <Pagination
              incrementPage={incrementPage}
              decrementPage={decrementPage}
              page={page}
              lastPage={lastPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageBoostCamps;
