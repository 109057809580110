import React, { useState, useEffect, useCallback } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { Arrow, Delete, ImgPlaceholder, PlusIcon } from "assets";
import { Spinner } from "react-activity";
import { uploadFile, deleteFile } from "services/file.service";
import {
  Button,
  Input,
  InputSelect,
  RecipeIngridientsList,
  RecipePreview,
  RichTextEditor,
  SearchIngredients,
  TagSelector,
} from "components";
import { cookHours, cookMinutes } from "consts";
import { fetchRequest } from "utils";
import { useNavigate } from "react-router-dom";
import TopModal from "modals/TopModal";
import { useToggleModal } from "hooks";
import format from "date-fns/format";
import FileResizer from "react-image-file-resizer";

const CreateRecipes = () => {
  const [preview, setPreview] = useState();
  const [pictureError, setPictureError] = useState(false);
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [isLoadingDeleteFile, setIsLoadingDeleteFile] = useState(false);
  const [cookTimeHours, setCookTimeHours] = useState("0 h");
  const [cookTimeMinutes, setCookTimeMinutes] = useState("5 m");
  const [ingredients, setIngredients] = useState([]);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [isOpenSearchIngredient, setIsOpenSearchIngredient] = useState(false);
  const [allIngredients, setAllIngredients] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [allTagsLoader, setAllTagsLoader] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [allUnits, setAllUnits] = useState([]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [previewError, setPreviewError] = useState(false);
  const [tagsError, setTagsError] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isLoadingAnother, setIsLoadingAnother] = useState(false);
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const savedModal = useToggleModal();
  const updatedShown = () => {
    savedModal.showModal();

    setTimeout(() => {
      savedModal.closeModal();
    }, 3000);
  };

  const onGetAllTags = async () => {
    try {
      setAllTagsLoader(true);
      const res = await fetchRequest("tags", "GET");
      if (res?.success) {
        setAllTags(res?.data);
      }
      setAllTagsLoader(false);
    } catch (error) {
      setAllTagsLoader(false);
    }
  };

  const onChangeDate = (e) => {
    setDate(e.target.value);
  };

  const onGetAllUnits = async () => {
    try {
      const res = await fetchRequest("units", "GET");
      if (res?.success) {
        setAllUnits(res?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    onGetAllTags();
    onGetAllUnits();
  }, []);

  const onDeleteIngredient = (index) => {
    const copy = [...ingredients];
    copy.splice(index, 1);
    setIngredients(copy);
  };

  const onChangeTitle = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const onChangeIngredientAmount = useCallback((e, index, i) => {
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].units[i].amount = e.target.value;
      return copy;
    });
  }, []);

  const onChangeIngredientUnit = useCallback((item, index, i) => {
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].units[i].unit = item;
      return copy;
    });
  }, []);

  const onAddNewUnit = useCallback((index) => {
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].units?.push({
        amount: 1,
        unit: copy[index]?.units[copy[index]?.units?.length - 1]?.unit,
      });
      return copy;
    });
  });

  const onDeleteNewUnit = useCallback((index, i) => {
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].units?.splice(i, 1);
      return copy;
    });
  });

  const base64ToBlob = (base64) => {
    const byteCharacters = window.atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray]);
  };
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        252,
        252,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const onSelectFile = async (e) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setIsLoadingUploadFile(true);
      const file = e.target.files[0];
      const image = await resizeFile(file);
      console.log(image);
      const base64Response = await fetch(image);
      const blob = await base64Response.blob();
      const blobNew = b64toBlob(image.split(",")[1]);
      console.log({ blob, blobNew });

      const res = await uploadFile(blob);
      setPreview(res?.data?.url);
      setIsLoadingUploadFile(false);
    } catch (error) {
      setIsLoadingUploadFile(false);

      console.log(error);
    }
  };

  const onDeleteFile = async (e) => {
    try {
      setIsLoadingDeleteFile(true);
      const res = await deleteFile(preview);
      setPreview(undefined);
      setIsLoadingDeleteFile(false);
    } catch (e) {
      setIsLoadingDeleteFile(false);

      console.log(e);
    }
  };
  const onSelectIngredient = (item) => {
    setIngredients((prev) => {
      return [
        ...prev,
        {
          units: [
            {
              amount: "1",
              unit: allUnits?.length > 0 ? allUnits[0] : { text: "", id: "" },
            },
          ],
          ingredient: item,
        },
      ];
    });
  };

  const onCreateRecipes = async (withAnother = false) => {
    try {
      if (!title) {
        setTitleError(true);
        return;
      } else {
        setTitleError(false);
      }
      if (!preview) {
        setPreviewError(true);
        return;
      } else {
        setPictureError(false);
      }
      if (selectedTags?.length === 0) {
        setTagsError(true);
        return;
      } else {
        setTagsError(false);
      }
      if (withAnother) {
        setIsLoadingAnother(true);
      } else {
        setIsSaveLoading(true);
      }

      const body = {
        title,
        cookTime: {
          hours: cookTimeHours.slice(0, 2).trim(),
          minutes: cookTimeMinutes.slice(0, 2).trim(),
        },
        image: preview,
        ingredients: ingredients.map((item) => {
          const ingr = {
            units: item?.units?.map((el) => ({
              unit: el?.unit?.id,
              amount: el?.amount,
            })),
            ingredient: item.ingredient.id,
          };

          if (!!item?.hyperLink) ingr.hyperLink = item.hyperLink;
          return ingr;
        }),
        date: date,
        preparation: description,
        tags: selectedTags.map((item) => item.id),
      };
      const res = await fetchRequest("recipes", "POST", body);
      if (res?.success) {
        if (!withAnother) {
          navigate("/recipes/manage");
        } else {
          updatedShown();
          setTitle("");
          setCookTimeHours("0 h");
          setCookTimeMinutes("5 m");
          setPreview();
          setIngredients([]);
          setDescription("");
          setSelectedTags([]);
          setDate(new Date());
        }
      }
      setIsLoadingAnother(false);
      setIsSaveLoading(false);
    } catch (error) {
      setIsLoadingAnother(false);
      setIsSaveLoading(false);
    }
  };

  const onChangeIngredientLink = useCallback((e, index) => {
    setIngredients((prev) => {
      const copy = [...prev];
      copy[index].hyperLink = e.target.value;
      return copy;
    });
  }, []);

  return (
    <>
      <RecipePreview
        isOpen={isOpenPreview}
        setIsOpen={setIsOpenPreview}
        preview={preview || ""}
        title={title}
        ingredients={ingredients}
        tags={selectedTags}
        description={description}
        cookTimeHours={cookTimeHours}
        cookTimeMinutes={cookTimeMinutes}
        date={date}
      />
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Create New Recipe</h1>
        <div className={styles.header}>
          <div className={styles.infoBlock}>
            <div className={styles.titleBlock}>
              <div className={styles.titleBlockLabel}>Recipe Title</div>
              <div className={styles.titleBlockLabelCook}>Date</div>
            </div>
            <div className={styles.titleInputBlock}>
              <Input
                value={title}
                onChange={onChangeTitle}
                placeholder={"Title"}
                classesInner={styles.titleInputBlockTitle}
                errorBorder={titleError}
              />
              <div className={styles.titleInputBlockCook}>
                <Input
                  value={format(new Date(date), "yyyy-MM-dd")}
                  placeholder={"Date"}
                  onChange={onChangeDate}
                  type={"date"}
                />
                {/* <InputSelect
                  value={cookTimeHours}
                  onChange={setCookTimeHours}
                  data={cookHours}
                  classesInner={styles.hoursInput}
                />
                <InputSelect
                  value={cookTimeMinutes}
                  onChange={setCookTimeMinutes}
                  data={cookMinutes}
                  classesInner={styles.minutesInput}
                /> */}
              </div>
            </div>
            <div className={styles.ingredientsBlock}>
              <span className={styles.title}>Ingredients</span>
              {ingredients?.length > 0 && (
                <div className={styles.titleBlock}>
                  <div className={styles.ingredientTitle}>Ingredient</div>
                  <div className={styles.amountTitle}>Amount</div>
                  <div className={styles.unitTitle}>Unit</div>
                  <div style={{ width: "40px" }} />
                </div>
              )}

              <RecipeIngridientsList
                ingredients={ingredients}
                setIngredients={setIngredients}
                onChangeIngredientAmount={onChangeIngredientAmount}
                onDeleteIngredient={onDeleteIngredient}
                onDeleteNewUnit={onDeleteNewUnit}
                onAddNewUnit={onAddNewUnit}
                onChangeIngredientUnit={onChangeIngredientUnit}
                onGetAllUnits={onGetAllUnits}
                allUnits={allUnits}
                onChangeIngredientLink={onChangeIngredientLink}
              />
              <div style={{ position: "relative", marginBottom: "20px" }}>
                <div
                  onClick={() => setIsOpenSearchIngredient((prev) => !prev)}
                  className={styles.addIngredient}
                >
                  <PlusIcon />
                  <span>Add Ingredient</span>
                </div>
                {isOpenSearchIngredient && (
                  <SearchIngredients
                    setIsOpen={setIsOpenSearchIngredient}
                    onSelect={onSelectIngredient}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.imageBlock}>
            {preview ? (
              <img
                src={preview}
                className={clsx(styles.recipePhoto, {
                  [styles.recipePhotoError]: previewError,
                })}
                alt={"photo"}
              />
            ) : (
              <div
                className={clsx(styles.recipePhoto, {
                  [styles.recipePhotoError]: previewError,
                })}
              >
                <ImgPlaceholder />
              </div>
            )}
            <div className={styles.flex}>
              <label
                htmlFor={"photoInput"}
                className={styles.recipeUploadImgWrapper}
              >
                {isLoadingUploadFile ? (
                  <span>
                    <Spinner size={12} />
                  </span>
                ) : (
                  "Upload New"
                )}
                <input
                  id={"photoInput"}
                  type={"file"}
                  accept="image/*"
                  onChange={onSelectFile}
                  onClick={(e) => (e.target.value = null)}
                  className={styles.recipeUploadImgBtn}
                />
              </label>
              <div onClick={onDeleteFile} className={styles.recipeDeleteImgBtn}>
                {isLoadingDeleteFile ? (
                  <span>
                    <Spinner size={12} />
                  </span>
                ) : (
                  "Delete photo"
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.preparationBlock}>
          <div className={styles.title}>Preparation</div>
          <RichTextEditor
            initialValue={description}
            setValue={setDescription}
            // label={"Short description"}
          />
        </div>
        <div className={styles.tagsBlock}>
          <div className={styles.title}>Tags</div>

          <div
            className={clsx(styles.tagsContainer, {
              [styles.tagsContainerError]: tagsError,
            })}
          >
            {allTagsLoader && (
              <div className={styles.tagsLoader}>
                <Spinner color={"#8C42E3"} size={25} />
              </div>
            )}
            {!allTagsLoader &&
              allTags.map((item, index) => (
                <TagSelector
                  item={item}
                  isSelect={selectedTags.some((el) => el?.id === item?.id)}
                  onSelect={setSelectedTags}
                  key={index}
                />
              ))}
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            onClick={() => navigate("/recipes/manage")}
            title={"Cancel"}
            emptyStyle
            classes={styles.cancelBtn}
          />
          <Button
            title={"Save"}
            loading={isSaveLoading}
            onClick={() => onCreateRecipes(false)}
            classes={styles.saveBtn}
          />
          <Button
            onClick={() => setIsOpenPreview(true)}
            title={"Preview"}
            classes={styles.previewBtn}
          />
          <Button
            loading={isLoadingAnother}
            title={"Save & Create Another One"}
            classes={styles.saveOrCreateBtn}
            onClick={() => onCreateRecipes(true)}
            rightIcon={() => <Arrow className={styles.arrow} />}
          />
        </div>
      </div>
      {savedModal.isShow && <TopModal title="Sucessfully saved" />}
    </>
  );
};

export default CreateRecipes;
