import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// libs
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//pages
import {
  Dashboard,
  NotFound,
  SignIn,
  UserRequest,
  Statistics,
  Settings,
  Logs,
  Log,
  CreateBoostcamps,
  FillDaysBoostCamps,
  ManageBoostCamps,
  EditBoostCamp,
  EditBoostCampsDays,
  Notifications,
  PreviewBoostcamp,
  MealPlansManage,
  CreateMealPlan,
  CreateRecipes,
  ManageRecipes,
  Ingredients,
  Categories,
  Units,
  Tags,
  ManageShoppingList,
  CreateShoppingList,
  EditRecipe,
  EditShoppingList,
  EditMealPlan,
  MealPlan,
  ManageIntroScreens,
  EditIntroScreen,
  CreateIntroScreen,
  HomeTutorial,
  BoostcampTutorial,
  EditTooltip,
  UsersScreen,
  UsersTestScreen,
  UsersBetaScreen,
  UsersVipScreen,
  UserScreen,
} from "pages";
//components
import { PrivateRoute } from "./components";

const Navigation = () => {
  return (
    <>
      <Routes>
        <Route path="signIn" element={<SignIn />} />
        <Route path="/preview/:id" element={<PreviewBoostcamp />} />
        <Route path="/" element={<PrivateRoute component={Dashboard} />}>
          <Route index element={<Navigate to="statistics" />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="mealplans/manage" element={<MealPlansManage />} />
          <Route path="mealplans/create" element={<CreateMealPlan />} />
          <Route path="mealplans/edit/:id" element={<EditMealPlan />} />
          <Route path="mealplans/:id" element={<MealPlan />} />
          <Route path="shopping-list/manage" element={<ManageShoppingList />} />
          <Route path="shopping-list/create" element={<CreateShoppingList />} />
          <Route path="shopping-list/edit" element={<EditShoppingList />} />
          <Route path="recipes/manage" element={<ManageRecipes />} />
          <Route path="recipes/create" element={<CreateRecipes />} />
          <Route path="recipes/edit/:id" element={<EditRecipe />} />
          <Route path="ingredients" element={<Ingredients />} />
          <Route path="categories" element={<Categories />} />
          <Route path="units" element={<Units />} />
          <Route path="tags" element={<Tags />} />

          {/* <Route path="boostcamps" element={<SchoolsTable />} /> */}
          {/* <Route path="manageSchools/:id" element={<School />} /> */}
          <Route path="boostcamps/create" element={<CreateBoostcamps />} />
          <Route path="boostcamps/edit/:id" element={<EditBoostCamp />} />
          {/* <Route
            path="boostcamps/edit/days/:id"
            element={<EditBoostCampsDays />}
          /> */}
          <Route
            path="boostcamps/create/filldays/:id"
            element={<FillDaysBoostCamps />}
          />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/boostcamps/manage" element={<ManageBoostCamps />} />

          {/* <Route path="boostcamps/:id" element={<Student />} /> */}
          {/* <Route path="requests" element={<UserRequest />} /> */}
          {/* <Route path="statistics" element={<Statistics />} /> */}
          {/* <Route path="logs" element={<Logs />} />
          <Route path="logs/:id" element={<Log />} /> */}
          <Route path="settings" element={<Settings />} />

          <Route path="intro-screens/manage" element={<ManageIntroScreens />} />
          <Route path="intro-screens/create" element={<CreateIntroScreen />} />
          <Route path="intro-screens/edit/:id" element={<EditIntroScreen />} />

          <Route path="tutorials/home" element={<HomeTutorial />} />
          <Route path="tutorials/boostcamp" element={<BoostcampTutorial />} />
          <Route path="tutorials/edit/:id" element={<EditTooltip />} />

          <Route path="users/main" element={<UsersScreen />} />
          <Route path="users/test" element={<UsersTestScreen />} />
          <Route path="users/beta" element={<UsersBetaScreen />} />
          {/* <Route path="users/vip" element={<UsersVipScreen />} /> */}
          <Route path="users/:id" element={<UserScreen />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </>
  );
};

export default Navigation;
